import {Injectable} from "@angular/core";
import {HttpClient, 
    // HttpHeaders,
    HttpParams} from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { IGetPaymentTrans, IPaymentReq, IZeroPaymentReq } from "../model/payment.model";
import { LocalStorage } from "ngx-webstorage";
// import { CommonService } from "./common.service";
// import { Router } from "@angular/router";
// import { RefreshServices } from "./refresh.services";

@Injectable({
  providedIn: 'root'
})

export class PaymentServices {

    protected baseApi: string;
    protected token: string; 
    public paymentData = {
        getResData: '',
        getCallerror: '',
        postResData: '',
        postResError: '',
        postResErrorCollection: []

    }; 

    @LocalStorage()
    protected uuid;
    
    @LocalStorage()
    protected xExpire;


  constructor(
         private http: HttpClient,
         private cookieService: CookieService,
        //  private commonService: CommonService,
        //  private refreshServices: RefreshServices,
        //  private router: Router,
         ) { 
        this.baseApi = environment.BASE_API;
        this.token = this.cookieService.get('x-token');
    }

     post(req: IPaymentReq): Observable<any> { 
    //   this.commonService.checkExpiredToken();

    //     const headers = new HttpHeaders({
    //         'authorization': `Bearer ${this.token}`
    //     })
        return this.http.post(`${this.baseApi}/${environment.AUTH.PAYMENT}/${this.uuid}`, req, {
            // headers,
            observe: 'response'});
    }

    makeZeroPayment(req: IZeroPaymentReq): Observable<any> {
        // this.commonService.checkExpiredToken();

        // const headers = new HttpHeaders({
        //     'authorization': `Bearer ${this.token}`
        // })
        return this.http.post(`${this.baseApi}/${environment.AUTH.ZERO_PAYMENT}/${this.uuid}`, req, {
            // headers,
            observe: 'response'});
    } 
    
    getPaymentReq(req:IGetPaymentTrans): Observable<any> {

    //    this.commonService.checkExpiredToken();
       
        // const headers = new HttpHeaders({
        //     'authorization': `Bearer ${this.token}`
        // })
        const {userID, companyId } = req;
        const params = new HttpParams()
            .set('userID', userID)
            .set('companyId', companyId);

        return this.http.get(`${this.baseApi}/${environment.AUTH.PAYMENT_TRANS}/${this.uuid}`, {
            // headers,
            params,
            observe: 'response',
        });
    }
}
