import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpHeaderInterceptorService implements HttpInterceptor {

  constructor(private cookieService: CookieService, private commonService: CommonService){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.commonService.checkExpiredToken();
    const token = this.cookieService.get('x-token');

    if(req.url.endsWith('/authenticate')) {
      return next.handle(req);
    }

    if(req.url === environment.AUTH.GET_IP){
      return next.handle(req);
    }

    const headers = new HttpHeaders({
      'authorization': `Bearer ${token}`
    })

    const modifiedReq = req.clone({ 
      headers
    });

    // Send the modified request
    return next.handle(modifiedReq);
  }
}
