import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, BehaviorSubject, catchError, map, tap, throwError, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorage } from "ngx-webstorage";
import { IGetDeviceReq, IPlanReq, ISaveDeviceReq, ISavePlanReq, IAllPlanDevices, IGetPaymentReq, IDeviceRes } from "../model/plan_device.model";
import Stepper from "bs-stepper";
import { ITransactionRequest } from "../model/payment.model";
import { CommonService } from "./common.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})

export class PlanServices {

    private planData = new BehaviorSubject<IAllPlanDevices | null>(null);
    planData$ = this.planData.asObservable();

    public devicesData = new BehaviorSubject<IDeviceRes | null>(null);
    devicesData$ = this.devicesData.asObservable();

    public stepper: Stepper;

    protected baseApi: string;
    protected token: string; 

    public getPlanReqCheck:boolean = false;
    
    public loadPlans: boolean;
    // private loadPlanTimeOutId: number = -1;

    public loadDevices: boolean = false;
    // private loadDevicesTimeOutId: number = -1;

    public showPlanCarousel: boolean = false

    public ipAddress: string = ""; 
    public ipAddressError: string = "";

    public dataPlanDevice = {
        allPlans : [],
        allDevices: [],
        errorMessagePlan: "",
        errorMessageDevice: "",
        formSubmitted: false,
        apiProcess: false,
        showDevices: false,
        selectedCardId: -1,
        planItem: [],
        selectedDeviceId: '',
        totalPrice: {
            planPrice: 0,
            price: 0,
            qty: 0,
            id: -1
        },
        unlocksOtherCombos: []
    }


    @LocalStorage()
    protected uuid;

    @LocalStorage()
    protected xExpire;

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private commonService: CommonService,
        private router: Router,
        // private refreshServices: RefreshServices
    ){
        this.baseApi = environment.BASE_API;
        this.token = this.cookieService.get('x-token');
    }
    
    selectCard(id){
        this.dataPlanDevice.selectedCardId = id;
        this.dataPlanDevice.planItem = this.dataPlanDevice.allPlans.filter( plan => plan.id === id);
        this.dataPlanDevice.totalPrice["planPrice"] = this.dataPlanDevice.planItem[0].price;
    }

    public showHidePlanCarousel(bool: boolean):void{
        this.showPlanCarousel = bool;
    }
     
    public saveSelectedPlan(){
        const userID = +this.cookieService.get('x-userId');

        if(this.dataPlanDevice.selectedCardId === -1){
        this.dataPlanDevice.errorMessagePlan = "No plan selected." 
            return
        }

        this.dataPlanDevice.errorMessagePlan = "";
        this.dataPlanDevice.apiProcess = true;

        const selectedPlan = this.dataPlanDevice.allPlans.find( (value) => value.id === this.dataPlanDevice.selectedCardId)
        
        const payload = {
        companyId: environment.COMPANY_ID,
        userID,
        selectedPlanId: selectedPlan.id,
        selectedPlanName: selectedPlan.name
        }
        
        this.savePlan(payload).subscribe(
        (response) => {
            if(response.status === 200 && response.body.Status){
                this.router.navigate(['registration', 'device'])
                this.getAllDevices();
            } 
            else {
                this.dataPlanDevice.errorMessagePlan = response.body.message
            }
        },
        error => {
            this.dataPlanDevice.errorMessagePlan = error.message
        })
    }


    public getAllDevices(){
            // this.dataPlanDevice.showDevices = true;
            // this.dataPlansAndDevices.next(this.dataPlanDevice);

            const userID = +this.cookieService.get('x-userId');
            const payload = {
                companyId: environment.COMPANY_ID,
                userID
            }

            this.getDevice(payload).subscribe(
            (response) => {
                this.dataPlanDevice.allDevices = response["data"].combos;
                this.devicesData.next(response["data"].combos);
            },
            error => {
                this.dataPlanDevice.errorMessageDevice = error.message;
                // this.dataPlansAndDevices.next(this.dataPlanDevice);
            }
        )
    }

    public checkSelectedDevices(deviceId:string):boolean{
       return this.dataPlanDevice.selectedDeviceId === deviceId;
    }

    public selectDevice(id, price: number, unlocksOtherCombos, allowMultiOrder){
        if(this.dataPlanDevice.selectedDeviceId === id){
            return;
        }

        this.dataPlanDevice.totalPrice = {
            ...this.dataPlanDevice.totalPrice,
            id,
            price,
            qty: 1
        }; 
        
        this.dataPlanDevice.selectedDeviceId = id;
        if(unlocksOtherCombos && allowMultiOrder){
            const relatedDevices = unlocksOtherCombos.split(',');
            
            relatedDevices.map( id => {
                const deviceData = this.dataPlanDevice.allDevices.filter( dvc => dvc.comboId == id);
                if(deviceData.length > 0){
                    this.dataPlanDevice.unlocksOtherCombos.push(deviceData[0]);
                }
            })
        }
        else {
            this.dataPlanDevice.unlocksOtherCombos = [];
        }
    }
    
    public getPlanReq(req:IPlanReq): Observable<any> { 
        if(this.getPlanReqCheck){
            return of('some data');
        } 

        const {userID, companyId } = req;
        const params = new HttpParams()
            .set('userID', userID)
            .set('companyId', companyId);

        this.getPlanReqCheck = true;
        return this.http.get(`${this.baseApi}/${environment.AUTH.GET_PLAN}/${this.uuid}`, {
            params,
            observe: 'body',
        })
        .pipe(
            tap((response) => {
                this.dataPlanDevice.allPlans = response["data"];
                this.planData.next(response["data"]);
                this.loadPlans = true; 
                return response
            }),
            catchError(refreshError => {
              return throwError(() => refreshError);
            })
        );
    }

    savePlan(req: ISavePlanReq): Observable<any> { 
        return this.http.post(`${this.baseApi}/${environment.AUTH.SAVE_PLAN}/${this.uuid}`, req, {
            observe: 'response'
        })
        .pipe(
            map(
              (response:HttpResponse<any>) => response),
            
            catchError(refreshError => {
              return throwError(() => refreshError );
            })
        );
    }

    getDevice(req: IGetDeviceReq): Observable<any> {
        const {userID, companyId } = req;
        const params = new HttpParams()
            .set('userID', userID)
            .set('companyId', companyId);

        return this.http.get(`${this.baseApi}/${environment.AUTH.GET_DEVICE}/${this.uuid}`, {
            params,
            observe: 'body',
        })
        .pipe(
            tap((response: IDeviceRes) => {
                this.dataPlanDevice.allDevices = response.data.combos;
                this.loadDevices = true;
                return response
            }),
            
            catchError(refreshError => {
                this.loadDevices = true;
              return throwError(() => refreshError)
            })
        );
    }

    saveDevice(req: ISaveDeviceReq): Observable<any> {
       
        return this.http.post(`${this.baseApi}/${environment.AUTH.SAVE_DEVICE}/${this.uuid}`, req, {
            observe: 'response',
        });
    }

    get getTotalValue():number {
        const result = [];
        const { planPrice, price, qty } = this.dataPlanDevice.totalPrice;
        const amount = (price * qty);

        result.push(planPrice);
        result.push(amount);

        const totalAmount = result.reduce( (accumulator, currentValue) => {
          return parseFloat(accumulator) + parseFloat(currentValue)
        }, 0);
        return totalAmount.toFixed(2);
    }

    getPayment(req: IGetPaymentReq): Observable<any> {
       
        const {userID, companyId } = req;
        const params = new HttpParams()
            .set('userID', userID)
            .set('companyId', companyId);
            
        return this.http.get(`${this.baseApi}/${environment.AUTH.PAYMENT}/${this.uuid}`, {
            params,
            observe: 'body',
        })
        .pipe(
            tap((response) => {
                this.loadDevices = true;
                return response
            }),
            
            catchError(refreshError => {
                this.loadDevices = true;
              return throwError(() => refreshError)
            })
        );
    }

    getIpAddress(){
        this.http.get(environment.AUTH.GET_IP).subscribe(
            (response) => {
                this.ipAddress = response["ip"];
            },
            (error) => {
                this.ipAddressError = error.message;
            }
        )
    }

    makePayment(req: ITransactionRequest): Observable<any> {
        this.commonService.checkExpiredToken();
        
        return this.http.post(`${this.baseApi}/${environment.AUTH.PAYMENT_TRANS}/${this.uuid}`, req, {
            observe: 'response',
        }).pipe(
            map(
              (response:HttpResponse<any>) => {
              return response;
            }),
            catchError(refreshError => {
              return throwError(() => refreshError);
            })
        );
    }

}
