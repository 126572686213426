import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PlanServices } from 'src/app/shared/services/plan_device.services';
import { IAllPlanDevices } from 'src/app/shared/model/plan_device.model';
import { Subscription, endWith } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/services/common.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit, OnDestroy {

  public deviceData: IAllPlanDevices; 
  private subscription: Subscription;
  public errorMessage: string = ""; 
  public apiProcess: boolean = false;
  private selectedDevice; 
  private timeOutId: number = -1; 
  public responseModalData = {
    eligibleForDevice: "",
    eligibleMsg: ""
  }


  @ViewChild('devicePromoModal') modalTemplate;

  constructor(
    public deviceService: PlanServices,
    private commonService: CommonService,
    private cookieService: CookieService,
    private router: Router,
    public modalService: NgbModal
    ){
      this.commonService.setStepInfo('registration/device');
    }
 
  ngOnInit(){
    const userID = +this.cookieService.get('x-userId');
    this.deviceService.getDevice({userID, companyId: environment.COMPANY_ID}).subscribe(
      (response) => {
        if(response.data.combos){
          this.responseModalData.eligibleForDevice = response.data.eligibleForDevice;
          this.responseModalData.eligibleMsg = response.data.eligibleMsg;
          this.deviceService.devicesData.next(response.data.combos);
          if(response.data["eligibleForDevice"] === 'Y'){
            this.open(this.modalTemplate);
          }
        }
      }
    );
  }

  previous(){
    this.router.navigate(['registration/plan'])
  }

  updateApiProcess(selectedData){
      this.selectedDevice = selectedData;
  }

  saveSelectedDevice():void{
    if(this.deviceService.dataPlanDevice.selectedDeviceId === ""){
      this.errorMessage = "Please make a selection, no any device selected";
      return 
    }
    this.errorMessage = "";

    const userID = +this.cookieService.get('x-userId');

    const reqPayload = {
      companyId: environment.COMPANY_ID,
      userID,
      combos: []
    }

    const updatedDataSelectedData = {...this.selectedDevice}; 

    this.timeOutId = window.setTimeout( () => {
      this.apiProcess = true;
    }, 1200);


    const {products} = updatedDataSelectedData;
      if(updatedDataSelectedData){
        if('allowMultiOrder' in updatedDataSelectedData && updatedDataSelectedData['allowMultiOrder']){
          this.commonService.deleteObjectKeys(['comboName', 'comboTitle', 'comboDesc', 'comboPrice', 'sortOrder', 'navIconClass', 'imagePath'], updatedDataSelectedData);
        }
        else if('allowMultiOrder' in updatedDataSelectedData && !updatedDataSelectedData.allowMultiOrder){
          const filteredProducts = products.map(item => {
            this.commonService.deleteObjectKeys(['productName', 'imagePath', 'productDesc', 'navIconClass', 'maxSelection', 'quantity', 'sortOrder'], updatedDataSelectedData);
            return item;
          });

          updatedDataSelectedData["products"] = filteredProducts;
          reqPayload.combos.push(updatedDataSelectedData);
        }
      }
     
      this.subscription = this.deviceService.saveDevice(reqPayload).subscribe(
      (response) => {

        window.clearTimeout(this.timeOutId);
        this.commonService.expiredTokenError = "";
        this.apiProcess = false;
        if(response.status === 200 && response.body.Status){
          this.deviceService.getIpAddress();
          this.router.navigate(['registration/cart']);
        }
        else {
          this.errorMessage = response.body.message;
        }
      },
      (error) => {
        window.clearTimeout(this.timeOutId);
        this.commonService.expiredTokenError = "";
        this.apiProcess = false;
        if(!error.error && !error.error.message ){
          error.error.message = "Unknown error";
        }
        this.errorMessage = error.error.message;
      }
    )
  }


  open(content: TemplateRef<any>) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true  }).result.then(
			(result) => {
				console.warn(result);
			},
			(reason) => {
				console.warn(`Dismissed ${this.getDismissReason(reason)}`)
			},
		);
	}

  private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'by pressing ESC';
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'by clicking on a backdrop';
			default:
				return `with: ${reason}`;
		}
	}
  
  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    } 
    this.modalService.dismissAll('destroyed');
  }
}

