<div class="container-fuild p-0">
    <div class="row">
        <div class="col-12">
        <ng-container *ngIf="formSubmitted; then result_card else login_card"></ng-container>
        <ng-template #login_card>
            <div class="login-card">
                <div class="login-main">
                    <form class="theme-form" [formGroup]="registerForm" (ngSubmit)="onSubmitRegister('N')">
                        <h4 class="text-center font-primary">Qualify Now</h4>
                        <p class="text-center m-t-20">Enter your zip code to get started.</p>
                        <div class="form-group mb-3">
                            <label class="col-form-label" for="networkComponentZipCode">Zip Code</label>
                            <input 
                                type="text"
                                formControlName="zipCode"
                                name="zipCode"
                                id="networkComponentZipCode"
                                class="form-control"
                                placeholder="Enter the zip code"
                                aria-label="Zip Code"
                                [ngClass]="{'is-invalid':registerForm.get('zipCode').touched && registerForm.get('zipCode').invalid}"
                                aria-describedby="basic-addon1">
                                <div class="invalid-feedback">
                                    Zip is required. It should match as 5-digit.
                                </div>
                        </div>
                        <div class="form-group mb-3">
                            <label class="col-form-label" for="networkComponentEmail">Email Address</label>
                            <input
                                type="email"
                                class="form-control"
                                id="networkComponentEmail"
                                formControlName="email"
                                name="email"
                                placeholder="Enter the email address"
                                aria-label="Email address"
                                autocomplete={{true}}
                                [ngClass]="{'is-invalid':registerForm.get('email').touched && registerForm.get('email').invalid}"
                                aria-describedby="basic-addon1">
                                <div class="invalid-feedback">
                                    Email is required. It should be a valid email address.
                                </div>
                        </div>
                        <!-- <div class="form-group">
                            <re-captcha
                            formControlName="recaptchaReactive"
                            [ngClass]="{'is-invalid':registerForm.get('recaptchaReactive').touched && registerForm.get('recaptchaReactive').invalid}"
                            siteKey="{{gSiteKey}}">
                            </re-captcha>
                            <div class="invalid-feedback">
                                Invalid captcha.
                            </div>
                        </div> -->

                        <div *ngIf="!commonService.expiredTokenError && apiProcess" class="api-process-loader">
                            <div  class="loader-box">
                                <div class="loader-9"></div>
                            </div>
                        </div>
                        
                        <div class="input-group mb-3 mt-3">
                            <button [disabled]="!commonService.expiredTokenError  && apiProcess" type="submit" class="btn btn-primary d-block w-100">Get Started</button>
                        </div>
                        <div *ngIf="!commonService.expiredTokenError  && errorMessage" class="error-invalid f-14">
                            {{errorMessage}}
                        </div>
                        <div *ngIf="commonService.expiredTokenError" class="error-invalid f-14">
                            {{commonService.expiredTokenError}}
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>    
        <ng-template #result_card>
            <div class="login-card">
                <div class="login-main">
                    <div class="text-center network-icon-wrap p-3">
                        <app-feather-icons [icon]="'bar-chart'"></app-feather-icons>
                    </div>

                    <h4 class="text-center mt-3 mb-3">{{'Your network status is ' + overallServiceStrength}}</h4>

                    <div class="text-center mt-3">
                        <a [routerLink]="'/'+nextRoute" class="btn btn-primary">Next</a>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
</div>


<ng-template #resumeEnrollment let-modal>
    <div class="modal-body">
      <div class="close-modal p-absolute" (click)="modal.close()">
        <i class="icon-close"></i>
      </div>
      <div class="theme-form p-20"> 
            <div class="text-center opacity-2 m-b-20">
                <img class="img-100" src="assets/images/other-images/smiley.png" alt="">
            </div> 
            <h4 class="f-20 text-center"><strong>Welcome Back</strong></h4>
            <p class="f-16 m-b-20">Continue with your existing application or start over?</p>
            <div class="form-group p-t-20 mt-3 text-end">
                <button type="button" class="btn btn-light" (click)="registrationCancel()">Start over</button>
                <button type="button" class="btn btn-primary m-l-10" (click)="resumeWithPrevious()">Continue</button>
                
            </div>
      </div>
    </div>
</ng-template>

