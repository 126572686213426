import {Injectable} from "@angular/core";
import { HttpClient, 
  // HttpHeaders, 
  HttpParams, HttpResponse } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { LocalStorage } from 'ngx-webstorage';
import { environment } from "src/environments/environment";
import { Observable, ReplaySubject } from 'rxjs';
import { IApplicantAddressReq, IGetPopulateAddress } from "../model/applicant_address.model";
// import { RefreshServices } from "./refresh.services";
import { CommonService } from "./common.service";
// import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})

export class ApplicantAddressService {
  protected baseApi: string;
  protected token: string; 
  
  private myDataSubject = new ReplaySubject<HttpResponse<any>| null>(null);
  myData$ = this.myDataSubject.asObservable();

    @LocalStorage()
    protected uuid;

    @LocalStorage()
    protected zipCodeId

    @LocalStorage()
    protected zipCode

    @LocalStorage()
    protected xExpire;

    constructor(
        private http:HttpClient,
        private cookieService: CookieService,
        private commonService: CommonService,
        ) {
        this.baseApi = environment.BASE_API; 
        this.token = this.cookieService.get('x-token');
    }

  put(req: IApplicantAddressReq): Observable<HttpResponse<any>>  {
    // this.commonService.checkExpiredToken();
    return this.http.put(`${this.baseApi}/${environment.AUTH.ADD_ADDRESS}/${this.uuid}`, req, { observe: 'response'});
  }

  populateCityAndSate(req: IGetPopulateAddress): Observable<any>  {
    // this.commonService.checkExpiredToken();
    // const headers = new HttpHeaders({
    //   'authorization': `Bearer ${this.token}`
    // })
    const {userID, companyId } = req;
    const params = new HttpParams()
          .set('userID', userID)
          .set('companyId', companyId);
          
      return this.http.get(`${this.baseApi}/${environment.AUTH.POPULATE_CITY}/${this.zipCode}`, {
        // headers,
        params,
        observe: 'body'
      })
  }

  getAllCity(req: IGetPopulateAddress, stateId): Observable<any>  {
    this.commonService.checkExpiredToken();
  //   const headers = new HttpHeaders({
  //     'authorization': `Bearer ${this.token}`
  // })
  const {userID, companyId } = req;
  const params = new HttpParams()
        .set('userID', userID)
        .set('companyId', companyId);
        
    return this.http.get(`${this.baseApi}/${environment.AUTH.ALL_CITY}/${stateId}`, {
      // headers,
      params,
      observe: 'body'
    })
  }

  getAllState(req: IGetPopulateAddress): Observable<any>  {
      this.commonService.checkExpiredToken();
      // const headers = new HttpHeaders({
      //   'authorization': `Bearer ${this.token}`
      //   })

      const {userID, companyId } = req;
      const params = new HttpParams()
            .set('userID', userID)
            .set('companyId', companyId);
            
        return this.http.get(`${this.baseApi}/${environment.AUTH.ALL_STATE}`, {
          // headers,
          params,
          observe: 'body'
        })
    }

  getAllZip(req: IGetPopulateAddress, cityCode): Observable<any>  {
    this.commonService.checkExpiredToken();
  //   const headers = new HttpHeaders({
  //     'authorization': `Bearer ${this.token}`
  // })
  const {userID, companyId } = req;
  const params = new HttpParams()
        .set('userID', userID)
        .set('companyId', companyId);
        
    return this.http.get(`${this.baseApi}/${environment.AUTH.ALL_ZIP}/${cityCode}`, {
      // headers,
      params,
      observe: 'body'
    })
  }
}
