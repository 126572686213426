import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';

import { AdminGuard } from './shared/guard/admin.guard';
import { NetworkComponent } from './auth/network/network.component';
import { StepperComponent } from './auth/registration/stepper/stepper.component';
import { InvoiceComponent } from './auth/invoice/invoice.component';


const routes: Routes = [
  {
    path: 'network-strength',
    component: NetworkComponent
  },
  {
    path: 'registration',
    canActivate: [AdminGuard],
    component: StepperComponent,
    loadChildren: () => import('./auth/registration/step1/step1.module').then(m => {
      return m.Step1Module;
    })
  },
  {
    path: 'invoice',
    canActivate: [AdminGuard],
    component: InvoiceComponent
  },
  {
    path: '**',
    redirectTo: '/network-strength'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
