import { AfterViewChecked, Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FORM_CONST } from 'src/app/shared/constants';
import { applyValidation, throwMessages } from 'src/app/shared/validators/formValidation'
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';
import { BeneficiaryService } from 'src/app/shared/services/beneficiary.services';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.scss']
})

export class BeneficiaryComponent implements OnInit, AfterViewChecked, OnDestroy {

  private subscription: Subscription;
  private subscriptionRefresh: Subscription;
  private errorSubscription: Subscription;

  public formSubmitted: boolean = false;
  public apiProcess:boolean = false;
  public errorMessage: string;
  public showFormatContactNumber:boolean = false;
  public formattedPhoneNumber:string = '';
  public contactNumberPlaceHolder: string = environment.HELPLINE_NUMBER;
  private timeOutId: number = -1;

  @Input()
  public filledData;


  @Output()
  contactNumber:any;

  @LocalStorage()
  prefilledInfo

  @LocalStorage()
  protected dl;

  @LocalStorage()
  protected ssn;

  @LocalStorage()
  private beneficiaryInfo;

  public prefixData: any;
  
  defaultPersonTypeCheck:boolean = false;

  datePickerConfig: Partial<BsDatepickerConfig>;

  beneficiaryForm = {
    isApplyingForSelf: {value: 'Yes'},
    isAcpConsent: {value: true, isValid: false},
    isBqpConsent: {value: false, isValid: false},
    firstName: {value: '', isValid: false},
    middleName: {value: ''},
    lastName: {value: '', isValid: false},
    suffix: {value: ''},
    dateOfBirth: {value: '', isValid: false},
    contactNumber: {value: '', isValid: false},
    ssn: {value: '', isValid: false},
    driverLicense: {value: ''},
    isTransferConsent: {value: true, isValid: false},
  };

  constructor( 
    private beneficiaryServices: BeneficiaryService,
    private cookieService: CookieService,
    public commonService: CommonService,
    private localStorage: LocalStorageService,
    private router: Router
    ){
    const minYear:number = new Date().getFullYear() - 100;
    const maxYear:number = new Date().getFullYear() - 18 ;

    const minDate = new Date();
    minDate.setFullYear(minYear);

    const maxDate = new Date();
    maxDate.setFullYear(maxYear);

    this.prefixData = environment.PREFIX_DATA;

   this.datePickerConfig = Object.assign({}, { 
     containerClass: 'theme-dark-blue',
     showWeekNumbers: false,
     minDate,
     maxDate,
    }); 
    this.commonService.setStepInfo('registration/beneficiary');
  }
  

  ngOnInit(){
    if(this.beneficiaryInfo){
      if(this.beneficiaryInfo.isApplyingForSelf.value === 'No'){
        this.defaultPersonTypeCheck = true;
      }
      const dateOfBirth = new Date(this.beneficiaryInfo.dateOfBirth.value);
      this.beneficiaryForm = {
        ...this.beneficiaryInfo,
        dateOfBirth: {value: dateOfBirth, isValid: false}
      }
      this.isApplyingForSelfCheck();
      this.formatPhoneNumberData();
    }
  }

  isApplyingForSelfCheck():boolean {
    if(this.beneficiaryInfo){
      if(this.beneficiaryInfo.isApplyingForSelf.value === 'No'){
        return true;
      }
      return false;
    }
    if(this.beneficiaryForm.isApplyingForSelf.value === 'No'){
    }
    return false;
  }

  ngAfterViewChecked(){
   this.isApplyingForSelfCheck();
  }

  hideFormattedContactNumber(){
    this.showFormatContactNumber = false;
  }

  formatPhoneNumberData(){
    const contactNumber = this.beneficiaryForm.contactNumber.value;
    if(contactNumber.length === 10){
      const areaCode = contactNumber.substring(0, 3);
      const firstPart = contactNumber.substring(3, 6);
      const secondPart = contactNumber.substring(6, 10);
      this.formattedPhoneNumber = '(' + areaCode + ') ' + firstPart + ' - ' + secondPart;
      this.showFormatContactNumber = true;
    }
    else {
      this.showFormatContactNumber = false;
    }
  }

  renderAdditionalForm(){
    this.isApplyingForSelfCheck();
  }

  callApi(): void {
    
    const {isApplyingForSelf, isAcpConsent, isTransferConsent } = this.beneficiaryForm;
    // console.log(isApplyingForSelf, isAcpConsent, isTransferConsent);
    
    // const isTransferConsent = isTransferConsent.value ? 'Yes' : 'No';
    // const isTransferConsent = "Yes";
    
    const userID = +this.cookieService.get('x-userId');
    const payload = {
      userID,
      companyId: environment.COMPANY_ID,
      isApplyingForSelf: isApplyingForSelf.value,
      isAcpConsent: isAcpConsent.value? "Yes": "No",
      isTransferConsent: isTransferConsent.value ? 'Yes' : 'No'
    };
    
    if(this.beneficiaryForm.isApplyingForSelf.value === 'No'){
      const {firstName, middleName, lastName, suffix, dateOfBirth, contactNumber, ssn, driverLicense, isBqpConsent} = this.beneficiaryForm;
      //const isBqpConsent = isBqpConsent.value ? 'Yes' : 'No';
      const dobValue = new Date(dateOfBirth.value);
      if(dobValue instanceof Date){
        const dd = dobValue.getDate().toString();
        const mm = dobValue.getMonth() + 1;
        const yyyy = dobValue.getFullYear().toString();
  
        const dob = {
          mm : `${mm}`.length === 1 ? `0${mm}` : mm,
          dd: dd.length === 1 ? `0${dd}` : dd,
          yyyy
        }
        payload["dateOfBirth"]= `${dob.mm}-${dob.dd}-${dob.yyyy}`;
      }

      payload["driverLicense"] = driverLicense.value;
      payload["ssn"] = ssn.value;
      payload["firstName"] = firstName.value;
      payload["middleName"] = middleName.value;
      payload["lastName"] = lastName.value;
      payload["suffix"]= suffix.value;
      payload["contactNumber"]= contactNumber.value;
      payload["isBqpConsent"]= isBqpConsent.value ? 'Yes': 'No';
    }
    
    if(JSON.stringify(this.beneficiaryInfo) === JSON.stringify(this.beneficiaryForm)){
        window.clearTimeout(this.timeOutId);
        this.apiProcess = false;
        this.formSubmitted = false;
        this.router.navigate(['registration/address']);
        return;
    }

    this.subscription = this.beneficiaryServices.put(payload)
    .subscribe(
      (response) => {
        window.clearTimeout(this.timeOutId);
        this.commonService.expiredTokenError = "";
        this.apiProcess = false;
        if(response.status === 200 && response.body.Status){
          const resultData = response.body.data;
          this.errorMessage = "";
          this.localStorage.store("beneficiaryInfo", this.beneficiaryForm);
          const nextRoute = this.commonService.setRouter(resultData.currentStep);
          if(this.prefilledInfo){
            this.prefilledInfo.resumeInfo.currentStep = resultData.currentStep;
            this.localStorage.store('prefilledInfo', this.prefilledInfo);
          }
          if(resultData.enrollmentNumber){
            this.commonService.updateEnrollment(resultData.enrollmentNumber);
          }
          this.router.navigate([nextRoute]);
        }
        else {
          this.errorMessage = response.body.message;
        }
      },
      (error) => {
          window.clearTimeout(this.timeOutId);
          this.commonService.expiredTokenError = "";
          this.apiProcess = false;
          if(!error.error && !error.error.message ){
            error.error.message = "Unknown error";
          }
          this.errorMessage = error.error.message;
        }
      )
  }

  slidePrevious(){
    this.router.navigate(['registration/security'])
  }

  checkValidation(event){
    const {value, name}  = event.currentTarget;

    if(name === "contactNumber"){
      const testNumber = FORM_CONST.PHONE_NUMBER_PATTERN.test(value);
      this.beneficiaryForm[name].isValid = !testNumber;
    }
    else if(name === "firstName"){
      const testNumber = FORM_CONST.NAME_PATTERN.test(value);
      this.beneficiaryForm[name].isValid = !testNumber;
    }
    else if(name === "lastName"){
      const testNumber = FORM_CONST.NAME_PATTERN.test(value);
      this.beneficiaryForm[name].isValid = !testNumber;
    }
    else if(name === "dateOfBirth"){
      this.beneficiaryForm[name].isValid = typeof value === "object" || value  ? false : true; 
    }

    else if(name === "isBqpConsent"){
      this.beneficiaryForm[name].isValid = value ? false : true;
    }

    else if(name === "ssn"){
      const testNumber = FORM_CONST.SSN_PATTERN.test(value);
      this.beneficiaryForm[name].isValid = !testNumber;
    }

    else if(name === "dateOfBirth"){
      this.beneficiaryForm[name].isValid = typeof value === "object" || value  ? false : true; 
    }

    else if (!value){
      this.beneficiaryForm[name].isValid = true;
    }
    else {
      this.beneficiaryForm[name].isValid = false;
    }
  }

  updateSuffix(event){
    const {value, name} = event.currentTarget;
    this.beneficiaryForm[name].value = value;
  }


  onSubmit():void{
    let invalidForm  = false;
    if(this.beneficiaryForm.isApplyingForSelf.value === "No"){
      applyValidation(this.beneficiaryForm);


      invalidForm = throwMessages(this.beneficiaryForm);
      const { isBqpConsent } = this.beneficiaryForm;
      if(!isBqpConsent.value){
        isBqpConsent.isValid = true;
      }

      if(!invalidForm){
        this.timeOutId = window.setTimeout( () => {
          this.apiProcess = true;
        }, 1200);
        this.callApi();
      }
    }
    else {
      const {isAcpConsent } = this.beneficiaryForm;
      if(isAcpConsent.value){
        this.timeOutId = window.setTimeout( () => {
          this.apiProcess = true;
        }, 1200);
        this.callApi();
      }
      else if(!isAcpConsent.value) {
        isAcpConsent.isValid = true;
      }
    }
  }

  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
    if(this.subscriptionRefresh){
      this.subscriptionRefresh.unsubscribe();
    }
    if(this.errorSubscription){
      this.errorSubscription.unsubscribe();
    }
  }
}
