import { Injectable } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { LocalStorage } from 'ngx-webstorage';
import { ISecurityReq } from "../model/security_info.model";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { CommonService } from "./common.service";
import { RefreshServices } from "./refresh.services";

@Injectable({
    providedIn: 'root'
})

export class SecurityFormService {
  protected baseApi: string;
  protected token: string; 
 
  @LocalStorage()
  protected uuid;
  
  @LocalStorage()
  protected xExpire;

  constructor(
      private http:HttpClient,
      private cookieService: CookieService,
      private router: Router,
      private commonService: CommonService,
      private refreshServices: RefreshServices
      ) {
      this.baseApi = environment.BASE_API;
      this.token = this.cookieService.get('x-token');
  }

  put(req: ISecurityReq):Observable<HttpResponse<any>> {
    this.commonService.checkExpiredToken();
    
    const headers = new HttpHeaders({
      'authorization': `Bearer ${this.token}`
    })
    
    return this.http.put(`${this.baseApi}/${environment.AUTH.SECURITY}/${this.uuid}`, req, {headers, observe: 'response'});
    
  }
}
