<div class="login-main register-form">
    <h4 class="font-primary">Enter applicants service address <small class="f-18">(PO is not allowed)</small></h4>
    <h5 class="mt-3 f-16">Please fill your current address</h5>
    <div class="theme-form">
    <div class="row">
        <div class="col-12 col-sm-6">
            <div class="form-group mb-3">
                <label class="col-form-label">Address 1*</label>
                <input 
                    type="text"
                    name="address1"
                    [(ngModel)]="currentAddress.address1.value"
                    [ngClass]="{'is-invalid':currentAddress.address1.isValid}"
                    class="form-control"
                    placeholder="Eg. 123 Street"
                    aria-label="Address Line 1"
                    (blur)="checkValidation($event)"
                    aria-describedby="basic-addon1" />
                <div class="invalid-feedback">
                    Address is required.
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-6">
        <div class="form-group mb-3">
            <label class="col-form-label">Address 2</label>
            <input 
                type="text"
                name="address2"
                class="form-control"
                [(ngModel)]="currentAddress.address2.value"
                placeholder="Eg. Apt, suit"
                aria-label="Address Line 2"
                aria-describedby="basic-addon1"
            />
        </div>
    </div>
        <div class="col-12 col-sm-4">
        <div class="form-group mb-3">
        <label class="col-form-label">City*</label>
            <ng-select
                [items]="allCities"
                name="city"
                class="form-control"
                aria-label="Applicant City"
                aria-describedby="basic-addon1"
                (change)="addressCall($event, 'city')"
                [(ngModel)]="currentAddress.city.value"
                [ngClass]="{'is-invalid': currentAddress.city.isValid}"
              ></ng-select>
            <div class="invalid-feedback">
                City is required.
            </div>
            <div *ngIf="apiProcessAddress" class="spinner-border text-light p-absolute custom-inline-loader" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div *ngIf="cityErrorMessage" class="text-danger f-12">{{cityErrorMessage}}</div>
        </div>
        </div>
        <div class="col-12 col-sm-4">
            <div class="form-group mb-3">
            <label class="col-form-label">State*</label>
            <ng-select
                [items]="allStates"
                name="state"
                class="form-control"
                aria-label="Applicant State"
                aria-describedby="basic-addon1"
                maxlength="2"
                (change)="addressCall($event, 'state')"
                [(ngModel)]="currentAddress.state.value"
                [ngClass]="{'is-invalid': currentAddress.state.isValid }"
              ></ng-select>
            <div class="invalid-feedback">
                State is required
            </div>
            <div *ngIf="stateErrorMessage" class="text-danger f-12">{{stateErrorMessage}}</div>
            <div *ngIf="apiProcessAddress" class="spinner-border text-light p-absolute custom-inline-loader" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        </div>
        <div class="col-12 col-sm-4">
            <div class="form-group mb-3">
                <label class="col-form-label">Zip Code*</label>
                <ng-select
                [items]="allZips"
                name="zipCode"
                class="form-control"
                aria-label="Applicant Zip Code" 
                aria-describedby="basic-addon1"
                maxlength="5"
                (change)="addressCall($event, 'zipCode')"

                [(ngModel)]="currentAddress.zipCode.value"
                [ngClass]="{'is-invalid': currentAddress.zipCode.isValid }"
              ></ng-select>
                <div *ngIf="zipErrorMessage" class="text-danger f-12">{{zipErrorMessage}}</div>
                <div *ngIf="apiProcessAddress" class="spinner-border text-light p-absolute custom-inline-loader" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-12">
            <h5 class="f-16">The above address is your*</h5>
        </div>
        <div class="col-12 col-sm-4">
            <div class="m-t-10 m-b-10 radio radio-primary">
                <input 
                    class="form-check-input"
                    type="radio"
                    name="addressType"
                    value="permanent" 
                    [(ngModel)]="addressType.value"
                    id="addressTypeId-1"
                    (change)="changeApplicantAddressRadioValue($event)"
                    >
                <label class="form-check-label" for="addressTypeId-1">
                    Permanent address
                </label>
                </div>
        </div>
        <div class="col-12 col-sm-4">
            <div class="m-t-10 m-b-10 radio radio-primary">
                <input 
                    class="form-check-input"
                    type="radio"
                    value="temporary"
                    [(ngModel)]="addressType.value"
                    name="addressType"
                    id="addressTypeId-2"
                    (change)="changeApplicantAddressRadioValue($event)"
                />
                <label class="form-check-label" for="addressTypeId-2">
                    Temporary address
                </label>
                </div>
        </div>
        <div class="col-12 col-sm-12">
            <h5 class="m-t-5 f-16 m-b-20">Is your shipping address same as service address?*</h5>
        </div>
        <div class="col-12 col-sm-3">
            <div class="radio radio-primary">
                <input 
                    class="form-check-input"
                    type="radio"
                    value="sameAddress"
                    [(ngModel)]="billingType.value"
                    (change)="changeApplicantAddressRadioValue($event)"
                    name="sameBillingAddress"
                    id="sameBillingAddress-1"
                     />
                <label class="form-check-label" for="sameBillingAddress-1">
                    Yes
                </label>
                </div>
        </div>
        <div class="col-12 col-sm-3">
            <div class="radio radio-primary">
                <input 
                    class="form-check-input"
                    type="radio"
                    value="otherAddress"
                    [(ngModel)]="billingType.value"
                    id="sameBillingAddress-2"
                    (change)="changeApplicantAddressRadioValue($event)"
                    name="sameBillingAddress"
                    >
                <label class="form-check-label" for="sameBillingAddress-2">
                    No
                </label>
                </div>
        </div>
       
    </div>
    <ng-container *ngIf="billingType.value !== 'sameAddress'">
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="form-group mb-3">
                    <label class="col-form-label">Address 1*</label>
                    <input 
                        type="text"
                        name="address3"
                        class="form-control"
                        placeholder="Eg. 123 Street"
                        [(ngModel)]="otherAddress.address3.value"
                        [ngClass]="{'is-invalid':otherAddress.address3.isValid}"
                        (blur)="checkValidation($event)"
                        aria-label="Address Name"
                        aria-describedby="basic-addon1">
                        <div class="invalid-feedback">
                            Address is required
                        </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
            <div class="form-group mb-3">
                <label class="col-form-label">Address 2</label>
                <input 
                    type="text"
                    name="address4"
                    class="form-control"
                    placeholder="Eg. Apt, suit" 
                    [(ngModel)]="otherAddress.address4.value"
                    aria-label="Address Line 2"
                    aria-describedby="basic-addon1"
                />
            </div>
        </div>
            <div class="col-12 col-sm-4">
                <div class="form-group mb-3">
            <label class="col-form-label">City*</label>
                <ng-select
                    [items]="allCities1"
                    name="city1"
                    class="form-control"
                    aria-label="Applicant City"
                    aria-describedby="basic-addon1"
                    (change)="addressCall($event, 'city1')"
                    [(ngModel)]="otherAddress.city1.value"
                    [ngClass]="{'is-invalid': otherAddress.city1.isValid}"
                ></ng-select>
            <div class="invalid-feedback">
                City is required
            </div>
            <div *ngIf="cityErrorMessage1" class="text-danger f-12">{{cityErrorMessage1}}</div>
            <div *ngIf="apiProcessAddress1" class="spinner-border text-light p-absolute custom-inline-loader" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            </div>
            </div>  
            <div class="col-12 col-sm-4">
                    <div class="form-group mb-3">
                    <label class="col-form-label">State*</label>
                    <ng-select
                        [items]="allStates"
                        name="state1"
                        class="form-control"
                        aria-label="Applicant State"
                        aria-describedby="basic-addon1"
                        maxlength="2"
                        (change)="addressCall($event, 'state1')"
                        [(ngModel)]="otherAddress.state1.value"
                        [ngClass]="{'is-invalid': otherAddress.state1.isValid }"
                    ></ng-select>
                    <div class="invalid-feedback">
                        State is required.
                    </div>
                    <div *ngIf="stateErrorMessage1" class="text-danger f-12">{{stateErrorMessage1}}</div>
                    <div *ngIf="apiProcessAddress1" class="spinner-border text-light p-absolute custom-inline-loader" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="form-group mb-3">
                    <label class="col-form-label">Zip Code*</label>
                    <ng-select
                        [items]="allZips1"
                        name="zipCode1"
                        class="form-control"
                        aria-label="Applicant Zip Code" 
                        aria-describedby="basic-addon1"
                        maxlength="5"
                        (change)="addressCall($event, 'zipCode1')"
                        [(ngModel)]="otherAddress.zipCode1.value"
                        [ngClass]="{'is-invalid': otherAddress.zipCode1.isValid }"
                    ></ng-select>
                    <div *ngIf="zipErrorMessage1" class="text-danger f-12">{{zipErrorMessage1}}</div>
                    <div *ngIf="apiProcessAddress1" class="spinner-border text-light p-absolute custom-inline-loader" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
</ng-container>

<div class="col-12 col-sm-12">
    <div class="form-group mt-3 p-l-0">
        <div class="form-check checkbox">
          <input 
                aria-label="You are authorizing Unity License Wireless Inc."
                type="checkbox"
                class="form-check-input"
                type="checkbox"
                [(ngModel)]="liveInTribalRegion.value"
                name="liveInTribalRegion"
                id="liveInTribalRegion-2"
                (change)="changeApplicantAddressRadioValue($event)"
            />
            <label 
                for="liveInTribalRegion-2"
                class="m-t-0"
               >
                Check if you live on Tribal lands.
            </label>
        </div>
        <ng-container *ngIf="liveInTribalRegion.value">
            <div class="tool-tip-wrapper">
            <p class="f-12">* Tribal lands include any federally recognized Indian tribe&rsquo;s reservation,  Pueblo, or colony, including former reservations in Oklahoma; Alaska
                Native regions established pursuant to the Alaska Native Claims Settlement Act (85 Stat. 688) ; Indian allotments; Hawaiian Home Lands—
                areas held in trust for Native Hawaiians by the state of Hawaii, pursuant
                to the Hawaiian Homes Commission Act, 1920 July 9, 1921, 42 Stat. 108, et. seq., as amended; and any land
                designated as such by the FCC pursuant to the designation process in the FCC&rsquo;s Lifeline rules.
                A map of qualifying Tribal lands is available) on USAC&rsquo;s website:
                <a class="f-12" href="https://www.affordableconnectivity.gov/wp-content/uploads/acp/documents/fcc_tribal_lands_map.pdf">
                    https://www.affordableconnectivity.gov/wp-content/uploads/acp/documents/fcc_tribal_lands_map.pdf
                </a>
            </p>
        </div>
        </ng-container>
  </div>
</div>
    <div class="text-danger" *ngIf="!commonService.expiredTokenError && errorMessage">{{errorMessage}}</div>
    <div class="text-danger" *ngIf="commonService.expiredTokenError">{{commonService.expiredTokenError}}</div>
    
    <div *ngIf="!commonService.expiredTokenError && apiProcess" class="api-process-loader">
        <div  class="loader-box">
            <div class="loader-9"></div>
        </div>
    </div>
    <div class="form-group mt-3 text-end">
        <button (click)="slidePrevious()" [disabled]="!commonService.expiredTokenError && apiProcess" class="btn btn-light btn-lg mx-4">Prev</button>
        <button class="btn btn-primary btn-lg" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="onSubmit()">Next</button>
    </div>
</div>
</div>

<ng-template #uspsSuggestionModal let-modal>
    <div class="modal-body">
      <div class="close-modal p-absolute" (click)="modal.close()">
        <i class="icon-close"></i>
      </div>
      <div class="theme-form p-20">
        <p *ngIf="!uspsSuggestion"><strong>Is there an apartment or suite number that you are missing? Double check your address for delivery.</strong></p>
        <p *ngIf="uspsSuggestion" class="f-18"><strong>USPS address confirmation for delivery</strong></p>


      <div class="text-center">
        <img class="address-img border-top-left-radius" src="./../../../../../assets/images/mailbox_6515707.png" alt="Image">
      </div>
      <p *ngIf="!uspsSuggestion">
        Your Address: <strong class="text-uppercase">{{currentAddress.address1.value +', ' + currentAddress.address2.value +' ' + currentAddress.city.value + ' ' + currentAddress.state.value + ' ' +  currentAddress.zipCode.value}}</strong><br/>
        <span *ngIf="billingType.value !== 'sameAddress'">Your Shipping Address: <strong class="text-uppercase">{{otherAddress.address3.value +', ' + otherAddress.address4.value + ' ' + otherAddress.city1.value + ' ' + otherAddress.state1.value + ' ' +  otherAddress.zipCode1.value}}</strong></span>
      </p>

      <h6  *ngIf="uspsSuggestion" class="mt-3 mb-3">USPS Suggested Address</h6>
      <p *ngIf="!uspsSuggestion">The provided (mailing or service) address is Not Verified with USPS for delivery. Please click on modify to update your address.</p>

        <ng-container *ngIf="uspsSuggestion; else failCallToAction">
            
            <p>
            <span *ngIf="uspsSuggestionAddress.billingAddress">Service Address: <strong class="text-uppercase">{{uspsSuggestionAddress.billingAddress.address1 +', ' + uspsSuggestionAddress.billingAddress.address2 +' ' + uspsSuggestionAddress.billingAddress.city +' '+ uspsSuggestionAddress.billingAddress.state +' '+ uspsSuggestionAddress.billingAddress.zip}}</strong></span><br/>
            <span *ngIf="uspsSuggestionAddress.shippingAddress">Shipping Address: <strong class="text-uppercase">{{uspsSuggestionAddress.billingAddress.address1 +', ' + uspsSuggestionAddress.billingAddress.address2 +' ' + uspsSuggestionAddress.billingAddress.city +' '+ uspsSuggestionAddress.billingAddress.state +' '+ uspsSuggestionAddress.billingAddress.zip}}</strong></span>
            </p>
            <ng-container *ngIf="uspsSuggestion">
                <div class="form-group p-t-20 mt-3 text-end">
                <button type="button" class="btn btn-light" (click)="modifyAddress('closeAble')">Modify</button>
                <button type="button" class="btn btn-primary m-l-10" (click)="saveAddress()">Confirm</button>
            
        </div>
    </ng-container> 
    </ng-container>
    <ng-template #failCallToAction>
        <div class="form-group p-t-20 mt-3 text-end">
            <button type="button" class="btn btn-primary" (click)="modal.close('')">Modify</button>
            <button type="button" class="btn btn-light m-l-10" (click)="registrationCancel()">Cancel</button>
        </div>
    </ng-template>   
    </div>
    </div>
  </ng-template>

  