import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'ngx-webstorage';


@Injectable({
  providedIn: 'root'
})

export class RefreshServices {
  
  public baseApi: string;
  public timeOutId: number;

  protected token: string; 

  public refreshTokenSubject: ReplaySubject<any> = new ReplaySubject<any>();  
  tokenData$ = this.refreshTokenSubject.asObservable();

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private cookieService: CookieService
    ){
      this.baseApi = environment.BASE_API;
      this.token = this.cookieService.get('x-token');
    }

   refreshToken(): Observable<HttpResponse<any>> {
            const userID = +this.cookieService.get('x-userId');
           
         
          const refreshTokenData = this.cookieService.get('x-refresh-token');
          const payload = {   
              userID,
              companyId: environment.COMPANY_ID,
              refreshToken: refreshTokenData
          }

        return this.http.post(`${this.baseApi}/${environment.AUTH.REFRESH_TOKEN}`, payload, { observe: 'response'})
         .pipe(
            tap((response: HttpResponse<any>) => {
                 debugger;
                if(response.status == 200 && response.body.Status){
                  // setting expiration time for token;
                  const expires: Date = new Date();
                  const minutes = expires.getMinutes();
                  const nextExpireMinutes = minutes + environment.TOKEN_EXPIRATION_MINUTES;
                  expires.setMinutes(nextExpireMinutes);
                    
                  const token = response.body["token"];
                  const refreshToken = response.body.data[0].refreshToken;

                  this.cookieService.set("x-token", token, {expires});
                  this.cookieService.set("x-refresh-token", refreshToken);
                  this.localStorage.store('xExpire', expires);
                }
               
                // this.refreshTokenSubject.next(response);
            }),
            
        );
      }

    setExpirationTime(){
        const expireTokenTime = new Date();
        const minutes = expireTokenTime.getMinutes();
        const nextExpireMinutes = minutes + environment.TOKEN_EXPIRATION_MINUTES;
        expireTokenTime.setMinutes(nextExpireMinutes);
        const milliseconds = expireTokenTime.getTime();
        this.localStorage.store('tokenExpiration', milliseconds);
    }
}
