 <div  
  class="card cursor-pointer min-height140"
  [ngClass]="{
  'b-warning': programService.nslInvalidCard && programService.checkSelected(program.shortName), 
  'b-success': !programService.nslInvalidCard && programService.checkSelected(program.shortName),
  }"
  id="{{program.shortName}}">
  <div class="program-image-wrapper" (click)="programService.renderAdditionalForm(program, $event, inputFields)">
    <ng-container *ngIf="program.imagePath; else placeHolderImg">
      <img [src]="program.imagePath" class="img-fluid" alt="images" />
    </ng-container>
    <ng-template #placeHolderImg>
      <img src="./assets/images/banner/1.jpg" class="img-fluid" alt="images" />
    </ng-template>
  </div>
  <div class="media p-20">
      <div 
      (click)="programService.renderAdditionalForm(program, $event, inputFields)">
      <!-- <div 
          class="ribbon ribbon-primary"
          >{{program.shortName}}</div> -->
      <div 
          class="p-absolute check-card">
          <app-feather-icons [icon]="'check-circle'"></app-feather-icons>
      </div>
      <h6 
          class="m-t-40 text-center pt-3 f-12"
          [ngClass]="{
          'text-muted': !programService.checkSelected(program.shortName)
          }">
          {{program.name}}
      </h6>
    </div>
    <ng-container *ngIf="programService.checkSelected(program.shortName)">
      <div class="p-absolute text-center badge-list">
        <ng-container *ngFor="let item of NSL; index as i">
          <span 
          *ngIf="item.shortName !== 'schoolName' && item.shortName !== 'benDob'"
          [id]="'nslId'+i" class="badge badge-light text-muted">{{item.value | uppercase }}</span>
        </ng-container>
      </div>
      <div class="pull-right">
        <div class="p-absolute edit-icon" (click)="programService.open(inputFields)">
          <i class="fa fa-pencil-square-o font-primary cursor-pointer"></i>
        </div>
      </div>
    </ng-container>
  </div>
  </div>

  <ng-template #inputFields let-modal>
    <div class="modal-body">
      <div class="close-modal close-modal-nsl p-absolute" (click)="modal.close()">
        <i class="icon-close"></i>
      </div>
       <div class="theme-form p-20">
          <h5 class="m-b-3">Please fill the details</h5>
          <div class="row">
             <ng-container *ngFor="let nslItem of NSL">

                <div class="col-12 col-sm-6" *ngIf="nslItem.displayName.shortName !== 'dob'">
                   <div class="form-group mb-3">
                      <label class="col-form-label">
                      {{nslItem["displayName"].label}} <span *ngIf="nslItem.displayName.shortName !== 'middleName'">*</span>
                      </label>
                      <ng-container *ngIf="nslItem.displayName.label === 'Suffix'; then selectElement else inputElement"></ng-container>
                      <ng-template #selectElement>
                         <select
                         [name]="commonService.getTrimmedValue(nslItem.displayName.label)"
                         class="form-control"
                         placeholder="Eg. John2"
                         aria-label="Suffix"
                         aria-describedby="basic-addon1"
                         [(ngModel)]="nslItem.displayName.value"
                         (change)="programService.checkNSLValidation(nslItem, $event)"
                         [ngClass]="{'is-invalid': nslItem.isValid}"
                         [disabled]="nslItem.displayName.editable === 'N'"
                         >
                         <option 
                           class="p-10"
                           [selected]="option.value === nslItem.displayName.value"
                           *ngFor="let option of programService.prefixData"
                           [value]="option.value">
                           {{ option.displayName }}
                         </option>
                         </select>
                         <div class="invalid-feedback">
                           Required field.
                        </div>
                      </ng-template>
                      <ng-template #inputElement>
                        <ng-container *ngIf="nslItem.displayName.shortName!=='middleName'; else middleName">
                         <input 
                         type="text"
                         [name]="commonService.getTrimmedValue(nslItem.displayName.label)"
                         class="form-control"
                         [placeholder]="'Eg. '+ nslItem.displayName.label"
                         [ariaLabel]="nslItem.displayName.label"
                         [ngClass]="{'is-invalid': nslItem.isValid}"
                         aria-describedby="basic-addon1"
                         [(ngModel)]="nslItem.displayName.value"
                         [maxlength]="nslItem.displayName.shortName==='ssn' && 4"
                         [disabled]="nslItem.displayName.editable === 'N'"
                         (blur)="programService.checkNSLValidation(nslItem, $event)"
                         >
                         <div *ngIf="nslItem.displayName.shortName !=='ssn'; else ssnError" class="invalid-feedback">
                            Required field.
                         </div>
                         <ng-template  #ssnError>
                           <div class="invalid-feedback">
                              Required field, must be a number type data.
                           </div>
                         </ng-template>
                      </ng-container>
                      <ng-template #middleName>
                        <input 
                         type="text"
                         [name]="commonService.getTrimmedValue(nslItem.displayName.label)"
                         class="form-control"
                         [placeholder]="'Eg. '+ nslItem.displayName.label"
                         [ariaLabel]="nslItem.displayName.label"
                         [ngClass]="{'is-invalid': nslItem.isValid}"
                         aria-describedby="basic-addon1"
                         [(ngModel)]="nslItem.displayName.value"
                         [disabled]="nslItem.displayName.editable === 'N'"
                         (blur)="programService.checkNSLValidation(nslItem, $event)"
                         >
                      </ng-template>
                     </ng-template>
                   </div>
                </div>
                <div *ngIf="nslItem.displayName.shortName === 'dob'" class="col-12 col-sm-6" >
                   <div class="form-group mb-3">
                      <label class="col-form-label">Date of Birth*</label>
                      <input 
                      type="text"
                      [name]="commonService.getTrimmedValue(nslItem.displayName.label)"
                      class="form-control"
                      [disabled]="nslItem.displayName.editable === 'N'"
                      placeholder="Eg. mm/dd/yyyy"
                      (change)="programService.checkNSLValidation(nslItem, $event)"
                      aria-label="Date of Birth"
                      [ngClass]="{'is-invalid': nslItem.isValid}"
                      aria-describedby="basic-addon1"
                      [(ngModel)]="nslItem.displayName.value"
                      [bsConfig]="programService.datePickerConfig"
                      bsDatepicker
                      >
                      <div class="invalid-feedback">
                         Incorrect ages. Ages should be in between 4-22 years.
                      </div>
                   </div>
                </div>
             </ng-container>
          </div>
          <div class="form-group mb-3 text-end">
            <button type="submit" class="btn btn-light" (click)="programService.resetNSLForm()">Reset</button>
            <button type="button"  class="btn btn-primary m-l-10" (click)="programService.saveNSLForm('save and close')">Save and Close</button>
         </div>     
       </div>
    </div>
  </ng-template>