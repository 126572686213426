import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { userAgeValidator } from 'src/app/shared/validators/ageMatchValidator';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { PersonalFormService } from 'src/app/shared/services/personal_form.services';
import { environment } from 'src/environments/environment';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { RefreshServices } from 'src/app/shared/services/refresh.services'; 
import { CommonService } from 'src/app/shared/services/common.service';
import { CookieService } from 'ngx-cookie-service';
import { FORM_CONST } from 'src/app/shared/constants';
import { Router } from '@angular/router';

interface IPrefix {
  displayName: string;
  value: string;
}

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})

export class PersonalComponent implements OnInit, AfterViewInit, OnDestroy {
  public personalInfoForm: FormGroup;
  protected companyId: string;
  
  private subscription: Subscription;
  private subscriptionRefresh: Subscription;
  public isRefreshToken: boolean = false
  public formSubmitted: boolean = false;
  public apiProcess:boolean = false;
  public errorMessage: string;
  public prefixData: IPrefix[];
  public errorCollection: string[];
  private timeOutId: number = -1;


  datePickerConfig: Partial<BsDatepickerConfig>;

  @LocalStorage()
  protected zipCode;

  @LocalStorage()
  protected email;

  @LocalStorage()
  prefilledInfo;

  @LocalStorage()
  personalInfo;

  @LocalStorage()
   stepperPrefilledData
  
  @Input()
  public filledData;

  @Output()
  slideNextEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private personalFormService: PersonalFormService,
    private tokenService: RefreshServices,
    private localStorage : LocalStorageService,
    private cookieService: CookieService,
    public commonService: CommonService,
    private router: Router
    ){

     this.commonService.setStepInfo('registration/personal');

     this.prefixData = environment.PREFIX_DATA;
     this.companyId = environment.COMPANY_ID;
     const minYear:number = new Date().getFullYear() - 100;
     const maxYear:number = new Date().getFullYear() - 18 ;

     const minDate = new Date();
     minDate.setFullYear(minYear);

     const maxDate = new Date();
     maxDate.setFullYear(maxYear);

    this.datePickerConfig = Object.assign({}, { 
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      minDate,
      maxDate,
     }); 
     
  }

  // date = new Date('11-09-1988')

  ngOnInit(){
    this.tokenService.tokenData$.subscribe(
      () => {},
      (error) => {
        this.errorMessage = error;
      }
    )
    

    this.personalInfoForm =  this.fb.group({
      firstName: ['', 
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.pattern(FORM_CONST.NAME_PATTERN),
        ]
      ],
      middleName: [''],
      lastName: ['',
        [
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.required,
          Validators.pattern(FORM_CONST.NAME_PATTERN)
        ]],
      suffix: [''],
      dateOfBirth: [
        '', 
        [
          Validators.required,
          userAgeValidator
        ]
      ],
    });

    if(this.personalInfo){
      const dob = new Date(this.personalInfo.dateOfBirth);
      this.personalInfoForm.patchValue({
        ...this.personalInfo,
        dateOfBirth: dob
      })
    }
  }


  get firstName(){
    return this.personalInfoForm.get("firstName");
  } 
  
  get lastName(){
    return this.personalInfoForm.get("lastName");
  } 
  
  get middleName(){
    return this.personalInfoForm.get("middleName");
  } 
  
  get dateOfBirth(){
    return this.personalInfoForm.get("dateOfBirth");
  }
  get suffix(){
    return this.personalInfoForm.get("suffix");
  }

  submitPersonalInfoForm(){
    if(this.personalInfoForm.valid){
      this.timeOutId = window.setTimeout( () => {
        this.apiProcess = true;
      }, 1200);
      this.errorMessage = '';
      this.errorCollection = [];

      const {firstName, middleName, lastName, suffix, dateOfBirth } = this.personalInfoForm.value;
      const dd = dateOfBirth.getDate().toString();
      const mm = dateOfBirth.getMonth() + 1;
      const yyyy = dateOfBirth.getFullYear().toString();
      
      const dob = {
        mm : `${mm}`.length === 1 ? `0${mm}` : mm,
        dd: dd.length === 1 ? `0${dd}` : dd,
        yyyy
      }

      const userID = +this.cookieService.get('x-userId');
      
      const payload = {
        companyId: environment.COMPANY_ID,
        userID,
        zipCode: this.zipCode,
        email: this.email,
        source: "WebBrowser",
        enrolmentType: "Enroll",
        firstName,
        middleName,
        lastName,
        suffix,
        dateOfBirth: `${dob.mm}-${dob.dd}-${dob.yyyy}`,
      }

      const storeData = {
        email: this.email,
        firstName,
        middleName,
        lastName,
        suffix,
        dateOfBirth, //upper scope data,
      }
      
      if(JSON.stringify(this.personalInfo) === JSON.stringify(storeData)){
        window.clearTimeout(this.timeOutId);
        this.apiProcess = false;
        this.formSubmitted = false;
        this.router.navigate(['registration/security']);
        return;
      }
        
      this.subscription =  this.personalFormService.put(payload).subscribe(
        (response) => {

          window.clearTimeout(this.timeOutId);
          this.commonService.expiredTokenError = "";
          this.apiProcess = false;
          if(response.status === 200 && response.body.Status){
            if(response.body.data.enrollmentNumber){
              this.commonService.updateEnrollment(response.body.data.enrollmentNumber);
            }

            this.formSubmitted = true;
            const {email, firstName, lastName, middleName, suffix } = payload;
            const storeData = {
              email,
              firstName,
              middleName: middleName ? middleName : "",
              lastName,
              suffix,
              dateOfBirth, //upper scope data,
            }
            this.localStorage.store('personalInfo', storeData);
            if(this.prefilledInfo){
              this.prefilledInfo["resumeInfo"] = {
                resumeAvailable: true,
                currentStep: response.body.data.currentStep
              }
              this.localStorage.store('prefilledInfo', this.prefilledInfo);
            }
            
            const nextRoute = this.commonService.setRouter(response.body.data.currentStep);
            this.router.navigate([nextRoute]);
         }
          else {
            const {body} = response;
            if('error' in body && typeof body["error"] === 'object'){
              const keys = Object.keys(body.error);
              
              keys.map( (fieldKey:string) => {
                if(fieldKey === 'firstName'){
                  this.firstName.setErrors({customError: body["error"].firstName});
                }
                else if(fieldKey === 'middleName'){
                  this.middleName.setErrors({customError: body["error"].middleName});
                }
                else if(fieldKey === 'lastName'){
                  this.lastName.setErrors({customError: body["error"].lastName});
                }
                else if(fieldKey === 'dateOfBirth'){
                  this.dateOfBirth.setErrors({customError: body["error"].dateOfBirth});
                }
                else if(fieldKey === 'suffix'){
                  this.suffix.setErrors({customError: body["error"].suffix});
                }
              })
            }
                
            this.errorMessage = response.body.message;
            this.formSubmitted = false;
            const {error} = response.body;
            if(typeof error === 'string'){
              this.errorMessage = error;
            }
            const result = this.commonService.getErrorMessageArranged(error)
            if(result instanceof Array){
              this.errorCollection = [...result];
            }
          }
        },
        (error) => {
          window.clearTimeout(this.timeOutId);
          this.apiProcess = false;
          this.formSubmitted = false
          this.commonService.expiredTokenError = "";
          if(!error.error ){
            this.errorMessage = "Unknown error";
          }
          else {
            this.errorMessage = error.error.message;
          }
        }
      );
    }
    else {
      this.personalInfoForm.markAllAsTouched();
    }
  }
  
  ngAfterViewInit(){
    
  }

  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
    if(this.subscriptionRefresh){
      this.subscriptionRefresh.unsubscribe();
    }
  }
}
