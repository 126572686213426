import { Injectable, 
  // OnInit
 } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, 
  // HttpHeaders,
  HttpResponse } from "@angular/common/http";
import { INetworkReq } from "../model/network.model";
import { environment } from "src/environments/environment";
import { Observable, ReplaySubject, catchError, map, throwError } from 'rxjs';
import { LocalStorage } from "ngx-webstorage"; 
// import { CommonService } from "./common.service"; 

@Injectable({
  providedIn: 'root'
})

export class ServiceAvailability {

    protected baseApi: string;
    public token: string = '';

    private myDataSubject = new ReplaySubject<HttpResponse<any>| null>(null);
    myData$ = this.myDataSubject.asObservable();

    @LocalStorage()
    protected xExpire;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    // private commonService: CommonService
    ) {
      this.token = this.cookieService.get('x-token');
    } 

    post(req: INetworkReq):Observable<HttpResponse<any>> {

      // this.commonService.checkExpiredToken();

      // const headers = new HttpHeaders({
      //    'authorization': `Bearer ${this.token}`
      // })
      
      return this.http.post(`${environment.BASE_API}/${environment.AUTH.NETWORK_AVAILABILITY}`, req, { 
        // headers,
        observe: 'response'})
      .pipe(
        map(
          (response:HttpResponse<any>) => {
          if(response.status === 200 && response.body.Status){
            this.myDataSubject.next(response.body.data[0])
          }
          return response
        }),
        catchError(refreshError => {
          return throwError(() => {
            this.myDataSubject.error(refreshError)
            return refreshError;
          });
        })
      );        
    }

  }
