  <div class="login-main register-form">
     <ng-container *ngIf="commonService.enrollMsg?.toLowerCase() === 'complete'">
        <div class="success-icon text-center mb-3">
          <app-feather-icons [icon]="'check-circle'"></app-feather-icons>
       </div>
         <h2 class="f-w-100 txt-success text-center">Congratulation!</h2>
        <h5 class="text-center p-t-20 p-b-40">You have successfully completed your application for Affordable Connectivity Program. Your enrollment id is <span class="text-info">{{prefilledInfo.enrollmentNumber}}</span></h5>
        <h5 class="text-center p-t-20 p-b-40">Your order is under processing and you should receive your order within 7-10 working days. You will also be notified when your order has been shipped on your provided email address.</h5>
      </ng-container>
      <!-- <div class="form-group mb-3 text-center">
            <button class="btn btn-primary btn-lg" (click)="completed()">Completed Registration</button>
        </div> -->
        <ng-container  *ngIf="commonService.enrollMsg?.toLowerCase() === 'review'">
          <div class="success-icon text-center mb-3">
            <app-feather-icons [icon]="'check-circle'"></app-feather-icons>
         </div>

         <h2 class="f-w-100 txt-success text-center">National Verifier checking your documents</h2>
         <h5 class="text-center p-t-20 p-b-40">Thank you for submitting your information. National Verifier is looking at your documents to make sure you qualify. Your enrollment id is <span class="text-info">{{prefilledInfo.enrollmentNumber}}</span></h5>  
         <h5 class="text-center p-t-20 p-b-40">This may take some time.  Your status will be updated in the system when your documents have been reviewed. Please check back later to see if you qualify for the Affordable Connectivity Benefit.</h5>
      </ng-container>
 </div>
      