import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import Stepper from 'bs-stepper'; 
import { LocalStorage, SessionStorageService } from 'ngx-webstorage';
import { Subscription, filter } from 'rxjs';
import { ServiceAvailability } from 'src/app/shared/services/network.services'; 
import { ProgramServices } from 'src/app/shared/services/program.services';
import { PlanServices } from 'src/app/shared/services/plan_device.services';
import { CommonService } from 'src/app/shared/services/common.service';
import {  ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})

export class StepperComponent implements OnInit, AfterViewInit, OnDestroy {
  
  public showOwlCarousel = true;

  private subscription: Subscription;
  private subscriptionProgram: Subscription;

  public errorMessage: string;
  public question: Record<string, any>;
  private stepInfo:string;
  public enrollmentId: string | null;
  
  
  public stepper: Stepper;

  public stepperPrefilledData: any | null;
  
  @LocalStorage()
  public prefilledInfo;

  @LocalStorage()
  enrollmentIdStorage

  count = 0; 
  disableStepper1 = true;
  disableStepper2 = true;
  disableStepper3 = true;
  disableStepper4 = true;

  constructor( 
    private sessionService: SessionStorageService,
    private networkServices: ServiceAvailability,
    private programService: ProgramServices,
    private planService: PlanServices,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private router: Router
    ){
      this.subscription = this.commonService.enrollmentInfo$.subscribe(
        data => {
          this.enrollmentId = data
        }
      )
    }
    
    setActiveSlide(index: number): void {
      // todo
    }

  buttonEnable(num: number):void {
    if(num === 1){
      this.disableStepper2 = false;
    }
    else if(num === 2){
      this.disableStepper3 = false;
    }
    else if(num === 3){
      this.disableStepper4 = false;
    }
  }

  next():void{
    this.stepper.next();
  }

  prev():void{
    this.stepper.previous();
  }
  
  // getPlanData(){
  //   const payload = {
  //     companyId: environment.COMPANY_ID,
  //     userID: environment.USER_ID
  //   }
  //   this.planService.getPlanReq(payload);
  // }

  ngOnInit(): void {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if(event.url === '/registration'){
        this.commonService.expiredTokenError = "";
        let nextUrl: string = '';
        if(this.prefilledInfo && this.prefilledInfo.resumeInfo.resumeAvailable){
          const { currentStep } = this.prefilledInfo.resumeInfo;
          nextUrl = this.commonService.setRouter(currentStep);
        }
        else {
          nextUrl = "/";
        }
        this.router.navigate([nextUrl]);
      }
    });

    this.commonService.stepInformation$.subscribe(
      (data) => {
        this.stepInfo = data
        this.updateStepper();
      }
    );

    this.networkServices.myData$.subscribe(
      data => {
          this.stepperPrefilledData = data;
      }
    );

    if(!this.stepperPrefilledData){
      this.stepperPrefilledData = this.prefilledInfo;
    }

    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    });
  }

  updateStepper() {
    this.toggleStepperState(this.stepInfo);
    if(this.stepper){
      if(this.stepInfo === 'step1' ){
        this.stepper.to(1);
      }
      else if(this.stepInfo === 'step2' ){
        this.stepper.to(2);
      }
      else if(this.stepInfo === 'step3' ){
        this.stepper.to(3);
      }
      else if(this.stepInfo === 'step4' ){
        this.stepper.to(4);
      }
    }
  }

  toggleStepperState(step){
    if(!step){
      return;
    }
  }

  ngAfterViewInit(){ 
    this.programService.stepper = this.stepper;
    this.planService.stepper = this.stepper;
  
    if(this.stepperPrefilledData && this.stepperPrefilledData.resumeInfo.resumeAvailable){
    const { currentStep } = this.stepperPrefilledData.resumeInfo;
    const nextUrl = this.commonService.setRouter(currentStep);  
      this.router.navigate([nextUrl])
    }
  }
  
  clipboard(id): void {
    // console.log(id);
    this.commonService.onCopyToClipboard(id)
   }

  ngOnDestroy():void{
    this.sessionService.clear();

    if(this.subscription){
      this.subscription.unsubscribe();
    }
    if(this.subscriptionProgram){
      this.subscriptionProgram.unsubscribe();
    }
  }


}
