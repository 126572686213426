import {Injectable, TemplateRef} from "@angular/core";
import {HttpClient,  HttpParams, HttpResponse} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { IGetProgramReq, IIncomeBaseNumberOfPerson, ISaveProgramReq } from "../model/program.model";
import { LocalStorage } from "ngx-webstorage";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import {
  ModalDismissReasons,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "./common.service";
import Stepper from "bs-stepper";
import { RefreshServices } from "./refresh.services"; 
import { CookieService } from "ngx-cookie-service";
import { FORM_CONST } from "../constants"; 

@Injectable({
  providedIn: 'root'
})

export class ProgramServices {

  public getProgramResponse = new BehaviorSubject<any>(null);
  getProgramResponse$ = this.getProgramResponse.asObservable();

  protected baseApi: string;
  protected token: string; 
  public loadProgram: boolean = false;
  public programs: any;
  public nslInvalidCard: boolean = false;
  public fphSelected:string = '';
  public fphInvalidCard: boolean = false;
  public stepper: Stepper;
  public saveProgramSuccessMessage:string = "";
  public ibSelectedReq: any;

  private reqNSLPayload = {
    "bqpSchoolName": "",
    "bqpFirstName": "",
    "bqpMiddleName": "",
    "bqpLastName": "",
    "bqpSuffix": "",
    "bqpDOB": "",
    "bqpSSN": ""
  };

  private reqIBPayload:any;

  private fphPayload: any;

  public showIBConfirmBox:boolean = false; 
  public ibSelected: string = '';
  public ibSelectedNumberOfPeople: number;
  public isIncomeSame: 'Y' | 'N';
  public ibInvalidCard: boolean = false;
  public ibProgramErrorMessage: string = '';
  public ibmoreThanFourBadges: IIncomeBaseNumberOfPerson | null = null;
  public refElement: HTMLInputElement | null = null
  
  // require for save endpoint
  public subProgram: any[]; 
  public errorMessageCollection: string[] = [];

  public programForm = {
    checkboxes: []
  };


  // use this payload for saving the data to endpoint
  public programFormPayload:any[];

  public datePickerConfig: Partial<BsDatepickerConfig>;

  public prefixData = environment.PREFIX_DATA;

  public NSL: any;
  public FPH: any;
  public IB: any[];

  public closeResult: string;


  public formSubmitted: boolean = false;
  public apiProcess:boolean = false;
  public errorMessage: string;


  @LocalStorage()
  protected uuid;

  @LocalStorage()
  protected xExpire;

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private refreshService: RefreshServices,
    private commonService: CommonService,
    // private router: Router,
    private cookieService: CookieService
    ) { 
        this.baseApi = environment.BASE_API;
        this.token = this.cookieService.get('x-token');

        const minYear:number = new Date().getFullYear() - 22;
        const maxYear:number = new Date().getFullYear() - 4;

        const minDate = new Date();
        minDate.setFullYear(minYear);

        const maxDate = new Date();
        maxDate.setFullYear(maxYear);
        
        this.datePickerConfig = Object.assign({}, { 
          containerClass: 'theme-dark-blue',
          showWeekNumbers: false,
          minDate
        }); 
    }

  setIBData(){}  

  @LocalStorage()
  prefilledInfo;

  @LocalStorage()
  programInfo

  prefilledProgram(){
    if(this.prefilledInfo){
      // if('step7' in this.prefilledInfo.steps){
      //   this.prefilledInfo.steps.step7.map(
      //     program => {
      //       this.programForm.checkboxes.push(program.shortName);
      //       if(program.shortName.toLowerCase() === 'nsl'){
      //         console.log("nsl", program);
      //       }
      //       if(program.shortName.toLowerCase() === 'fph'){
      //         console.log("fph", program);
      //       } 
      //       if(program.shortName.toLowerCase() === 'ib'){
      //         console.log("ib", program);
      //       } 
      //     }
      //   )
      }
      else if(this.programInfo){
        //  this.programInfo.map(
          // program => {
          //   this.programForm.checkboxes.push(program.shortName);
          //   if(program.shortName.toLowerCase() === 'nsl'){
          //     console.log("nsl", program);
          //   }
          //   if(program.shortName.toLowerCase() === 'fph'){
          //     console.log("fph", program);
          //   } 
          //   if(program.shortName.toLowerCase() === 'ib'){
          //     console.log("ib", program);
          //   } 
          // }
        // ) 
      }
    } 
  
  
  next(){
    this.stepper.next();
  }

  getProgram(req: IGetProgramReq): Observable<any> {
    
    // this.commonService.checkExpiredToken();
    
    // const headers = new HttpHeaders({
    //     'authorization': `Bearer ${this.token}`
    // })

    const {userID, companyId } = req;
    const params = new HttpParams()
        .set('userID', userID)
        .set('companyId', companyId);
    
    return this.http.get(`${this.baseApi}/${environment.AUTH.GET_PROGRAMS}/${this.uuid}`, {
        // headers,
        params,
        observe: 'body',
    })
    .pipe(
      tap(
        response => {
        
        this.errorMessage = "";
        const IB = response["data"].programs.find(v => v.shortName === 'IB');
        if(IB){
          this.IB = IB.incomeBaseProgramMapping;
        }
        const NSL = response["data"].programs.find(v => v.shortName === 'NSL');
        if(NSL){
          this.NSL = NSL.inputFields;
        }
        const FPH = response["data"].programs.find(v => v.shortName === 'FPH');
        if(FPH){
          this.FPH = FPH.subProgram;
        }
        
        this.getProgramResponse.next(response["data"].programs);
        this.programs = response["data"].programs;
        
        this.prefilledProgram();
        this.loadProgram = true;
        return response
        },
      )
    )
  }

  checkProgramSelected(sName){
    const index = this.programForm.checkboxes.indexOf(sName);
    if(index > -1){
      return true
    }
    return false;
  } 

  
  saveProgram(req: ISaveProgramReq): Observable<any> {
    this.commonService.checkExpiredToken();
 
    return this.http.put(`${this.baseApi}/${environment.AUTH.SAVE_PROGRAMS}/${this.uuid}`, req, { observe: 'response'})
    .pipe(
      map(
        (response:HttpResponse<any>) => {
          this.errorMessage = "";
          if(response.status === 200 && response.body.Status){
            return response;
          }
          else  {
            if(typeof response.body.error === 'string'){
              this.errorMessage = response.body.error;
              
            }
            else if(typeof response.body.error === 'object' && response.body.error instanceof Object) {
              this.errorMessageCollection = this.commonService.getErrorMessageArranged(response.body.error)
              this.errorMessage = response.body.error;
            }
            return response;
          }
        }
      )
    );
  }

  saveProgramDataAndValidate(){
      const result = [];
      let payload = null;
        this.programs.map( programItem => {
           payload = Object.assign({}, {
            id: programItem.id,
            nladCode: programItem.nladCode
          })

          this.programForm.checkboxes.map(
            shortName => {
          
              if(programItem.shortName === shortName){
              let items; 
              if(shortName !== 'FPH' && shortName !== 'NSL' && shortName !== 'IB'){
                
                items = Object.assign({}, programItem);
                this.commonService.deleteObjectKeys(['sortOrder', 'type', 'imagePath', 'name', 'shortName'], items);
                const data = result.find(item => item.id === items.id)
                if(!data){
                  result.push(items);
                  return;
                }
              }
              else if(shortName === 'FPH'){
                payload = {
                  ...payload,
                  ...this.fphPayload
                }
                result.push(payload)
              }
              else {
                return;
              }
            }  
          })
        } 
    )
    if(this.reqNSLPayload.bqpFirstName){
      result.push({
        ...payload, 
        inputFields: this.reqNSLPayload
      });
    }

    if(this.reqIBPayload){
      const newObj = {...this.reqIBPayload}
      delete newObj.incomeValues;
      
      result.push({
       ...payload,
       incomeBaseProgramMapping: newObj
      });
    }
    return result;
  }

  checkSelected(sName){
    const index = this.programForm.checkboxes.indexOf(sName);
    if(index > -1){
      return true
    }
    return false;
  } 

  renderAdditionalForm(...params):void{
    const program = params[0];

    if(program.shortName.toLowerCase() === 'fph' && !this.fphInvalidCard){
      this.fphInvalidCard = true;
    }
    if(program.shortName.toLowerCase() === 'nsl' && !this.nslInvalidCard){
      this.nslInvalidCard = true;
    }
    if(program.shortName.toLowerCase() === 'ib' && !this.ibInvalidCard){
      this.ibInvalidCard = true;
    }
    if(params.length > 1){
     !this.checkSelected(program.shortName) && this.open(params[2]);
    }

    const {shortName} = program;
    if(this.checkSelected(shortName)){
      const index = this.programForm.checkboxes.indexOf(shortName);
      this.programForm.checkboxes.splice(index, 1);
      if(shortName === 'ib'){
        this.resetIBForm();
      }
      if(shortName === 'fph'){
        this.resetFPHForm();
      }
    }
    else {
       const reqPayload = Object.assign({}, program);
        delete reqPayload.shortName; 
        delete reqPayload.type;
        delete reqPayload.sortOrder;
        delete reqPayload.imagePath;
        delete reqPayload.subProgram; 
        this.programForm.checkboxes.push(shortName);
    }
  }

  resetNSLForm(){
    this.NSL.forEach( element => {
      const {displayName, shortName} = element;

      displayName.value="";
      if(shortName !== 'middleName' || shortName !== 'suffix'){
        if(element["isValid"] === true){
          element["isValid"] = false;
        }
      }
    })
  }

  saveNSLForm(reason){
    const invalid = this.NSL.filter( v => {
      return v["isValid"] === true
    });

    if(invalid.length > 0){
      this.nslInvalidCard = true;
      return;
    }

    else {
      this.nslInvalidCard = false;
      
      this.modalService.dismissAll(reason);
        
      this.NSL.map( item => {
        const {displayName} = item;
        const {value, shortName} = displayName;
        if(shortName === 'schoolName'){
          this.reqNSLPayload.bqpSchoolName = value;
        }
        else if(shortName === 'firstName'){
          this.reqNSLPayload.bqpFirstName = value;
        }
        else if(shortName === 'middleName'){
          this.reqNSLPayload.bqpMiddleName = value;
        }
        else if(shortName === 'lastName'){
          this.reqNSLPayload.bqpLastName = value;
        }
        else if(shortName === 'suffix'){
          this.reqNSLPayload.bqpSuffix = value;
        }
      
        else if(shortName === 'dob'){
          if(typeof value === 'string'){
            this.reqNSLPayload.bqpDOB = value;
            return;
          }
          const dd = value.getDate().toString();
          const mm = value.getMonth() + 1;
          const yyyy = value.getFullYear().toString();
          const dob = {
            mm : `${mm}`.length === 1 ? `0${mm}` : mm,
            dd: dd.length === 1 ? `0${dd}` : dd,
            yyyy
          }
          this.reqNSLPayload.bqpDOB = `${dob.mm}-${dob.dd}-${dob.yyyy}`;
        }
        else if(shortName === 'ssn'){
          this.reqNSLPayload.bqpSSN = value;
        }
      })
    }
  }

  setIBString(key):string{
    switch(key){
      case 'incomeValueAllStates':
        return 'All states: '; 
        
      case 'incomeValueAlaska':
        return 'In Alaska: '
        
      case 'incomeValueHawaii':
        return 'In Hawaii: '

      default :
        return ''  
    }
  }

  public objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }
  
  hasModal(shortName):boolean{
    if(shortName === 'NSL' || shortName === 'FPH' || shortName === 'IB'){
      return true
    }
    return false
  }
  
  open(content: TemplateRef<any>) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true  }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}
  
  private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'by pressing ESC';
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'by clicking on a backdrop';
			default:
				return `with: ${reason}`;
		}
	}

  checkNSLValidation(nslItem, event): void{
    let value;
    if(typeof event === 'object'){
      value = event.currentTarget.value;
    }
    const { shortName } = nslItem.displayName;

    if(shortName === 'middleName' || shortName === 'suffix'){
      return;
    }
    else {
      
      if(!value){
         this.NSL.forEach( element => {
          if(element.displayName.shortName === shortName){
            element["isValid"] = true;
            this.nslInvalidCard = true;
          }
        })
      }
      else if(shortName === 'firstName' || shortName === 'lastName'){
        this.NSL.forEach( element => {
          if(element.displayName.shortName === 'firstName' ||  shortName === 'lastName'){
            if(FORM_CONST.NAME_PATTERN.test(value)){
                element["isValid"] = false;
                this.nslInvalidCard = false;
            }
            else {
              element["isValid"] = true;
              this.nslInvalidCard = true;
            }
          }
        })
      }
      else if(shortName === 'ssn'){
        this.NSL.forEach( element => {
          if(element.displayName.shortName === 'ssn'){
            if(typeof +value === 'number' && `${value}`.length === 4){
                element["isValid"] = false;
                this.nslInvalidCard = false;
            }
            else {
              element["isValid"] = true;
              this.nslInvalidCard = true;
            }
          }
        })
      }
      else if(`${value}`.length < 3 && `${value}`.length > 50){
        this.NSL.forEach( element => {
          if(element.displayName.shortName === shortName){
            element["isValid"] = true;
            this.nslInvalidCard = true;
          }
        })
      }
      else {
        this.NSL.forEach( element => {
          if(element.displayName.shortName === nslItem.displayName.shortName){
            element["isValid"] = false;
            this.nslInvalidCard = false;
          }
        })
      }
    }
  }


  saveFPHForm(reason){

    if(!this.fphSelected){
      this.fphInvalidCard = true;
      return;
    }

    this.modalService.dismissAll(reason);
  }


  resetFPHForm(){
    if(this.fphSelected){
      this.fphInvalidCard = false;
      this.fphSelected = '';
      return;
    }
  }

  fphOnChange(shortName){
    // debugger;
    this.fphSelected = shortName;
    this.fphInvalidCard = false;
    // this.FPH

    const selected = this.FPH.find( (element) => element.shortName === shortName );
    const cloneSelected = {...selected};

    this.commonService.deleteObjectKeys(['imagePath', 'name', 'shortName', 'shortName', 'sortOrder'], cloneSelected)
    this.fphPayload = {
      subProgram: [
        cloneSelected
        ] 
    }
  }

  ibOnChange(ibProgram, ref){
    if(ibProgram === 'moreThanFour'){
      this.ibSelected = 'moreThanFour';
      this.ibmoreThanFourBadges ? this.ibmoreThanFourBadges = null : '';
      this.refElement = ref;
      this.ibInvalidCard = true;
      ref.focus();
      return;
    }

    const {numberOfPeople} = ibProgram;

    this.ibProgramErrorMessage = "";
    if(this.ibSelected === numberOfPeople){
      return;
    }
    else {
        this.ibSelected = numberOfPeople;
        if(typeof numberOfPeople === 'number'){
          this.reqIBPayload = ibProgram;
          this.ibInvalidCard = false;
          this.ibSelectedNumberOfPeople = numberOfPeople;
          this.ibInvalidCard = true;
          this.refElement ? this.refElement.value = '' : '';
          setTimeout(()=> { 
            this.showIBConfirmBox = true
          }, 500)
          return;
        }
      }
    }

  moreIBpersonValidation(){
    const inputValue = parseInt(this.refElement.value);
    if(!inputValue){
      this.ibInvalidCard = true;
      this.ibProgramErrorMessage = "Only number accepted";
      this.ibmoreThanFourBadges = null;
      this.ibSelectedNumberOfPeople = -1;
    }
    else if(isNaN(inputValue)){
      this.ibInvalidCard = true;
      this.ibmoreThanFourBadges = null;
      this.ibProgramErrorMessage = "Only number accepted";
      this.ibSelectedNumberOfPeople = -1;
    }
    else if(inputValue < 5 || inputValue > 20 ){
      this.ibInvalidCard = true;
      this.ibmoreThanFourBadges = null;
      this.ibProgramErrorMessage = "People range should be in between 5 to 20";
    }
    else {
      this.ibProgramErrorMessage = '';
      const findData = this.IB.find((element) => element.numberOfPeople === inputValue);
      this.ibmoreThanFourBadges = findData;
      this.reqIBPayload = findData;
      this.ibSelectedNumberOfPeople = inputValue;
      setTimeout(()=> { 
        this.showIBConfirmBox = true
       }, 500)
    }
  }
 
  public updateIBUserRes(string){
    this.reqIBPayload.isIncomeSame = string;
    this.ibInvalidCard = false;
    this.showIBConfirmBox = false;
  }

  saveIBForm(reason){
    if(!this.reqIBPayload){
      this.ibInvalidCard = true;
      this.ibProgramErrorMessage = "No option selected, please choose one."
      return;
    }
    else if(this.ibSelected === 'otherIncome' && this.ibInvalidCard){
      return;
    }
    
    this.modalService.dismissAll(reason);
  }
  
  resetIBForm(){
    if(this.ibSelected){
      this.ibInvalidCard = false;
      this.ibSelected = '';
      this.ibProgramErrorMessage = '';
      return;
    }
  }
}
