
    <div class="login-main register-form gte-md-m-t-50">
        <h4 class="font-primary">Personal Information</h4>
        <p class="f-12">The name you use on official documents, like your Social Security Card or State ID. Not a nickname.</p>
        <form [formGroup]="personalInfoForm" class="theme-form">
            <div class="row">
                <div class="col-12 col-sm-6 ">
                    <div class="form-group mb-3">
                        <label class="col-form-label" for="personalFormFirstName">First Name*</label>
                        <input 
                            type="text"
                            formControlName="firstName"
                            id="personalFormFirstName"
                            name="firstName"
                            class="form-control"
                            placeholder="Eg. John"
                            aria-label="First Name"
                            [ngClass]="{'is-invalid':firstName.touched && firstName.invalid}"
                            aria-describedby="basic-addon1">
                            <div class="invalid-feedback">
                                Minimum 3 and maximum 50 char allowed
                            </div>
                            <div class="text-danger f-12" *ngIf="firstName.errors">{{ firstName.errors["customError"]}}</div>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="form-group mb-3">
                        <label class="col-form-label" for="personalFormMiddleName">Middle Name</label>
                        <input 
                            type="text"
                            formControlName="middleName"
                            name="middleName"
                            class="form-control"
                            placeholder="Eg. Middle name"
                            aria-label="Middle Name"
                            id="personalFormMiddleName"
                            aria-describedby="basic-addon1">
                            <div class="text-danger f-12" *ngIf="middleName.errors">{{ middleName.errors["customError"]}}</div>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group mb-3">
                      <label class="col-form-label" for="personalFormLastName">Last Name*</label>
                      <input 
                          type="text"
                          formControlName="lastName"
                          name="lastName"
                          id="personalFormLastName"
                          class="form-control"
                          placeholder="Eg. Smith"
                          aria-label="Last Name"
                          aria-describedby="basic-addon1"
                          [ngClass]="{'is-invalid':lastName.touched && lastName.invalid}">
                          <div class="invalid-feedback">
                            Minimum 3 and maximum 50 char allowed
                        </div>
                        <div class="text-danger f-12" *ngIf="lastName.errors">{{ lastName.errors["customError"]}}</div>
                  </div>
              </div>
               <div class="col-12 col-sm-6">
                <div class="form-group mb-3">
                    <label class="col-form-label" for="personalFormSuffix">Suffix</label>
                    <select
                        formControlName="suffix"
                        name="suffix"
                        id="personalFormSuffix"
                        class="form-control"
                        placeholder="Eg. John2"
                        aria-label="Suffix"
                        aria-describedby="basic-addon1"
                        >
                            <option 
                                class="p-10"
                                *ngFor="let option of prefixData"
                                [value]="option.value">
                            {{ option.displayName }}
                            </option>
                    </select>
                    <div class="text-danger f-12" *ngIf="suffix.errors">{{ suffix.errors["customError"]}}</div>
                </div>
            </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group mb-3">
                      <label class="col-form-label" for="personalFormDateOfBirth">Date of Birth*</label>
                      <input 
                          type="text"
                          formControlName="dateOfBirth"
                          name="dateOfBirth"
                          id="personalFormDateOfBirth"
                          class="form-control"
                          placeholder="mm/dd/yyyy"
                          aria-label="Date of Birth"
                          [ngClass]="{'is-invalid':dateOfBirth.touched && dateOfBirth.invalid}"
                          aria-describedby="basic-addon1"
                          bsDatepicker
                          [bsConfig]="datePickerConfig"
                          >
                          <div class="invalid-feedback">
                               Incorrect ages. User ages should be in between 18-100 years.
                          </div>
                          <div class="text-danger f-12" *ngIf="dateOfBirth.errors">{{ dateOfBirth.errors["customError"]}}</div>
                      </div>
                  </div>
            </div>
            <div *ngIf="!commonService.expiredTokenError && errorMessage && !errorCollection" class="error-invalid f-12">
                {{"Error: " + errorMessage}}
            </div>
            <div *ngIf="!commonService.expiredTokenError && apiProcess" class="api-process-loader">
                <div  class="loader-box">
                    <div class="loader-9"></div>
                </div>
            </div>
            
            <div class="text-danger" *ngIf="commonService.expiredTokenError">{{ commonService.expiredTokenError }}</div>
            <div class="form-group mt-3 text-end">
              <button type="submit" class="btn btn-primary btn-lg" [disabled]="apiProcess" (click)="submitPersonalInfoForm()">Next</button>
            </div>
           
            <div class="text-muted  my-3 f-12">Note: To apply for the Affordable Connectivity Program, each section of this form must be completed, initial all agreement statements, and electronically sign the last page.</div>
          </form>
    </div>
