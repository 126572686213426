<div class="login-main register-form">
  <h4 class="font-primary">Security Information</h4>
  <div class="f-12 mb-2">By checking this box and your signature below. You are authorizing Unity Wireless INC, or is representatives to use an automated system to deliver telemarketing calls to the phone number you provided below using an automatic telephone dialing system, and or in artificial or pre-recorded voice or text message. You also agree to receive recurring automated promotional and personalized marketing text messages and emails (e.g. complete your application reminders) from Unity Wireless INC at the cell number used when signing up. Consent is not a condition of any purchase. Message frequency varies.</div>
  <form class="theme-form" (ngSubmit)="onSubmit()" [formGroup]="securityForm">
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="form-group mb-3">
            <label class="col-form-label">Contact*</label>
            <input 
                type="text"
                formControlName="contactNumber"
                class="form-control"
                [placeholder]="'Eg. ' + contactNumberPlaceHolder"
                aria-label="Contact Number"
                aria-describedby="basic-addon1"
                maxlength="10"
                (focusin)="hideFormattedContactNumber()"
                (focusout)="formatPhoneNumberData()"
                [ngClass]="{'is-invalid':contactNumber.touched && contactNumber.invalid}">
                <div class="formatted-data" (click)="hideFormattedContactNumber()" *ngIf="showFormatContactNumber">{{formattedPhoneNumber}}</div>
                <div class="invalid-feedback">
                  Contact number is required. It should be 10 digit, start with [2-9].
                </div>
                <div class="text-danger f-12" *ngIf="contactNumber.errors">{{ contactNumber.errors["customError"]}}</div>
      </div>
    </div>
      <div class="col-12 col-sm-6">
        <div class="form-group mb-3">
          <label class="col-form-label">SSN (Last 4 digit)*</label>
          <input 
              type="text"
              formControlName="ssnNumber"
              class="form-control"
              placeholder="****"
              aria-label="SSN Name"
              maxlength="4"
              [ngClass]="{'is-invalid':ssnNumber.touched && ssnNumber.invalid}"
              aria-describedby="basic-addon1">
              <div class="invalid-feedback">
                  Required field. Must have a number with 4 digit.
              </div>
              <div class="text-danger f-12" *ngIf="ssnNumber.errors">{{ ssnNumber.errors["customError"]}}</div>
      </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <div class="form-group mb-3">
        <label class="col-form-label">Driver License # / Government ID</label>
        <input 
            type="text"
            formControlName="driverLicense"
            class="form-control"
            placeholder="XXXXXX"
            [ngClass]="{'is-invalid':driverLicense.touched && driverLicense.invalid}"
            aria-label="Driver License"
            maxlength="100"
            aria-describedby="basic-addon1">
          <div class="invalid-feedback">
            Required field.
        </div>
        <div class="text-danger f-12" *ngIf="driverLicense.errors">{{ driverLicense.errors["customError"]}}</div>
    </div>
    </div>
</div>
<div class="col-xs-12 col-sm-6">
  <div class="form-group mt-1">
      <div class="form-check checkbox checkbox-primary">
        <input 
            formControlName="authorize"
            aria-label="You are authorizing Unity License Wireless Inc."
            type="checkbox"
            class="form-check-input"
            [ngClass]="{'is-invalid':authorize.touched && authorize.invalid}"
            id="authorize"
          />
          <label 
            class="checkbox-primary-1 f-12"
            for="authorize"
            [ngClass]="{'form-check-label': authorize.value}"
            >
            Check to authorize
          </label>
      </div>
      <div class="text-danger f-12" *ngIf="authorize.errors">{{ authorize.errors["customError"]}}</div>
</div>
</div>
<div class="text-danger f-12" *ngIf="!commonService.expiredTokenError && errorMessage">{{errorMessage}}</div>
<div class="text-danger f-12" *ngIf="commonService.expiredTokenError">
  {{ commonService.expiredTokenError }}
</div>
<div *ngIf="!commonService.expiredTokenError && apiProcess" class="api-process-loader">
  <div  class="loader-box">
      <div class="loader-9"></div>
  </div>  
</div>
<div class="form-group mt-3 text-end">
  <button type="button" class="btn btn-light btn-lg mx-3" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="slidePrevious()">Prev</button>
  <button type="submit" [disabled]="!commonService.expiredTokenError && apiProcess" class="btn btn-primary btn-lg">Next</button>
</div>
  </form>
</div>