import {Injectable} from "@angular/core";
import { HttpClient, 
  // HttpHeaders,
  HttpResponse } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { LocalStorage } from 'ngx-webstorage';
import { IBeneficiaryReq } from "../model/beneficiary.model";
import { environment } from "src/environments/environment"; 
import { Observable, ReplaySubject, map } from 'rxjs';
// import { RefreshServices } from "./refresh.services";
// import { Router } from "@angular/router";
import { CommonService } from "./common.service";

@Injectable({
    providedIn: 'root'
})

export class BeneficiaryService {
  protected baseApi: string;
  protected token: string; 

  private myBeneficiarySubject = new ReplaySubject<HttpResponse<any>| null>(null);
  myData$ = this.myBeneficiarySubject.asObservable();

  @LocalStorage()
  protected uuid;
  
  @LocalStorage()
  protected xExpire;

    constructor(
        private http:HttpClient,
        // private refreshService: RefreshServices,
        private cookieService: CookieService,
        // private router: Router,
        private commonService: CommonService
        ) {
          this.baseApi = environment.BASE_API;
          this.token = this.cookieService.get('x-token');
        }

  put(req: IBeneficiaryReq):Observable<any> {
    // this.commonService.checkExpiredToken();
    // const headers = new HttpHeaders({
    //   'authorization': `Bearer ${this.token}`
    // })
   
   return this.http.put(`${this.baseApi}/${environment.AUTH.ADD_BENEFICIARY}/${this.uuid}`, 
    req, {
      // headers,
      observe: 'response'})
    .pipe(
      map(
        (response:HttpResponse<any>) => {
        if(response.status === 200 && response.body.Status){
          this.myBeneficiarySubject.next(response.body)
        }
        return response
      })
  )}
}

