 <ng-container *ngIf="!toggleForApprovalCard; else toggleApprovalCard"> 
  <!-- <ng-container *ngIf="false; else toggleApprovalCard"> -->
<div *ngIf="programService.loadProgram else loaderContent">
    <div class="login-main program-form">
        <ng-container>
          <h4 class="font-primary">Qualify for Affordable Connectivity Program</h4>
          <p class="f-12">Please select a program below to show you, your dependent or someone in your household qualifies for the Affordable Connectivity Program. You can qualify for the Affordable Connectivity Program by showing that you already participate in one of the government assistance programs below. If you do not currently participate in one of the programs listed, you may be able to qualify based on income. You may be required to show proof of your participation in the program you choose or show proof of income.</p>
        </ng-container>
          <div class="row">
                <ng-container *ngFor="let program of programService.programs; index as i">
                  <div class="col-12 col-sm-6  d-flex align-items-stretch">
                      <ng-container *ngIf="programService.hasModal(program.shortName) then hasContentModal else hasNoContentModal"></ng-container>
                      <ng-template #hasContentModal>
                        <ng-container *ngIf="program.shortName === 'NSL'">
                          <app-school-lunch [index]="i" [program]="program"></app-school-lunch>
                        </ng-container>
                        <ng-container *ngIf="program.shortName === 'FPH'">
                          <app-housing [index]="i" [program]="program"></app-housing>
                        </ng-container>
                  </ng-template>

          <ng-template #hasNoContentModal>
          <div class="card cursor-pointer min-height140"
           [ngClass]="{ 'b-success': programService.checkSelected(program.shortName)
          }"
          (click)="programService.renderAdditionalForm(program)">
          <div class="program-image-wrapper"> 
            <ng-container *ngIf="program.imagePath; else placeHolderImg">
              <img [src]="program.imagePath" class="img-fluid" alt="images" />
            </ng-container>
            <ng-template #placeHolderImg>
              <img src="./assets/images/banner/1.jpg" class="img-fluid" alt="images" />
            </ng-template>
          </div>
          <div class="media p-20">
          <!-- <div 
            class="ribbon ribbon-primary"
            [ngClass]="{
            'd-none': !program.shortName
            }"
            >{{program.shortName}}</div> -->
          <div 
            class="p-absolute check-card js-check-card">
            <app-feather-icons [icon]="'check-circle'"></app-feather-icons>
          </div>
          <div class="media-body pr-5 text-center mt-3">
          <h6 
            class="mt-4 text-center pt-3 f-12"
            [ngClass]="{
            'text-muted': !programService.checkSelected(program.shortName)
            }"
            >
          {{program.name}}
          </h6>
          </div>
          </div>
        </div>
          </ng-template>
      </div>
      </ng-container>
      </div>

    <ng-container *ngFor="let program of programService.programs; index as i">
        <ng-container *ngIf="program.shortName === 'IB'">
          <hr />
          <h4 class="font-primary m-t-30 m-b-20">Qualify based on Income</h4>
          <div class="row">
            <app-income [index]="i" [program]="program"></app-income>
          </div>
          
        </ng-container>
    </ng-container>
    

        
        <ng-container *ngIf="!commonService.expiredTokenError && errorMessage">
          <div class="text-danger f-12" *ngFor="let err of errorMessage">{{err.label}}</div>
       </ng-container>
       <ng-container *ngIf="!commonService.expiredTokenError && errorMessageEndpoint">
        <div class="text-danger f-12 p-b-20">{{ errorMessageEndpoint }} </div>
      </ng-container>
      <div class="text-danger f-12" *ngIf="commonService.expiredTokenError">{{ commonService.expiredTokenError }}</div>
          <div class="form-group m-b-0 text-end">
            <button class="btn btn-light btn-lg mx-3" [disabled]="commonService.lockPreviousStep" (click)="stepPrevious()">Prev</button>
            <button class="btn btn-primary btn-lg" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="saveProgramDataAndValidate()">Next</button>
          </div>
    </div>
    <div *ngIf="!commonService.expiredTokenError && apiProcess" class="api-process-loader">
      <div  class="loader-box">
          <div class="loader-9"></div>
      </div>
  </div>
</div>
<ng-template #loaderContent>
  <div class="p-2">
    <div class="login-main program-form">
      <div class="loader-box">
          <div class="loader-9"></div>
      </div>
    </div>
  </div>
</ng-template>
</ng-container>
<ng-template #toggleApprovalCard>
    <div class="login-main program-form">
      <div class="card-body text-center">
        <div class="success-icon text-center mb-3">
          <app-feather-icons [icon]="'check-circle'"></app-feather-icons>  
        </div>
        <h1 class="f-w-100 txt-success">Congratulation! <br/>
          <small>you're pre approved</small>
        </h1>
        <div class="p-2 m-b-10">
         
        <a *ngIf="!commonService.showCallbackUrlButton" class="text-center btn btn-primary btn-lg d-block" [href]="resolutionUrl">
          Click here to finish your application with National Verifier
       </a></div>
       <div class="text-center btn btn-primary d-block" *ngIf="commonService.showCallbackUrlButton">
        <button class="btn btn-primary btn-lg" (click)="continueAction()">Continue...</button>
      </div>
        <div class="text-center m-b-10">
          <a class="btn btn-light" [href]="'tel:'+helpline">
            <app-feather-icons [icon]="'phone-call'"></app-feather-icons> 
            <span class="line-height-24">Give us a call {{helpline}}</span>
          </a>
        </div>
        <div class="text-center m-b-10">
          <button class="btn btn-light btn-lg">
            Chat with an agent 
          </button>
        </div>
      <!-- <div class="text-center m-b-10">
          if you prefer you can directly go to the National Verifier and provide the necessary documents.
        </div> -->
        
        <div class="text-center m-t-20  m-b-20">
          It might take upto 48 hours until the National Verifier validates your eligibility.
        </div>
        <div class="b-dark text-center m-t-10 p-2">
          <app-feather-icons class="beware-icon" [icon]="'alert-triangle'"></app-feather-icons> Beware <br/>
          Make sure you don't have an open application or an active account with other companies. If you do, your tablet order will be cancelled.
        </div>
      </div>
  </div>
  <div *ngIf="!commonService.expiredTokenError && apiProcess" class="api-process-loader">
    <div class="loader-box">
        <div class="loader-9"></div>
    </div>
  </div>
</ng-template>
