  <div class="login-main register-form">
  <h4 class="font-primary">Who received government assistance? <small class="f-18">(SNAP, Medicaid, etc)</small></h4>
  <div class="theme-form">
  <div class="form-group p-l-10">
    <div class="radio radio-primary p-t-5">
      <input class="form-check-input" 
      name="isApplyingForSelf"
      (change)="renderAdditionalForm()"
      value="Yes"
      [(ngModel)]="beneficiaryForm.isApplyingForSelf.value"
      id="isApplyingForSelf-1"
      type="radio">
      <label class="form-check-label" for="isApplyingForSelf-1">
        Myself
      </label>
    </div>
    <div class="radio radio-primary p-t-5">
      <input class="form-check-input" 
      name="isApplyingForSelf"
      (change)="renderAdditionalForm()"
      value="No"
      [(ngModel)]="beneficiaryForm.isApplyingForSelf.value"
      id="isApplyingForSelf-2"
      type="radio">
      <label class="form-check-label" for="isApplyingForSelf-2">
        Somebody else in household
      </label>
    </div>
  </div>
  <ng-container *ngIf="beneficiaryForm.isApplyingForSelf.value === 'No'">
    <div class="animate__fadeInUp">
    <h5 class="f-16 m-b-15 m-t-10">Qualifying Beneficiary Details</h5>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="form-group mb-3">
            <label class="col-form-label">First Name*</label>
            <input 
              type="text"
              name="firstName"
              class="form-control"
              placeholder="Eg. John"
              aria-label="First Name"
              (blur)="checkValidation($event)"
              [(ngModel)]="beneficiaryForm.firstName.value"
              [ngClass]="{'is-invalid':beneficiaryForm.firstName.isValid}"
              aria-describedby="basic-addon1">
              <div class="invalid-feedback">
              Required field.
            </div>
        </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="form-group mb-3">
          <label class="col-form-label">Middle Name</label>
          <input 
            type="text"
            name="middleName"
            class="form-control"
            [(ngModel)]="beneficiaryForm.middleName.value"
            placeholder="Eg. Middle name"
            aria-label="Middle Name"
            aria-describedby="basic-addon1">
        </div>
      </div>
    
        <div class="col-12 col-sm-6">
          <div class="form-group mb-3">
            <label class="col-form-label">Last Name*</label>
            <input 
              type="text"
              name="lastName"
              (blur)="checkValidation($event)"
              [ngClass]="{'is-invalid':beneficiaryForm.lastName.isValid}"
              [(ngModel)]="beneficiaryForm.lastName.value"
              class="form-control"
              placeholder="Eg. Smith"
              aria-label="Last Name"
              aria-describedby="basic-addon1"
              >
              <div class="invalid-feedback">
                Required field.
              </div>
          </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="form-group mb-3">
            <label class="col-form-label">Suffix</label>
            <select
                name="suffix"
                class="form-control"
                placeholder="Eg. John2"
                aria-label="Suffix"
                aria-describedby="basic-addon1"
                [(ngModel)]="beneficiaryForm.suffix.value"
                (change)="updateSuffix($event)"
                >
                    <option 
                        class="p-10"
                        *ngFor="let option of prefixData"
                        [selected]="beneficiaryForm.suffix.value === option.value"
                        [value]="option.value">
                    {{ option.displayName }}
                    </option>
            </select>
        </div>
      </div>
        <div class="col-12 col-sm-6">
          <div class="form-group mb-3">
              <label class="col-form-label">Date of Birth*</label>
              <input 
                type="text"
                name="dateOfBirth"
                class="form-control"
                (change)="checkValidation($event)"
                [ngClass]="{'is-invalid':beneficiaryForm.dateOfBirth.isValid}"
                [(ngModel)]="beneficiaryForm.dateOfBirth.value"
                placeholder="mm/dd/yyyy"
                aria-label="Date of Birth"
                aria-describedby="basic-addon2"
                bsDatepicker
                [bsConfig]="datePickerConfig"
                >
                <div class="invalid-feedback">
                   Incorrect ages. User ages should be in between 18-100 years.
                </div>
          </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="form-group mb-3">
            <label class="col-form-label">Contact*</label>
            <input 
                type="text"
                name="contactNumber"
                class="form-control"
                #contactNumber
                [ngClass]="{'is-invalid':beneficiaryForm.contactNumber.isValid}"
                (change)="checkValidation($event)"
                (focusin)="hideFormattedContactNumber()"
                (focusout)="formatPhoneNumberData()"
                maxlength="10"
                [placeholder]="'Eg. '+ contactNumberPlaceHolder"
                aria-label="Contact Number"
                [(ngModel)]="beneficiaryForm.contactNumber.value"
                aria-describedby="basic-addon1"
              >
              <div class="formatted-data" (click)="hideFormattedContactNumber()" *ngIf="showFormatContactNumber">{{formattedPhoneNumber}}</div>
              <div class="invalid-feedback">
                Contact number is required. It should be 10 digit, start with [2-9].
              </div>
          </div>
      </div>

      <div class="col-12 col-sm-6">
        <div class="form-group mb-3">
          <label class="col-form-label">SSN (Last 4 digit)*</label>
            <input 
                type="text"
                name="ssn"
                class="form-control"
                [ngClass]="{'is-invalid': beneficiaryForm.ssn.isValid}"
                (change)="checkValidation($event)"
                placeholder="Eg. 1222"
                aria-label="SSN number last 4 digit"
                [(ngModel)]="beneficiaryForm.ssn.value"
                aria-describedby="basic-addon1"
                maxlength="4"
              >
              <div class="invalid-feedback">
                Required field. Must have a number with 4 digit.
              </div>
          </div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="form-group mb-3">
          <label class="col-form-label">Driver License # / Government ID</label>
            <input 
                type="text"
                name="driverLicense"
                class="form-control"
                (change)="checkValidation($event)"
                placeholder="Eg. XXXXXX"
                aria-label="Driver License"
                [(ngModel)]="beneficiaryForm.driverLicense.value"
                aria-describedby="basic-addon1"
              >
          </div>
      </div>
    </div>
    <div class="form-group p-l-10">
      <div class="checkbox p-0">
        <div class="checkbox p-0">
          <input 
            (change)="checkValidation($event)"
            [(ngModel)]="beneficiaryForm.isBqpConsent.value"
            [ngClass]="{'is-invalid': beneficiaryForm.isBqpConsent.isValid}"
            type="checkbox"
            id="checkAuthorize"
            name="isBqpConsent">
            <label class="text-muted" for="checkAuthorize">
              Check to authorize
            </label>
        </div>
      </div>
      <div *ngIf="beneficiaryForm.isBqpConsent.isValid" class="error-invalid f-12">
        Checkbox is required field.
      </div>
    </div>
  </div>
  </ng-container>

  <h5 class="f-16">Affordable Connectivity Program (ACP) Consent</h5>
  <div class="p-l-20 acp-consent-wrapper">
    <div>
      <div class="form-group">
        <div class="checkbox p-0">
          <input 
            (change)="checkValidation($event)"
            [(ngModel)]="beneficiaryForm.isTransferConsent.value"
            [ngClass]="{'is-invalid': beneficiaryForm.isBqpConsent.isValid}"
            type="checkbox"
            name="isTransferConsent"
            id="isTransferConsent"
            >
            <label class="text-muted f-12" for="isTransferConsent">
              By continuing with your application, you affirm and understand that the Affordable Connectivity Program is an FCC benefit program that reduces your monthly Broadband invoice. The program will be in effect for an indefinite amount of time.  At the conclusion of the program, you will be given 30 days’ notice and may elect to keep your plan at an undiscounted rate. As a participant you may transfer your ACP benefit to another provider.  The Affordable Connectivity Program is limited to one monthly service discount and one device discount per household.
            </label>
        </div>
        <div *ngIf="beneficiaryForm.isTransferConsent.isValid" class="error-invalid f-12">
          Checkbox is required field.
        </div>
    </div> 
    <div class="form-group">
        <div class="checkbox p-0">
          <input 
            (change)="checkValidation($event)"
            [(ngModel)]="beneficiaryForm.isAcpConsent.value"
            [ngClass]="{'is-invalid': beneficiaryForm.isAcpConsent.isValid}"
            type="checkbox"
            name="isAcpConsent"
            id="isAcpConsent"
            >
            <label class="text-muted f-12" for="isAcpConsent">
              
              I understand that I am not allowed multiple ACP program benefits with the same or different providers AND I consent to enroll or transfer into the Unity Wireless Affordable Connectivity Program.
            </label>
        </div>
      <div *ngIf="beneficiaryForm.isAcpConsent.isValid" class="error-invalid f-12">
        Checkbox is required field.
      </div>
    </div>
    </div>
  </div>
  
  <div *ngIf="!commonService.expiredTokenError && errorMessage" class="text-danger">{{errorMessage}}</div>
  <div *ngIf="commonService.expiredTokenError" class="text-danger">{{commonService.expiredTokenError}}</div>
  <div *ngIf="!commonService.expiredTokenError &&  apiProcess" class="api-process-loader">
    <div  class="loader-box">
        <div class="loader-9"></div>
    </div>
  </div>
  <div class="form-group mt-3 text-end">
    <button (click)="slidePrevious()" [disabled]="!commonService.expiredTokenError && apiProcess" class="btn btn-light btn-lg mx-4">Prev</button>
    <button class="btn btn-primary btn-lg" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="onSubmit()">Next</button>
  </div>
</div>
</div>
