import { Component, Input, OnInit } from '@angular/core';
import { ProgramServices } from 'src/app/shared/services/program.services';

@Component({
  selector: 'app-housing',
  templateUrl: './housing.component.html',
  styleUrls: ['./housing.component.scss']
})
export class HousingComponent implements OnInit {

  
  @Input()
  public program;

  @Input()
  public index;

  public FPH: any[];
  
  constructor(
    public programService: ProgramServices
  ){

  }

  ngOnInit():void {
    this.FPH = this.program.subProgram;
  }
  
}
