<div 
  class="card cursor-pointer min-height140"
  [ngClass]="{
  'b-warning': programService.fphInvalidCard && programService.checkSelected(program.shortName), 
  'b-success': !programService.fphInvalidCard && programService.checkSelected(program.shortName)
  }"
  id="{{program.shortName}}">
  <div class="program-image-wrapper" (click)="programService.renderAdditionalForm(program, $event, federalPrograms)">
    <ng-container *ngIf="program.imagePath; else placeHolderImg">
      <img [src]="program.imagePath" class="img-fluid" alt="images" />
    </ng-container>
    <ng-template #placeHolderImg>
      <img src="./assets/images/banner/1.jpg" class="img-fluid" alt="images" />
    </ng-template>
  </div>
  <div class="media p-20">
  <div (click)="programService.renderAdditionalForm(program, $event, federalPrograms)">
  <!-- <div 
     class="ribbon ribbon-primary"
     >{{program.shortName}}</div> -->

    <div class="p-absolute check-card">
      <app-feather-icons [icon]="'check-circle'"></app-feather-icons>
    </div>
    <h6 
          class="m-t-40 text-center pt-3 f-12"
          [ngClass]="{
          'text-muted': !programService.checkSelected(program.shortName)
          }">
      {{program.name}}
    </h6>
    </div>
    <ng-container *ngIf="programService.checkSelected(program.shortName)">
    <div class="p-absolute text-center badge-list">
        <span [id]="'fph'+programService.fphSelected" class="badge badge-light text-muted">{{ programService.fphSelected }}</span>
    </div>
    <div class="pull-right">
      <div class="p-absolute edit-icon" (click)="programService.open(federalPrograms)">
        <i class="fa fa-pencil-square-o font-primary cursor-pointer"></i>
      </div>
    </div>
  </ng-container>
</div>
</div>
<ng-template #federalPrograms let-modal>
  <div class="modal-body">
    <div class="close-modal p-absolute" (click)="modal.close()">
      <i class="icon-close"></i>
    </div>
     <div class="theme-form p-20">
      <h5 class="m-b-3">Please choose the availed program</h5>
      <div class="row p-t-20">

        <ng-container *ngFor="let fdProgram of FPH">
          <div class="col-sm-6 ">
            <div class="card min-h-120 cursor-pointer"
              (click)="programService.fphOnChange(fdProgram.shortName)"
              [ngClass]="{
                'b-light': programService.fphSelected !== fdProgram.shortName,
                'b-success': programService.fphSelected === fdProgram.shortName,
              }"
            >
              <div class="media p-20">
                <div class="form-check radio radio-primary me-3">
                  <input
                    class="form-check-input"
                    [id]="'fph'+fdProgram.id"
                    type="radio"
                    name="radio1"
                    [defaultChecked]="fdProgram.shortName === programService.fphSelected"
                    [value]="fdProgram.shortName"
                  />
                  <label 
                  class="form-check-label" 
                  [for]="'fph'+fdProgram.id"></label>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mega-title-badge">
                    {{fdProgram.name}}
                    <span class="badge badge-primary pull-right digits"
                      >{{fdProgram.shortName +' | ' + fdProgram.nladCode}}</span
                    >
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="font-danger"
          *ngIf="programService.fphInvalidCard">
          No radio selection. Please select the radio option
        </div>
      </div>
     </div>
     <div class="form-group mt-0 text-end">
      <button type="button"  class="btn btn-light" (click)="programService.resetFPHForm()">Reset</button>
      <button type="button"  class="btn btn-primary m-l-10" (click)="programService.saveFPHForm('save and close')">Save and Close</button>
   </div>      
  </div>
</ng-template>

