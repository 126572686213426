  <div class="login-main register-form">
    <h4 class="font-primary">ACP Disclosures, Consents and Certifications</h4>
    <p>The Affordable Connectivity Program (ACP) is a government benefit program operated by the Federal Communications Commission (FCC) that provides discounts on monthly broadband Internet access service and certain connected devices. For more information about the ACP and program eligibility requirements, call us at {{helplineNumber}} or visit <a href="https://unitywireless.com/pages/acp-free-service">
      https://unitywireless.com/pages/acp-free-service</a>.
    </p>
    <p> ACP discounts can be applied to any available Unity Wireless, LLC d/b/a Unity Wireless (Unity Wireless)&rsquo;s service plans at the same terms available to households that are not eligible for ACP supported services. Upload/download speeds will be determined by your particular service plan, and other factors, including your device, network availability from our underlying carrier, your proximity to cellular towers, and environmental factors may affect speeds, as described in our Broadband Transparency Disclosure at <a href="https://unitywireless.com/policies/terms-of-service">https://unitywireless.com/policies/terms-of-service</a>.  A complete listing of our plans, including plans that are fully covered (no co-pay after application of the ACP discount), is available <a href="https://unitywireless.com/">https://unitywireless.com</a>.
    </p>
    <p>
    Unity Wireless&rsquo;s complete Terms and Conditions, including the Acceptable Use Policy, Privacy Policy and ACP Terms and Conditions, are available at <a href="https://unitywireless.com/">https://unitywireless.com</a>.  You agree that your service will be activated upon submission of this application form and completion of the eligibility verification and enrollment process. 
    </p>
    <p>
    ACP monthly service and one-time device discounts are not transferrable to other households or individuals.  ACP benefits may be obtained from any participating provider of your choosing.
    </p>
    <p>
    An eligible household is limited to one monthly service discount and a single one-time device discount. 
    </p>
    <p>
    An eligible household does not have to purchase an ACP discounted connected device in order to enroll in the ACP and receive monthly service discounts.  Devices available for ACP discounts are listed at <a href="https://unitywireless.com">https://unitywireless.com</a>
    </p>
    <p>
    Eligibility for the ACP, including Tribal benefits, is determined by the National Verifier and National Lifeline Accountability Database, administered by the Universal Service Administrative Company (USAC).  
    </p>
    <p>
    If the FCC announces the end of the ACP, we discontinue our ACP service offers, you transfer your ACP benefits to another provider, or we determine your household is no longer eligible, you will be notified.  You may keep your service plan by paying the applicable undiscounted rate plus applicable fees and taxes.</p>
    <p>
    If you select a plan that is not fully covered by applicable discounts and requires monthly post-payments, Unity Wireless may disconnect your ACP-supported service after 90 consecutive days of non-payment. A household may file a complaint against an ACP service provider via the FCC&rsquo;s Consumer Complaint Center.</p>
    <p>
    You authorize Unity Wireless and its contracted partners, including Outwork Tech , to collect, use, share, and retain your personal information, including but not limited to information required for the purpose of establishing eligibility for and enrolling in ACP, or waste/fraud/abuse prevention, and including, but not limited to, full name, full residential address, date of birth, last four digits of social security number, phone number, eligibility criteria and status, the date on which any ACP service discount was initiated and if applicable, terminated, device information, usage status and other compliance requirements, the amount of support being sought for the service, and information necessary to establish identity and verifiable address. This information may be shared with USAC to ensure proper administration of the ACP. Failure to provide consent will result in me being denied ACP connect device and service benefits. 
    </p>
    <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
    <input 
      type="checkbox"
      name="checkbox1"
      class="form-check-input"
      [(ngModel)]="eSignForm.checkbox1.value"
      [ngClass]="{'is-invalid':eSignForm.checkbox1.isInValid}"
      (change)="checkValidation($event)"
      id="checkbox1">
    <label class="form-check-label" for="checkbox1" [ngClass]="{'text-danger':eSignForm.checkbox1.isInValid}"> I hereby certify that I have read, understand and consent to the disclosures listed above regarding the ACP benefits and consent to enroll in the ACP with Unity Wireless.
    </label>
    </div>
    <div id="showhideDiv1">
    <ngb-accordion [closeOthers]="true" #acc="ngbAccordion" activeIds="ngb-panel-0">
      <ngb-panel id="ngb-panel-0">
        <ng-template ngbPanelTitle> 
          <img class="p-absolute accordian-image-inline" src="./assets/images/other-images/swipe-right.png" alt="inline-image" />
          <span 
            class="p-l-25"
            [ngClass]="{'text-danger': eSignForm.checkbox2.isInValid}"
          >ACP Benefit Transfer Consent: </span>
        </ng-template>
        <ng-template ngbPanelContent>
          <p>
            A subscriber already enrolled in the ACP with another provider must consent to the transfer of their ACP benefit to Unity Wireless.  
            </p>
            <p>
            The effect of an ACP benefit transfer is that your ACP benefit will be applied to Unity Wireless&rsquo;s ACP service and will no longer be applied to service retained from your former ACP service provider. You may be subject to your former ACP provider&rsquo;s undiscounted rates as a result of the transfer if you elect to maintain service from that provider.
            </p>
            <p>
            You are limited to one ACP benefit transfer transaction per service month, with limited exceptions for situations where a subscriber seeks to reverse an unauthorized benefit transfer or is unable to receive service from a specific provider.  
            </p>
            <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
              <input 
                class="form-check-input"
                type="checkbox"
                name="checkbox2"
                [(ngModel)]="eSignForm.checkbox2.value"
                [ngClass]="{'is-invalid':eSignForm.checkbox2.isInValid}"
                value="checkbox2"
                (change)="checkValidation($event)"
                id="checkbox2">
              <label class="form-check-label" [ngClass]="{'text-danger': eSignForm.checkbox2.isInValid}" for="checkbox2">
                I hereby certify that I have read, understand and consent to the disclosures listed above regarding the ACP benefit transfers and consent to and authorize Unity Wireless to transfer my current ACP benefit to Unity Wireless, if I am found to already be receiving an ACP discount benefit from another ACP provider.
            </label>
            </div>
            </ng-template>
      </ngb-panel>
      <ngb-panel id="ngb-panel-1">
              <ng-template ngbPanelTitle> 
                <img class="p-absolute accordian-image-inline" src="./assets/images/other-images/swipe-right.png" alt="inline-image" />
                <span 
                  class="p-l-25"
                  [ngClass]="{'text-danger': eSignForm.checkbox3.isInValid}"
                >ACP Discounted Connected Device Purchase Election: </span>
              </ng-template>

              <ng-template ngbPanelContent>
            <p>
            I agree that if I receive a connected device discount from Unity Wireless as part of the ACP, I will pay a minimum of $10.01 and a maximum of $49.99 for the connected device and that, to the best of my knowledge, no one in my household has received a connected device discount from any service provider through the ACP or the Emergency Broadband Benefit program.
            </p>
            <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
              <input 
                class="form-check-input"
                type="checkbox" 
                name="checkbox3"
                [(ngModel)]="eSignForm.checkbox3.value"
                [ngClass]="{'text-danger':eSignForm.checkbox3.isInValid}"
                value="checkbox3"
                (change)="checkValidation($event)"
                id="checkbox3">
              <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox3.isInValid}" for="checkbox3">
            I hereby certify that I have read, understand and consent to the disclosures listed above regarding the ACP connected devices and I hereby confirm that want to purchase and receive an ACP discounted connected device from Unity Wireless.
            </label>
            </div>
              </ng-template>
      </ngb-panel>
      <ngb-panel id="ngb-panel-2">
            <ng-template ngbPanelTitle> 
              <img class="p-absolute accordian-image-inline" src="./assets/images/other-images/swipe-right.png" alt="inline-image" />
              <span 
                class="p-l-25"
                [ngClass]="{
                  'text-danger': eSignForm.checkbox4.isInValid || eSignForm.checkbox4a.isInValid
                  || eSignForm.checkbox4b.isInValid || eSignForm.checkbox4c.isInValid 
                  || eSignForm.checkbox4d.isInValid || eSignForm.checkbox4e.isInValid
                  || eSignForm.checkbox4f.isInValid
                  || eSignForm.checkbox4g.isInValid || eSignForm.checkbox4h.isInValid
                  || eSignForm.checkbox4i.isInValid || eSignForm.checkbox4j.isInValid
                  }"
                >Certification of Truth and Correctness Under Penalty of Perjury: </span>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input 
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="eSignForm.checkbox4.value"
                  [ngClass]="{'is-invalid':eSignForm.checkbox4.isInValid}"
                  (change)="checkValidation($event)"
                  name="checkbox4"
                  id="checkbox4">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox4.isInValid}" for="checkbox4">
              By checking this box, I hereby certify, under penalty of perjury, that the information included in the foregoing applications and certifications is true and correct to the best of my knowledge.
              </label>

              <!-- sub checks start from here -->
              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input 
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="eSignForm.checkbox4a.value"
                  [ngClass]="{'is-invalid':eSignForm.checkbox4a.isInValid}"
                  (change)="checkValidation($event)"
                  name="checkbox4a"
                  id="checkbox4a">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox4a.isInValid}" for="checkbox4a">
                  I (or my dependent or other person in my household) currently get benefits from the
                  government program(s) listed on this form or my annual household income is 200% or less
                  than the Federal Poverty Guidelines (the amount listed in the Federal Poverty Guidelines
                  table on this form).
              </label>
              </div>

              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input 
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="eSignForm.checkbox4b.value"
                  [ngClass]="{'is-invalid':eSignForm.checkbox4b.isInValid}"
                  (change)="checkValidation($event)"
                  name="checkbox4b"
                  id="checkbox4b">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox4b.isInValid}" for="checkbox4b">
                  I agree that if I move I will give my service provider my new address within 30 days
              </label>
              </div>
              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input 
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="eSignForm.checkbox4c.value"
                  [ngClass]="{'is-invalid':eSignForm.checkbox4c.isInValid}"
                  (change)="checkValidation($event)"
                  name="checkbox4c"
                  id="checkbox4c">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox4c.isInValid}" for="checkbox4c">
                  I understand that I have to tell my service provider within 30 days if I do not qualify for the
                  ACP anymore, including:
                  <ol>
                    <li>I, or the person in my household that qualifies, do not qualify through a
                      government program or income anymore.</li>
                    <li>Either I or someone in my household gets more than one ACP benefit.</li>
                  </ol>
              </label>
              </div>
              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input 
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="eSignForm.checkbox4d.value"
                  [ngClass]="{'is-invalid':eSignForm.checkbox4d.isInValid}"
                  (change)="checkValidation($event)"
                  name="checkbox4d"
                  id="checkbox4d">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox4d.isInValid}" for="checkbox4d">
                  I know that my household can only get one ACP benefit and, to the best of my knowledge,
                  my household is not getting more than one ACP benefit. I understand that I can only receive one
                  connected device (desktop, laptop, or tablet) through the ACP, even if I switch ACP companies.
              </label>
              </div>
              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input 
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="eSignForm.checkbox4e.value"
                  [ngClass]="{'is-invalid':eSignForm.checkbox4e.isInValid}"
                  (change)="checkValidation($event)"
                  name="checkbox4e"
                  id="checkbox4e">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox4e.isInValid}" for="checkbox4e">
                  I agree that all of the information I provide on this form may be collected, used, shared, and
                  retained for the purposes of applying for and/or receiving the ACP benefit. I understand that if this
                  information is not provided to the Program Administrator, I will not be able to get ACP benefits.
                  If the laws of my state or Tribal government require it, I agree that the state or Tribal government
                  may share information about my benefits for a qualifying program with the ACP Administrator.
                  The information shared by the state or Tribal government will be used only to help find out if I can
                  get an ACP benefit.
              </label>
              </div>
              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input 
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="eSignForm.checkbox4f.value"
                  [ngClass]="{'is-invalid':eSignForm.checkbox4f.isInValid}"
                  (change)="checkValidation($event)"
                  name="checkbox4f"
                  id="checkbox4f">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox4f.isInValid}" for="checkbox4f">
                  For my household, I affirm and understand that the ACP is a federal government subsidy
                  that reduces my broadband internet access service bill and at the conclusion of the program, my
                  household will be subject to the company’s undiscounted general rates, terms, and conditions if
                  my household continues to subscribe to the service
              </label>
              </div>
              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input 
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="eSignForm.checkbox4g.value"
                  [ngClass]="{'is-invalid':eSignForm.checkbox4g.isInValid}"
                  (change)="checkValidation($event)"
                  name="checkbox4g"
                  id="checkbox4g">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox4g.isInValid}" for="checkbox4g">
                  All the answers and agreements that I provided on this form are true and correct to the best
                  of my knowledge.
              </label>
              </div>
              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input 
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="eSignForm.checkbox4h.value"
                  [ngClass]="{'is-invalid':eSignForm.checkbox4h.isInValid}"
                  (change)="checkValidation($event)"
                  name="checkbox4h"
                  id="checkbox4h">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox4h.isInValid}" for="checkbox4h">
                  I know that willingly giving false or fraudulent information to get ACP benefits is punishable
                  by law and can result in fines, jail time, de-enrollment, or being barred from the program.
              </label>
              </div>
              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input 
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="eSignForm.checkbox4i.value"
                  [ngClass]="{'is-invalid':eSignForm.checkbox4i.isInValid}"
                  (change)="checkValidation($event)"
                  name="checkbox4i"
                  id="checkbox4i">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox4i.isInValid}" for="checkbox4i">
                  The ACP Administrator or my service provider may have to check whether I still qualify at any
                  time. If I need to recertify my ACP benefit, I understand that I have to respond by the deadline or I will
                  be removed from the Affordable Connectivity Program and my ACP benefit will stop.
              </label>
              <p><i>The certification below applies to all consumers and is required to process your application</i></p>
              </div>
              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input 
                  class="form-check-input"
                  type="checkbox"
                  [(ngModel)]="eSignForm.checkbox4j.value"
                  [ngClass]="{'is-invalid':eSignForm.checkbox4j.isInValid}"
                  (change)="checkValidation($event)"
                  name="checkbox4j"
                  id="checkbox4j">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox4j.isInValid}" for="checkbox4j">
                  I was truthful about whether or not I am a resident of Tribal lands, as defined by the FCC <span class="f-12">( Tribal lands include any federally  recognized Indian tribe&rsquo;s reservation,  Pueblo, or colony, including former reservations in Oklahoma; Alaska  Native regions established pursuant to the Alaska Native Claims Settlement Act (85 Stat. 688) ; Indian allotments; Hawaiian Home Lands— areas held in trust for Native Hawaiians by the state of Hawaii,  pursuant to the Hawaiian Homes Commission Act, 1920 July 9, 1921, 42 Stat. 108,
                  et. seq., as amended; and any land designated as such by the FCC pursuant to the designation process in the FCC&rsquo;s Lifeline rules.
                  A map of qualifying Tribal lands is available) on USAC&rsquo;s website:
                  <a class="f-12" href="https://www.affordableconnectivity.gov/wpcontent/uploads/acp/ documents/fcc_tribal_lands_map.pdf" target="_blank">https://www.affordableconnectivity.gov/wpcontent/uploads/acp/ documents/fcc_tribal_lands_map.pdf</a>)</span> 
              </label>
              </div>
            
              <!-- sub checks end  here -->
              </div>
            </ng-template>
      </ngb-panel>
      <ngb-panel id="ngb-panel-3">
            <ng-template ngbPanelTitle> 
              <img class="p-absolute accordian-image-inline" src="./assets/images/other-images/swipe-right.png" alt="inline-image" />
              <span 
                class="p-l-25"
                [ngClass]="{'text-danger': eSignForm.checkbox5.isInValid || eSignForm.checkbox6.isInValid}"
              >Consent to Receive Communications: </span>
            </ng-template>

            <ng-template ngbPanelContent>

              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input 
                  [(ngModel)]="eSignForm.checkbox5.value"
                  [ngClass]="{'is-invalid':eSignForm.checkbox5.isInValid}"
                  class="form-check-input"
                  type="checkbox" 
                  (change)="checkValidation($event)"
                  name="checkbox5"
                  id="checkbox5">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox5.isInValid}" for="checkbox5">
              I authorize and give express consent to being contacted via email, telephone, or text messaging, including calls or messages using an automated telephone dialing system, manually, or with pre-recorded/artificial voice messages.  We may contact you for service-related, informational or marketing purposes via these methods.  You also provide express consent to contact you, regardless of whether your number is listed on the Do-Not-Call Registry or a state equivalent registry.  Consent to such contacts is not a condition of service and may be revoked at any time.  Consent for emails, calls and texts is optional and can be revoked at any time by dialing 611 from my Unity Wireless provided wireless number or by calling {{helplineNumber}} and revoking consent. However, I understand that opting out will not affect Unity Wireless&rsquo;s ability to contact me with free notices and messages regarding ACP service and/or any other service or product via the methods listed herein.  For more information see our Terms and Conditions and Privacy Policy at <a href="https://unitywireless.com/">https://unitywireless.com</a>.
              </label>
              </div>
              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
          
                <input 
                [(ngModel)]="eSignForm.checkbox6.value"
                [ngClass]="{'is-invalid':eSignForm.checkbox6.isInValid}"
                class="form-check-input"
                type="checkbox" 
                name="checkbox6"
                (change)="checkValidation($event)"
                id="checkbox6">
                 
             <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox6.isInValid}" for="checkbox6">
              I authorize and give express consent for Unity Wireless to use and share my Customer Proprietary Network Information (CPNI) for marketing purposes.  You have a right, and Unity Wireless has a duty, under federal law, to protect the confidentiality of CPNI.  CPNI is information made available to us solely by virtue of our relationship with you that relates to the type, quantity, destination, technical configuration, geolocation data, and amount of use of the telecommunications services you purchase from us, as well as information related to the billing for those services.  You will continue to receive Unity Wireless communications services if you choose to not consent.  Customer Proprietary Network Information (CPNI) is described in our Privacy Policy at <a href="https://unitywireless.com/policies/privacy-policy">https://unitywireless.com/policies/privacy-policy</a>. You may withdraw your consent at any time by reaching us as described in our Privacy Policy.
              </label>
              </div>

            </ng-template>
      </ngb-panel>
      <ngb-panel id="ngb-panel-4">
            <ng-template ngbPanelTitle> 
              <img class="p-absolute accordian-image-inline" src="./assets/images/other-images/swipe-right.png" alt="inline-image" />
              <span 
                class="p-l-25"
                [ngClass]="{'text-danger':eSignForm.checkbox7.isInValid}"
              >E-sign Certification: </span>
            </ng-template>

            <ng-template ngbPanelContent>
              <div class="checkbox mb-3 p-l-20 p-t-0 esign-check">
                <input
                 class="form-check-input" 
                 [(ngModel)]="eSignForm.checkbox7.value"
                 [ngClass]="{'is-invalid':eSignForm.checkbox7.isInValid}"
                  type="checkbox"
                  name="checkbox7"
                  (change)="checkValidation($event)"
                  id="checkbox7">
                <label class="form-check-label" [ngClass]="{'text-danger':eSignForm.checkbox7.isInValid}" for="checkbox7">
              I consent to use of this electronic form.  I understand I have the right to enroll in the service using non-electronic methods. I further understand that I have the right to withdraw this consent at any time prior to the activation of my service. Unity Wireless has advised me and I understand that I may request a paper copy of my contractual terms and associated fees or withdraw this consent by calling {{helplineNumber}}. 
              </label>
              </div>
              </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <div class="row mt-4">
    <div class="col-md-2 mb-3">
    <b>Applicant&rsquo;s Signature:</b>
    </div>
    <div class="col-md-5 mb-3">
    <input 
      type="text"
      class="form-control"
      name="signature"
      style="font-family: cursive;"
      disabled
      [value]="personFullName"
      id="signature" />
   
    </div>
    <div class="col-md-1 mb-3">
    <b>Date:</b>
    </div>
    <div class="col-md-4 mb-3">
    <input 
    type="text"
    class="form-control"
    name="signatureDate"
    readonly
    [ngModel]="dateNow"
    [bsConfig]="programService.datePickerConfig"
    name="date"
    bsDatepicker
    disabled
    id="date">
     
    </div>
    </div>
    <p class="text-danger f-12" *ngIf="errorMessage">{{errorMessage}}</p>
    <div class="text-center mb-3">
    <button type="button" id="submitBtn" (click)="clickNext()" class="btn btn-primary btn-lg col-6">Submit</button>
    </div>
    </div>
  </div>
  <div *ngIf="apiProcess" class="api-process-loader">
    <div  class="loader-box">
        <div class="loader-9"></div>
    </div>
</div>