import {  Component } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/shared/services/common.service';
import { PaymentServices } from 'src/app/shared/services/payment.services';
import { PlanServices } from 'src/app/shared/services/plan_device.services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-device-cart',
  templateUrl: './device-cart.component.html',
  styleUrls: ['./device-cart.component.scss']
})
export class DeviceCartComponent {

  public inputValue = 1;
  public subInputValue = 1;

  constructor(
    public deviceService: PlanServices,
    private paymentService: PaymentServices,
    private cookieService: CookieService,
    private commonService: CommonService,
    private router: Router
    ){
      this.commonService.setStepInfo('registration/cart');
    }

  increment(device){
    const {maxSelection} = device;
    if(maxSelection > this.inputValue){
      this.inputValue = this.inputValue + 1;
    }
    this.subInputValue = this.inputValue;
    this.setPriceOnService(this.inputValue);
  }

  decrement(device){
    const {minSelection} = device;
    if(minSelection < this.inputValue){
      this.inputValue = this.inputValue - 1;
    }
    this.subInputValue = this.inputValue;
    this.setPriceOnService(this.inputValue);
  }

  getTotalValue(){
    const totalValue = this.deviceService.getTotalValue;
    return totalValue;
  }

  setPriceOnService(ipValue){
    this.deviceService.dataPlanDevice.totalPrice.qty = ipValue;
    this.getTotalValue();
  }

  disableIncButton(maxSelection):boolean{
    if(!maxSelection || maxSelection === 1){
      return true
    }
    else if(maxSelection === this.inputValue){
      return true;
    }
    return false;
  }

  disableDesButton(minSelection){
    if(!minSelection || minSelection === this.inputValue){
      return true
    }
    return false;
  }

  previous(){
    this.router.navigate(['registration/device'])
  }

  slideNext(){

    const userID = +this.cookieService.get('x-userId');
    this.paymentService.getPaymentReq({
      userID,
      companyId: environment.COMPANY_ID
    }).subscribe(
        (response) => {
          this.paymentService.paymentData.getCallerror = "";
          if(response.status === 200 && response.body.Status){
            this.paymentService.paymentData.getResData = response.body["data"];
            if(response.body["data"].totalPrice !== 0){
              this.router.navigate(['registration/checkout']);
            }
            else {
              this.router.navigate(['registration/esign']);
            }
          }
          else {
            this.paymentService.paymentData.getCallerror = response.body.message;
            console.warn("get payment endpoint fails")
          }
        },
        (error) => {
          this.paymentService.paymentData.getCallerror = error.message;
          console.warn("get payment endpoint fails")
        }
    );
  }
}
