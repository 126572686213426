export const environment = {
  production: false,
  G_CAPTCHA_SITE_KEY: "6LfAuBgpAAAAAJNyzQNZS1qgS69LVWD37CcmH3D",
  G_CAPTCHA_SECRET_KEY: "6LfAuBgpAAAAAFIf5zPZGy_yI54hAooCunpIrRre",
  COMPANY_ID: "UNITYCRM0021",
  BASE_API: "https://enroll-api.aqsatel.com/api",
  USER_ID: 3,
  TOKEN_EXPIRATION_MINUTES: 45,
  PREFIX_DATA: [
    {displayName: 'I', value: 'i'},
    {displayName: 'II', value: 'ii'},
    {displayName: 'III', value: 'iii'},
    {displayName: 'IV', value: 'iv'},
    {displayName: 'V', value: 'v'},
    {displayName: 'Jr', value: 'jr'},
    {displayName: 'Sr', value: 'sr'},
   ],
  RETRY_COUNT: 4,
  HELPLINE_NUMBER:'(954) 613 - 6051',
  CONTACT_NUMBER: '9546136051',
  AUTH: {
    AUTHENTICATE: 'Authorization/authenticate',
    USER_NAME: "cluster3234",
    USER_PWD: "pravendra",
    NETWORK_AVAILABILITY: 'ServiceAvailability/CheckServiceAvailability',
    PERSONAL: 'Enrollment/personal',
    SECURITY: 'Enrollment/security',
    CHECKOUT_RETURN_URL: "https://enroll.aqsatel.com/#/registration/esign",
    
    POPULATE_CITY: "Enrollment/populateCityState",
    ALL_STATE: "Enrollment/getAllStates",
    ALL_CITY: "Enrollment/getAllCities",
    ALL_ZIP: "Enrollment/getAllZipCodes",

    GET_IP: "https://api.ipify.org?format=json",
    ADD_BENEFICIARY: 'Enrollment/AddBeneficiary',
    ADD_ADDRESS: 'Enrollment/AddAddress',
    QUESTIONS: 'Enrollment/Questions',
    GET_PROGRAMS: 'Enrollment/GetPrograms',
    SAVE_PROGRAMS: 'Enrollment/SavePrograms',
    REFRESH_TOKEN: "Authorization/RefreshToken",
    PAYMENT_TRANS: "Enrollment/GetPaymentTransactionFields",
    PAYMENT: "Enrollment/ChargeCreditCard",
    ZERO_PAYMENT: "Enrollment/CustomerEnroll",
    GET_DEVICE: "Enrollment/GetDeviceCombos",
    SAVE_DEVICE: "Enrollment/SaveDeviceCombos",
    GET_PLAN: "Enrollment/GetPlans",
    SAVE_PLAN: "Enrollment/SavePlans",
    CHECK_ELIGIBLE: "Enrollment/EligibilityCheck",
    E_SIGN: "Enrollment/EsignEnroll",
  }
};


