import { AfterViewInit, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FORM_CONST } from 'src/app/shared/constants';
import { ApplicantAddressService } from 'src/app/shared/services/applicant_address.services';
import { applyValidation, throwMessages } from 'src/app/shared/validators/formValidation';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { RefreshServices } from 'src/app/shared/services/refresh.services';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/shared/services/common.service';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { ProgramServices } from 'src/app/shared/services/program.services';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-applicant-address',
  templateUrl: './applicant-address.component.html',
  styleUrls: ['./applicant-address.component.scss']
})

export class ApplicantAddressComponent implements OnInit, AfterViewInit, OnDestroy {

  public applicantForm: any;
  public otherBillingAddress: boolean = false;

  private subscription: Subscription;
  private subscriptionRefresh: Subscription;
  private subscriptionApplicantAddress: Subscription;

  public formSubmitted: boolean = false;
  public apiProcess:boolean = false;

  public apiProcessAddress:boolean = false;
  public apiProcessAddress1:boolean = false;

  public errorMessage: string;
  private timeOutId: number = -1;

  public populateAddress: any;
  
  public allStates: any;
  
  public allZips: any;
  public allCities: any;
  
  public allZips1: any;
  public allCities1: any;

  public zipErrorMessage:string = "";
  public stateErrorMessage:string = "";
  public cityErrorMessage:string = "";

  public zipErrorMessage1:string = "";
  public stateErrorMessage1:string = "";
  public cityErrorMessage1:string = "";

  public closeResult: string;
  public uspsSuggestion: boolean = false;
  public uspsSuggestionAddress: any;

  private resultData;

  @Input()
  public filledData;

  @LocalStorage()
  private applicationInfo;

  @LocalStorage()
  private prefilledInfo;

  @ViewChild('uspsSuggestionModal') modalTemplate;

  constructor(
    private applicantServices: ApplicantAddressService,
    private tokenService: RefreshServices,
    private cookieService: CookieService,
    public commonService: CommonService,
    public programService: ProgramServices,
    private localStorage: LocalStorageService,
    private router: Router,
    private modalService: NgbModal,
    ){
    this.commonService.setStepInfo('registration/address');
    this.applicantForm = {
      addressType: {
        value: 'permanent'
      },
      billingType: {
        value: 'sameAddress'
      },
      liveInTribalRegion: {
        value: false
      },
      address: {
        address1: {value: '', isValid: false},
        address2: {value: ''},
        zipCode: {value: '', isValid: false},
        city: {value: '', isValid: false},
        state: {value: '', isValid: false},
      },
      address2: {
        address3: {value: '', isValid: false},
        address4: {value: ''},
        zipCode1: {value: '', isValid: false},
        city1: {value: '', isValid: false},
        state1: {value: '', isValid: false},
      }
    };

    this.subscriptionRefresh = this.tokenService.refreshTokenSubject.subscribe(
      () => {},
      (error) => {
        this.errorMessage = error.message;
      }
    )
    this.subscriptionApplicantAddress = this.applicantServices.myData$.subscribe(
      () => {},
      (error) => {
        this.errorMessage = error.message;
        this.apiProcess = false;
      }
    )
  }

  ngOnInit(){
    // if(this.applicationInfo){
    //   this.applicantForm = {
    //     ...this.applicationInfo
    //   }
    // }

    const userID = +this.cookieService.get('x-userId');
    const payload = {
      companyId: environment.COMPANY_ID,
      userID
    }
    if(!this.applicationInfo){
      this.apiProcessAddress =  true;
      this.apiProcessAddress1 =  true;
      this.applicantServices.populateCityAndSate(payload).subscribe( 
        (response) => {
          this.commonService.expiredTokenError = "";
          if(response.Status){
            this.populateAddress = response.data;
            const { city, zip, state } = response.data;
  
            this.currentAddress.city.value = city;
            this.currentAddress.zipCode.value = zip;
            this.currentAddress.state.value =  state;
  
            this.otherAddress.city1.value = city;
            this.otherAddress.zipCode1.value = zip;
            this.otherAddress.state1.value =  state;
          }
          else {
            this.stateErrorMessage = "Something went wrong, please retry.";
          }
          this.apiProcessAddress =  false;
          this.apiProcessAddress1 =  false;
        },
        (error) => {
          this.commonService.expiredTokenError = "";
          this.stateErrorMessage = "Something went wrong, please retry.";
          console.warn(error);
          this.apiProcessAddress =  false;
          this.apiProcessAddress1 =  false;
        }
      );
    }

    this.applicantServices.getAllState(payload).subscribe( 
      (response) => {
        this.commonService.expiredTokenError = "";
        if(response.Status){
          this.allStates = response.data.map(
            state => {
              const data = {};
                data["id"] = state.id;
                data["label"] = state.stateCode;
                data["value"] = state.stateCode;
                return data;
            }
          );
          if(this.applicationInfo){
            this.applicantForm = {
              ...this.applicationInfo
            }
          }
        }
        else {
          this.stateErrorMessage = "Something went wrong, please retry.";
        }
      },
      (error) => {
        this.commonService.expiredTokenError = "";
        this.stateErrorMessage = "Something went wrong, please retry.";
        console.warn(error);
      }
    ); 
  }

  ngAfterViewInit(){
    
   }

  get currentAddress(){
    return this.applicantForm.address
  }

  get otherAddress(){
    return this.applicantForm.address2
  }

  get billingType(){
    return this.applicantForm.billingType
  }

  get addressType(){
    return this.applicantForm.addressType;
  }

  get liveInTribalRegion(){
    return this.applicantForm.liveInTribalRegion;
  }

  slidePrevious(){
     this.router.navigate(['registration/beneficiary']);
  }

  addressCall(event, fieldName){
    
    if(!fieldName || !event){
      return;
    }
    const otherEvent = {
      currentTarget : {
        name: fieldName,
        value: event.label
      }
    }

    if(typeof fieldName === 'string'){
      if(fieldName === 'city1' || fieldName === 'city'){
        this.otherAddress.city1.value = event.label;
        this.currentAddress.city.value = event.label;
        this.callAllZip(event.id, this.currentAddress);
      }
      else if(fieldName === 'state1' || fieldName === 'state'){
        this.currentAddress.state.value = event.label;
        this.otherAddress.state1.value = event.label;
        this.callAllCities(event.id, this.otherAddress);
      }
      else if(fieldName === 'zipCode1'){
        this.otherAddress.zipCode1.value = event.label;
      }
      else if(fieldName === 'zipCode'){
        this.currentAddress.zipCode.value = event.label;
      }
      return;
    }
    this.checkValidation(otherEvent);
  }

  checkValidation(event){
    
    const {value, name}  = event.currentTarget;
    if(!name){
      return;
    }
    const isValid = this.checkNameWithAddress1(name)


    if(isValid){
      if(name === "state1" && (value.length < 2 || value.length >= 3)){
        this.otherAddress[name].isValid = true;
      }

      else if(name === "address3"){
        if(value.length < 4 || value.length > 100){
          this.otherAddress[name].isValid = true;
        }
        else {
          this.otherAddress[name].isValid = false;
        }
      }
      else if(name === "zipCode1"){
        const testNumber = FORM_CONST.ZIP_CODE_PATTERN.test(value);
        this.otherAddress[name].isValid = !testNumber;
      }
      else if (!value){
        this.otherAddress[name].isValid = true;
      }
      else {
        this.otherAddress[name].isValid = false;
      }
    }
    else {
      if(name === "state" && (value.length < 2 || value.length >= 3)){
        this.currentAddress[name].isValid = true;
      }

      else if(name === "address1" && (value.length < 4 || value.length > 100)){
        this.currentAddress[name].isValid = true;
      }
      else if(name === "zipCode"){
        const testNumber = FORM_CONST.ZIP_CODE_PATTERN.test(value);
        this.currentAddress[name].isValid = !testNumber;
      }
      else if (!value){
        this.currentAddress[name].isValid = true;
      }
      else {
        this.currentAddress[name].isValid = false;
      }
    }
  }
  
  checkNameWithAddress1(name):boolean {
    const keysCollection = Object.keys(this.applicantForm.address2);
    return keysCollection.includes(name);
  }

  changeApplicantAddressRadioValue(event){
    const {name, value} = event.currentTarget;

    if(name === 'addressType'){
      this.applicantForm.addressType.value === value;
    }
    else if(name === 'sameBillingAddress'){
      this.applicantForm.billingType.value === value;
      this.updateBillingAdd();
    }
  }

  updateBillingAdd(){
    if(this.applicantForm.billingType.value === "otherAddress"){
        this.otherBillingAddress = true
    }
    else {
      this.otherBillingAddress = false;
    }
  }

  resetAddressErrorMessage(){
    this.cityErrorMessage = "";
    this.zipErrorMessage = "";
    this.stateErrorMessage= "";
    this.cityErrorMessage1 = "";
    this.zipErrorMessage1 = "";
    this.stateErrorMessage1 = "";
  }

  callAllCities(stateId, objName){
     this.apiProcessAddress = true;
    this.apiProcessAddress1 = true;

    this.resetAddressErrorMessage();
    const userID = +this.cookieService.get('x-userId');
    const payload = {
      companyId: environment.COMPANY_ID,
      userID
    }

    this.applicantServices.getAllCity(payload, stateId).subscribe( 
      (response) => {
        this.apiProcessAddress = this.apiProcessAddress && false;
        this.apiProcessAddress1 = this.apiProcessAddress1 && false;

        this.commonService.expiredTokenError = "";
        if(response.Status){
          const data = response.data.map(
            (city) => {
              const updated = {
                id: city.id,
                value: city.id,
                label: city.CityName,
              }
              return  updated
            }
          )

          this.currentAddress["city"] = {
            value: data[0].label,
            isValid: false
          };
          this.otherAddress["city1"] = {
            value: data[0].label,
            isValid: false
          };
            
          this.currentAddress["zipCode"] = {
              value: '',
              isValid: false
          }

          this.otherAddress["zipCode1"]= {
            value: '',
            isValid: false
          }

          this.allCities = data;

          this.callAllZip(data[0].id, objName)
        }
        else {
          if('city' in objName){
            this.stateErrorMessage = "Please retry after sometime"
          }
          if('city1' in objName){
            this.stateErrorMessage1 = "Please retry after sometime"
          }
        }
      },
      (error) => {
        this.apiProcessAddress = false;
        this.apiProcessAddress1 = false;
        this.cityErrorMessage = "Select city is not available please retry after sometime";
        this.cityErrorMessage1 = "Select city is not available please retry after sometime";
        console.warn(error);
      }
    );
  }

  callAllZip(cityCode, objName){
    this.resetAddressErrorMessage();
    const userID = +this.cookieService.get('x-userId');
    const payload = {
      companyId: environment.COMPANY_ID,
      userID
    }

    this.applicantServices.getAllZip(payload, cityCode).subscribe( 
      (response) => {
        this.commonService.expiredTokenError = "";
        if(response.Status){
          const data = response.data.map(
            (zip) => {
              const updated = {
                id: zip.id,
                value: zip.ZipCode,
                label: zip.ZipCode,
              }
              return  updated
            }
          )
            this.currentAddress.zipCode = {
              value: data[0].value,
              isValid: false
            }

            this.otherAddress.zipCode1 = {
              value: data[0].value,
              isValid: false
            }
            this.allZips = data;
            this.allZips1 = data;
          }
        else {
            this.allZips = [];
            this.cityErrorMessage = response.error["cityId"];
           
            this.allZips1 = [];
            this.cityErrorMessage1 = response.error["cityId"];
        }
      },
      (error) => {
          this.zipErrorMessage = "Zip code not available with selected city, please retry after sometime, or you may Change the city."
          this.currentAddress["zipCode"] = {
            value: '',
            isValid: true
          }
          this.zipErrorMessage1 = "Zip code not available with selected city, please retry after sometime, or you may Change the city."
          this.otherAddress["zipCode1"] = {
            value: '',
            isValid: true
          }
        console.warn(error);
      }
    );
  }

  onSubmit(){
    let invalidForm = false;
    if(this.otherBillingAddress){ 
      applyValidation(this.otherAddress);
      invalidForm = throwMessages(this.otherAddress);
      if(invalidForm){
        return;
      }
    }
    
    applyValidation(this.currentAddress);
    invalidForm = throwMessages(this.currentAddress);

    if(!invalidForm){
      this.timeOutId = window.setTimeout( () => {
        this.apiProcess = true;
      }, 1200);

      this.errorMessage = "";
      const { address1, address2, zipCode, city, state } = this.currentAddress;
      const isPermanent = this.addressType.value === 'permanent' ? true : false;
      const isSameAsBilling =  this.billingType.value === 'sameAddress' ? true : false;
      
      const userID = +this.cookieService.get('x-userId');

      const payload = {
        companyId: environment.COMPANY_ID,
        userID,
        billingAddress: {
          address1: address1.value,
          address2: address2.value,
          zipCode: parseInt(zipCode.value),
          city: city.value,
          state: state.value
        },
        isPermanent,
        isSameAsBilling,
        isTribal: this.liveInTribalRegion.value
      };

      if(!isSameAsBilling){
        const {address3, address4, zipCode1, city1, state1 } = this.otherAddress;
        payload["shippingAddress"] = {
          address1: address3.value,
          address2: address4.value,
          zipCode: parseInt(zipCode1.value),
          city: city1.value,
          state: state1.value,
        }
      }
      
    //   if(this.commonService.deepEqual(this.applicationInfo, this.applicantForm)){
    //     this.apiProcess = false;
    //     this.formSubmitted = false;
    //     this.router.navigate(['registration/program'])
    //     return;
    // }
      
      this.subscription = this.applicantServices.put(payload)
      .subscribe(
        (response => {
          window.clearTimeout(this.timeOutId);
          this.commonService.expiredTokenError = "";
          this.apiProcess = false;
          if(response.status === 200 && response.body.Status){
              const resultData = response.body.data;
              this.resultData = resultData;
              if(this.prefilledInfo){
                this.prefilledInfo.resumeInfo.currentStep = resultData.currentStep;
                this.localStorage.store('prefilledInfo', this.prefilledInfo);
              }
              if(resultData.enrollmentNumber){
                this.commonService.updateEnrollment(resultData.enrollmentNumber);
              }
              // this.formSubmitted = true;

              this.localStorage.store('applicationInfo', this.applicantForm);
               this.prefilledInfo.resumeInfo.currentStep = resultData["currentStep"];
               this.localStorage.store('prefilledInfo', this.prefilledInfo);

               if('uspsSuggestion' in resultData){
                const {uspsSuggestion} = resultData;
                this.uspsSuggestion = true;
                this.uspsSuggestionAddress = this.filterNull(uspsSuggestion);
                this.open(this.modalTemplate);
                return
                }
               
               const nextRoute = this.commonService.setRouter(resultData.currentStep);
               this.router.navigate([nextRoute]);
            }
            else {
              this.open(this.modalTemplate);
              // this.errorMessage = response.body.message;
              this.formSubmitted = false;
            }
        }),
        ((error) => {
          window.clearTimeout(this.timeOutId);
          this.commonService.expiredTokenError = "";
          this.apiProcess = false;
          this.formSubmitted = false;
          if(!error.error && !error.error.message ){
            error.error.message = "Unknown error";
          }
          this.errorMessage = error.error.message;
        })
      );
    }
  }

  filterNull(obj){
    for(const [objKey, objValue] of Object.entries(obj)){

      if(objKey === 'billingAddress'){
          for(const [billingKey, billingValue] of Object.entries(objValue)){
            if(!billingValue){
              obj.billingAddress[billingKey] = "";
            }
            if(billingValue === "null"){
              obj.billingAddress[billingKey] = "";
            }
          }
      }
      if(objKey === 'shippingAddress'){
        for(const [shippingKey, shippingValue] of Object.entries(objValue)){
            if(shippingValue === "null"){
              obj.billingAddress[shippingKey] = "";
            }
           if(!shippingValue){
             obj.shippingAddress[shippingKey] = "";
           }
        }
      }
    }
    return obj;
  }

  open(content: TemplateRef<any>) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true  }).result.then(
			(result) => {
				this.closeResult = `Closed with: ${result}`;
			},
			(reason) => {
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			},
		);
	}

  private getDismissReason(reason: any): string {
		switch (reason) {
			case ModalDismissReasons.ESC:
				return 'by pressing ESC';
			case ModalDismissReasons.BACKDROP_CLICK:
				return 'by clicking on a backdrop';
			default:
				return `with: ${reason}`;
		}
	}

  modifyAddress(closable){

    if('billingAddress' in this.uspsSuggestionAddress){
      const {address1, address2 = "", city, state, zip } = this.uspsSuggestionAddress["billingAddress"];
      this.currentAddress["address1"] = {
        value: address1,
        isValid: false
      } 
      this.currentAddress["address2"] = {
        value: address2,
        isValid: false
      } 
      this.currentAddress["zipCode"] = {
        value: zip,
        isValid: false
      } 
      this.currentAddress["city"] = {
        value: city,
        isValid: false
      } 
      this.currentAddress["state"] = {
        value: state,
        isValid: false
      }
    }

    if('shippingAddress' in this.uspsSuggestionAddress){
      const {address1, address2 = "", city, state, zip } = this.uspsSuggestionAddress["shippingAddress"];
      this.otherAddress["address3"] = {
        value: address1,
        isValid: false
      } 
      this.otherAddress["address4"] = {
        value: address2,
        isValid: false
      } 
      this.otherAddress["zipCode1"] = {
        value: zip,
        isValid: false
      } 
      this.otherAddress["city1"] = {
        value: city,
        isValid: false
      } 
      this.otherAddress["state1"] = {
        value: state,
        isValid: false
      }
    }
    if(closable){
      this.uspsSuggestion = false;
      this.modalService.dismissAll('none');
    }
  }

  registrationCancel(){
    this.modalService.dismissAll('none');
    this.router.navigate(['/']);
  }
  saveAddress(){
    this.modalService.dismissAll('none');
    const nextRoute = this.commonService.setRouter(this.resultData.currentStep);
    this.router.navigate([nextRoute]);
  }

  ngOnDestroy() {
    
    if(this.subscription){
      this.subscription.unsubscribe()
    }
    if(this.subscriptionRefresh){
      this.subscriptionRefresh.unsubscribe()
    }
    if(this.subscriptionApplicantAddress){
      this.subscriptionApplicantAddress.unsubscribe()
    }
  }
}
