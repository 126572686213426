import { Component, Input } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProgramServices } from 'src/app/shared/services/program.services';

@Component({
  selector: 'app-school-lunch',
  templateUrl: './school-lunch.component.html',
  styleUrls: ['./school-lunch.component.scss']
})
export class SchoolLunchComponent {

  @Input()
  public program;

  @Input()
  public index;


  public NSL: any[];

  constructor(
    public programService: ProgramServices,
    public commonService: CommonService
  ){}

  ngOnInit(){
    this.NSL = this.program.inputFields;
    this.NSL.forEach(element => {
      if(element.required === 'Y'){
        element["isValid"] = false
      }
    });
  }
}

