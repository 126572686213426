import { Component, Input, OnInit } from '@angular/core';
import { ProgramServices } from 'src/app/shared/services/program.services';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss']
})
export class IncomeComponent implements OnInit {

  
  @Input()
  public program;

  @Input()
  public index;
  
  public IB;

  constructor(
    public programService: ProgramServices
  ){}

  ngOnInit(): void {
    this.IB =  this.program.incomeBaseProgramMapping;
  }

  userResponseOnIncome(string):void{
    this.programService.updateIBUserRes(string);
    this.programService.showIBConfirmBox = false;
  }
}
