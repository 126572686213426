import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FORM_CONST } from 'src/app/shared/constants';
import { SecurityFormService } from 'src/app/shared/services/securit_form.services';
import { environment } from 'src/environments/environment';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent  implements OnInit, AfterViewInit, OnDestroy  {
    public securityForm: FormGroup;

    public formSubmitted: boolean = false;
    public apiProcess:boolean = false;
    public errorMessage: string;

    private subscription: Subscription;
    private subscriptionRefresh: Subscription;
    private subscriptionSecurity: Subscription;

    public formattedPhoneNumber: string = "";
    public contactNumberPlaceHolder: string = environment.HELPLINE_NUMBER;
    public showFormatContactNumber: boolean = false
    private timeOutId: number = -1;

    @LocalStorage()
    private prefilledInfo;
    
    @LocalStorage()
    private dlStoreData

    @LocalStorage()
    private dlStoreDataEndpoint

    @Input()
    stepType: string;


    @Input()
    public filledData;

    constructor(
      private fb: FormBuilder,
      private securityServices: SecurityFormService,
      private localStorage: LocalStorageService,
      public commonService: CommonService,
      private cookieService: CookieService,
      private router: Router
    ){
      this.commonService.setStepInfo('registration/security');
    }

    ngOnInit(){

      this.securityForm =  this.fb.group({
        contactNumber: [
          '',
          [
            Validators.required,
            Validators.pattern(FORM_CONST.PHONE_NUMBER_PATTERN)
          ]
        ],
        ssnNumber: [
          {value: '', disabled: this.apiProcess}, 
          [
            Validators.required,
            Validators.pattern(FORM_CONST.SSN_PATTERN)
          ],
        ],
        driverLicense: [
          {value: '', disabled: this.apiProcess}, 
        ],
        authorize: [{value: false, disabled: this.apiProcess}, 
          [Validators.required]
        ],
      });
      
      if(this.prefilledInfo){
        // this.setSecurityFormData(this.prefilledInfo)
      }
      else {
        this.setSecurityFormData(this.filledData);
      }

      if(this.dlStoreData){
        this.securityForm.patchValue({
          ...this.dlStoreData
        })
      }
    }

    formatPhoneNumberData(){
      const {contactNumber} = this.securityForm.value;
      if(contactNumber.length === 10){
        const areaCode = contactNumber.substring(0, 3);
        const firstPart = contactNumber.substring(3, 6);
        const secondPart = contactNumber.substring(6, 10);
        this.formattedPhoneNumber = '(' + areaCode + ') ' + firstPart + ' - ' + secondPart;
        this.showFormatContactNumber = true;
      }
      else {
        this.showFormatContactNumber = false;
      }
    }

  hideFormattedContactNumber(){
    this.showFormatContactNumber = false;
  }

  ngAfterViewInit(){

    // if(this.dlStoreData){
    //   this.securityForm.patchValue({
    //     ...this.dlStoreData
    //   })
    // }
  }

    setSecurityFormData(data){
       
    }

    get ssnNumber(){
      return this.securityForm.get('ssnNumber');
    }

    get driverLicense(){
      return this.securityForm.get('driverLicense');
    }

    get authorize(){
      return this.securityForm.get('authorize');
    }
    
    get contactNumber(){
      return this.securityForm.get("contactNumber");
    }

    onSubmit(){
        const userID = +this.cookieService.get('x-userId');
        this.errorMessage = "";

        if(!this.authorize.value){
          this.authorize.setErrors({ customError: 'Check is required'})
        }
        
         if(this.securityForm.valid){
          this.timeOutId = window.setTimeout( () => {
            this.apiProcess = true;
          }, 1200);
          const {ssnNumber, driverLicense, contactNumber, authorize } = this.securityForm.value;
          
          const payload = {
            companyId: environment.COMPANY_ID,
            userID,
            ssn: `${ssnNumber}`,
            driverLicense,
            selfConsent: authorize ? 'Yes': '',
            contactNumber
          };
          // const authorize = payload.selfConsent === 'Yes' ? true : false;

          const storeData = {
            ssnNumber,
            driverLicense,
            authorize,
            contactNumber
          }

          if(this.dlStoreDataEndpoint && JSON.stringify(this.dlStoreData) === JSON.stringify(storeData)){
              window.clearTimeout(this.timeOutId);
              this.apiProcess = false;
              this.formSubmitted = false;
              this.router.navigate(['registration/beneficiary']);
              return;
          }

          this.localStorage.store('dlStoreData', storeData);
          this.localStorage.store('dlStoreDataEndpoint', false);

          this.subscription = this.securityServices.put(payload).subscribe(
            (response) => {
              window.clearTimeout(this.timeOutId);
              this.commonService.expiredTokenError = "";
              
              if(response.status === 200 && response.body.Status){
                this.localStorage.store('ssn', ssnNumber);
                this.localStorage.store('dl', driverLicense);
                this.apiProcess = false;
                this.formSubmitted = true;
                this.localStorage.store('dlStoreDataEndpoint', true);
                const resultData = response.body.data;
                if(resultData.enrollmentNumber){
                  this.commonService.updateEnrollment(resultData.enrollmentNumber);
                }
                if(this.prefilledInfo){
                  this.prefilledInfo.resumeInfo.currentStep = resultData.currentStep;
                  this.localStorage.store('prefilledInfo', this.prefilledInfo);
                }
                const nextRoute = this.commonService.setRouter(resultData.currentStep);
                this.router.navigate([nextRoute]);
              }
              else {
                const {body} = response;
                if('error' in body && typeof body["error"] === 'object'){
                  const keys = Object.keys(body.error);

                  keys.map( (fieldKey:string) => {
                    if(fieldKey === 'driverLicense'){
                      this.driverLicense.setErrors({customError: body["error"].driverLicense});
                    }
                    else if(fieldKey === 'contactNumber'){
                      this.contactNumber.setErrors({customError: body["error"].contactNumber});
                    }
                    else if(fieldKey === 'ssn'){
                      this.ssnNumber.setErrors({customError: body["error"].ssn});
                    }
                    else if(fieldKey === 'selfConsent'){
                      this.authorize.setErrors({customError: body["error"].selfConsent});
                    }
                  })
                }
                this.errorMessage = response.body.message;
                this.apiProcess = false;
                this.formSubmitted = false;

              }
            },
            (error) => {
              window.clearTimeout(this.timeOutId);
              this.commonService.expiredTokenError = "";
              this.apiProcess = false;
              this.formSubmitted = false;
              if(!error.error && !error.error.message ){
                error.error.message = "Unknown error";
              }
              this.errorMessage = error.error.message;
            }
          );
        }
        else {
          this.securityForm.markAllAsTouched();
        }
      }

      
    slidePrevious(){
      this.router.navigate(['registration/personal']);
    }

    ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }
      if(this.subscriptionRefresh){
        this.subscriptionRefresh.unsubscribe();
      }

      if(this.subscriptionSecurity){
        this.subscriptionSecurity.unsubscribe();
      }
    }
}
