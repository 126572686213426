import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Step1RoutingModule } from './step1-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PersonalComponent } from './personal/personal.component';
import { SecurityComponent } from './security/security.component';
import { ApplicantAddressComponent } from './applicant-address/applicant-address.component';
import { BeneficiaryComponent } from './beneficiary/beneficiary.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { QuestionComponent } from './question/question.component';
import { ProgramComponent } from './program/program.component'; 
import { SharedModule } from 'src/app/shared/shared.module';
import { SchoolLunchComponent } from './program/school-lunch/school-lunch.component';
import { HousingComponent } from './program/housing/housing.component';
import { IncomeComponent } from './program/income/income.component';
import { PlansComponent } from './plans/plans.component';
import { DevicesComponent } from './devices/devices.component';
import { DeviceCartComponent } from './device-cart/device-cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SubPlanComponent } from './plans/sub-plan/sub-plan.component';
import { SubDeviceComponent } from './devices/sub-device/sub-device.component';
import { PreviewComponent } from './preview/preview.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EsignComponent } from './esign/esign.component';

@NgModule({
  declarations: [
    PersonalComponent,
    SecurityComponent,
    ApplicantAddressComponent,
    BeneficiaryComponent,
    QuestionComponent,
    ProgramComponent, 
    SchoolLunchComponent,
    HousingComponent,
    IncomeComponent,
    PlansComponent,
    DevicesComponent,
    DeviceCartComponent,
    CheckoutComponent,
    SubPlanComponent,
    SubDeviceComponent,
    PreviewComponent,
    EsignComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    Step1RoutingModule,
    BsDatepickerModule,
    SharedModule,
    NgSelectModule
  ],
  exports: [
    PersonalComponent,
    SecurityComponent,
    ApplicantAddressComponent,
    BeneficiaryComponent,
    QuestionComponent,
    ProgramComponent,
    SchoolLunchComponent,
    HousingComponent,
    IncomeComponent,
    PlansComponent,
    DevicesComponent,
    DeviceCartComponent,
    CheckoutComponent,
    SubPlanComponent,
    SubDeviceComponent,
    PreviewComponent,
    EsignComponent
  ]
})
export class Step1Module { }
