import {  Injectable } from '@angular/core';
import { IAutoLoginReq } from '../model/auto_login.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { CookieService } from 'ngx-cookie-service';
import { RefreshServices } from './refresh.services';

@Injectable({
    providedIn: 'root'
})

export class CommonService {
    protected baseApi: string;
    public router: Router;
    public carouselStartIndex: number = 0;
    public expiredTokenError:string = ""; // it is available for setting up when token time get expires.
    public lockPreviousStep = false; // it is for locking the prev button and steps;
    public showCallbackUrlButton = false;
    public isTextCopied: boolean = false;
    public token: string;
    private lastSegment: string;
    public enrollMsg:string; //this variable works for complete page screens. 

    // public enrollmentId:string = '';

    public stepInformation = new BehaviorSubject<string>(null);
    stepInformation$ = this.stepInformation.asObservable();

    public enrollmentInfo = new BehaviorSubject<string>(null);
    enrollmentInfo$ = this.enrollmentInfo.asObservable();

    private subscription: Subscription; 

    @LocalStorage()
    private xExpire;

    constructor(
      private http: HttpClient,
      private localStorage: LocalStorageService,
      private cookieService: CookieService,
      private refreshServices: RefreshServices,
      private activatedRoute: ActivatedRoute
    ){
      this.baseApi = environment.BASE_API; 
      this.token = this.cookieService.get('x-token');

      this.activatedRoute.url.subscribe(urlSegments => {
        this.lastSegment = urlSegments[urlSegments.length - 1].path;
        // console.log('Last segment of the URL:', this.lastSegment);
      });
      if(!this.router){
        this.router = new Router();
      }
    }
   
    public getErrorMessageArranged(param): string[]{
        const result: string[] = []; 
        if(param instanceof Object){
            for(const [key, value] of Object.entries(param)){
              if(value instanceof Array){
                  value.map(item => {
                    result.push(`${key.toUpperCase()}:: ${item}`)
                  })                      
              }
              else {
                result.push(`${key.toUpperCase()}:: ${value}`)
              }
            }
        }
        return result;
    } 
    
    public getTrimmedValue(stringChar){
      if(stringChar.indexOf(' ') > -1){
        return stringChar.split(' ').join('');
      }
      return stringChar;
    }

    public deleteObjectKeys(keys, obj):void{
      if(typeof keys === 'object' && keys instanceof Array){
        keys.forEach( (key:string) => {
          if(key in obj){
            delete obj[key];
          }
        })
      }
      else if(keys === 'string'){
        if(keys in obj){
          delete obj[keys];
        }
      }
    }

    public updateEnrollment(id){
      if(id){
        this.localStorage.store('enrollmentIdStorage', id);
      }
      this.enrollmentInfo.next(id); 
    }

    public authCall(req: IAutoLoginReq ):Observable<HttpResponse<any>>{
      

      return this.http.post(`${this.baseApi}/${environment.AUTH.AUTHENTICATE}`, req, { observe: 'response'});
    }
    
    public handleError(urlWithQueryString, err) {
      console.warn(urlWithQueryString);
      console.warn(err);
      if(err.status === 401){
        this.router.navigate([urlWithQueryString]);
      }
      else {
        return err;
      }
    }

    //Populate address call 
    public populateAddress(req: IAutoLoginReq ):Observable<HttpResponse<any>>{
      this.checkExpiredToken();
      return this.http.post(`${this.baseApi}/${environment.AUTH.AUTHENTICATE}`, req, { observe: 'response'});
    }
    
    public clearLocalStorage(){
      this.localStorage.clear('applicationInfo');
      this.localStorage.clear('beneficiaryInfo');
      // this.localStorage.clear('personalInfo');
      this.localStorage.clear('dlStoreData');
      this.localStorage.clear('questionHouseHold');
      this.localStorage.clear('questionAdult');
      this.localStorage.clear('questionShareMoney');
      this.localStorage.clear('programInfo');
      this.localStorage.clear('programChecks');
      // this.localStorage.clear('enrollmentIdStorage');
      this.localStorage.clear('callbackData');
      this.localStorage.clear('resolutionUrl');
      this.localStorage.clear('cStep');
    }

    public clearLocalStorageWithoutExpireTime(){
      this.localStorage.clear('applicationInfo');
      this.localStorage.clear('beneficiaryInfo');
      this.localStorage.clear('personalInfo');
      this.localStorage.clear('dlStoreData');
      this.localStorage.clear('questionHouseHold');
      this.localStorage.clear('questionAdult');
      this.localStorage.clear('questionShareMoney');
      this.localStorage.clear('programInfo');
      this.localStorage.clear('programChecks');
      this.localStorage.clear('enrollmentIdStorage');
      this.localStorage.clear('callbackData');
      this.localStorage.clear('resolutionUrl');
      this.localStorage.clear('cStep');
      this.localStorage.clear('prefilledInfo');
    }


    public settingStorageOnNetworkCall(steps){

    for(const [key, value] of Object.entries(steps)){
      if(key === 'step2'){
        value["middleName"] ? value["middleName"] : '';
        this.localStorage.store("personalInfo", value);
      }
      else if(key === 'step3'){
        const data = {
          authorize: true,
          contactNumber: value["contactNumber"],
          driverLicense: value["driverLicense"] ? value["driverLicense"] : '',
          ssnNumber: value["ssn"] ? value["ssn"] : ''
        }
        this.localStorage.store("dlStoreData", data);
      }
      else if(key === 'step4'){
        const beneficiaryForm = {
          isApplyingForSelf: {value: 'Yes'},
          isAcpConsent: {value: true, isValid: false},
          isBqpConsent: {value: false, isValid: false},
          firstName: {value: '', isValid: false},
          middleName: {value: ''},
          lastName: {value: '', isValid: false},
          suffix: {value: ''},
          dateOfBirth: { isValid: false},
          contactNumber: {value: '', isValid: false},
          ssn: {value: '', isValid: false},
          driverLicense: {value: ''},
          isTransferConsent: {value: true, isValid: false},
        };
        if(Object.keys(value).length > 0){
          // beneficiaryInfo
          
          for( const [name, data] of Object.entries(value)){
            if(name === "dateOfBirth"){
              const dob = new Date(data);
              beneficiaryForm.dateOfBirth["value"] = dob;
            }
            else if(name === 'isTransferConsent'){
              beneficiaryForm.isTransferConsent.value =  data ? true : false
            }
            else if(name === 'isBqpConsent'){
              beneficiaryForm.isBqpConsent.value = data ? true : false
            }
            else if(name === 'isAcpConsent'){
              beneficiaryForm.isAcpConsent.value = 1 ? true : false
            }
            else if(name === 'isApplyingForSelf'){
              beneficiaryForm.isApplyingForSelf.value = data
            }
            else {
              beneficiaryForm[name].value = data ? data : ''
            }
          }
        }
        this.localStorage.store("beneficiaryInfo", beneficiaryForm);
      }
      else if(key === 'step6'){
        const formData = {
          addressType: {
            value: ''
          },
          billingType: {
            value: ''
          },
          address: {
            address1: {value: '', isValid: false},
            address2: {value: ''},
            zipCode: {value: '', isValid: false},
            city: {value: '', isValid: false},
            state: {value: '', isValid: false},
          },
          address2: {
            address3: {value: '', isValid: false},
            address4: {value: ''},
            zipCode1: {value: '', isValid: false},
            city1: {value: '', isValid: false},
            state1: {value: '', isValid: false},
          },
          liveInTribalRegion: {
            value: false
          }
        }
        
        for(const [stepKey, stepValue] of Object.entries(value)){
          if(stepKey === "isMailAddrSame"){
            formData.billingType.value = stepValue === 'Yes' ? 'sameAddress' : 'otherAddress';
          }
          else if(stepKey === "isPermanent"){
            formData.addressType.value = stepValue ? 'permanent' : 'temporary'
          }
          else if(stepKey === "isTribal"){
            formData.liveInTribalRegion.value = stepValue;
          }
          else if(stepKey === 'billingAddress'){
            const {address1, address2 = "", zipCode, city, state }  = stepValue;
            formData.address.address1.value = address1;
            formData.address.address2.value = address2;
            
            formData.address.zipCode.value = zipCode;
            formData.address.city.value = city;
            formData.address.state.value = state;
            formData.address2.city1.value = city;
            formData.address2.state1.value = state;
            formData.address2.zipCode1.value = zipCode;
          }
          else if(stepKey === 'shippingAddress'){
            const {address1, address2 = "", zipCode }  = stepValue;
            formData.address2.address3.value = address1;
            formData.address2.address4.value = address2;
            formData.address2.zipCode1.value = zipCode;
          }
        }
        this.localStorage.store("applicationInfo", formData);
      }
      else if(key === 'step8'){

        //  const checkboxes =  this.programService.programForm.checkboxes;
          const checkboxes = [];

         if(value instanceof Array  && value.length >  0){
            value.forEach((item) => {
              checkboxes.push(item.shortName);
            })
          }
        this.localStorage.store("programChecks", checkboxes);
        }
      }
    }

    public deepEqual(obj1, obj2) {
      if (obj1 === obj2) {
          return true;
      }
  
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
          return false;
      }
  
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
  
      if (keys1.length !== keys2.length) {
          return false;
      }
  
      for (const key of keys1) {
          if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) {
              return false;
          }
      }
  
      return true;
    }

    public setRouter(step):string {
      let route = '';
      
      switch(step){ 
        case 2: 
          route = 'registration/personal'
          break;

        case 3 :
          route = 'registration/security'
          break;
        
        case 4:
        case 5:
          route = 'registration/beneficiary'
          break;

        case 6:
          route = 'registration/address'
          break;

        case 7:
          route = 'registration/question'
          break;
        
        case 8:
          route = 'registration/program'
          break;

        case 11:
          route = 'registration/plan'
          break;

        case 12:
          route = 'registration/device'
          break;

        case 15:
          route = 'registration/checkout'
          break;

      default:
          route = 'login'
      }
      return route;
    }
  
    public setStepInfo(path){
      let stepInfo = '';

      switch(path){
        case 'registration':
            this.router.navigate(['/']);
            break;

        case 'registration/personal':
        case 'registration/security':
        case 'registration/beneficiary':
        case 'registration/address':
        case 'registration/question':
          stepInfo = 'step1'
          this.stepInformation.next(stepInfo);
          break;

        case 'registration/program':
          case 'registration/program-review':
          stepInfo = 'step2'
          this.stepInformation.next(stepInfo);
          break;

        case 'registration/plan':
        case 'registration/device':
        case 'registration/cart':
        case 'registration/checkout':
          stepInfo = 'step3'
          this.stepInformation.next(stepInfo);
          break;

        case 'registration/complete':
          stepInfo = 'step4'
          this.stepInformation.next(stepInfo);
          break;
        }
        return stepInfo;
    }

    onCopyToClipboard(textToCopy) {
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      this.isTextCopied = true;
      window.setTimeout(() => {
        this.isTextCopied = false;
      }, 2000);
    }

    public async checkExpiredToken(){
      const date = new Date(); 
      const timeNow = date.getTime();
      const timeExpire = new Date(this.xExpire).getTime();
       
      if( timeExpire < timeNow){
        this.expiredTokenError = "Please retry !!!"
        if(!this.token){
          // window.location.href = "/";
          this.router.navigate(['/']);
          if(this.subscription){
            this.subscription.unsubscribe();
          } 
          return;
        }
        else {
          this.router.navigate(['/']);
        }
        // if(!this.subscription){
        //   this.subscription = this.refreshServices.refreshToken().subscribe();
        // }
        // await setTimeout( () => {
        //   console.warn("refresh token service call")
        // }, 5000)
      }
    }
}

