<div class="col-12 col-sm-6">
<div 
class="card cursor-pointer min-height140"
[ngClass]="{
'b-warning':  programService.ibInvalidCard &&  programService.checkSelected(program.shortName), 
'b-success': !programService.ibInvalidCard && programService.checkSelected(program.shortName),
}"
id="{{program.shortName}}">
<div class="program-image-wrapper" (click)="programService.renderAdditionalForm(program, $event, incomeCard)">
  <ng-container *ngIf="program.imagePath; else placeHolderImg">
    <img [src]="program.imagePath" class="img-fluid" alt="images" />
  </ng-container>
  <ng-template #placeHolderImg>
    <img src="./assets/images/banner/1.jpg" class="img-fluid" alt="images" />
  </ng-template>
</div>
<div class="media p-20">
  <div *ngIf="program.shortName === 'IB'"
  (click)="programService.renderAdditionalForm(program, $event, incomeCard)">
  <!-- <div 
     class="ribbon ribbon-primary"
     >{{program.shortName}}</div> --> 
        <div class="p-absolute check-card">
          <app-feather-icons [icon]="'check-circle'"></app-feather-icons>
        </div>
        <h6 
              class="m-t-40 text-center pt-3 f-12"
             [ngClass]="{
             'text-muted': !programService.checkSelected(program.shortName)
             }">
         {{program.name}}
     </h6>
  </div>
  <ng-container *ngIf="!programService.ibInvalidCard && programService.checkSelected(program.shortName)">
    <div class="p-absolute text-center badge-list">
        <span [id]="'fph'+programService.ibSelected" class="badge badge-light text-muted">{{ 'Number of people ' + programService.ibSelected | uppercase }}</span>
    </div>
    <div class="pull-right">
      <div class="p-absolute edit-icon" (click)="programService.open(incomeCard)">
        <i class="fa fa-pencil-square-o font-primary cursor-pointer"></i>
      </div>
    </div>
  </ng-container>
</div>
</div> 

<ng-template #incomeCard let-modal>
  <div class="modal-body">
    <div *ngIf="programService.showIBConfirmBox" class="card m-b-0 bg-warning p-absolute ib-user-response">
      <div class="d-flex">
        <div> You have chosen {{programService.ibSelectedNumberOfPeople }} people. Does your income matches or higher then displayed region income ?</div>
        <div class="d-inline-flex">
          <button class="btn btn-primary btn-small" (click)="userResponseOnIncome('Y')">Yes</button>
          <button class="btn btn-danger m-l-10 btn-small" (click)="userResponseOnIncome('N')">No</button>
        </div>
      </div>
  </div>
    <div class="close-modal p-absolute" (click)="!programService.showIBConfirmBox && modal.close()">
      <i class="icon-close"></i>
    </div>
     <div class="p-20">
      <h5 class="m-b-3">Please share income base</h5>
      <div class="row p-t-20">
        <ng-container *ngFor="let ibProgram of IB; index as ibIndex ">
          <ng-container *ngIf="ibProgram.numberOfPeople < 5; then ibProgramRegularRadio else ibProgramRegularOtherRadio"></ng-container>
          <ng-template  #ibProgramRegularRadio>
            <div class="col-sm-6">
              <div class="card min-h-120 cursor-pointer"
                (click)="programService.ibOnChange(ibProgram, $event)"
                [ngClass]="{
                  'b-light': programService.ibSelected !== ibProgram.numberOfPeople,
                  'b-success': !programService.ibInvalidCard && programService.ibSelected === ibProgram.numberOfPeople,
                  'b-warning': programService.ibInvalidCard && programService.ibSelected === ibProgram.numberOfPeople
                }"
              >
                <div class="media p-20">
                  <div class="form-check radio radio-primary me-3">
                    <input
                      class="form-check-input"
                      [id]="'income'+ibProgram.numberOfPeople"
                      type="radio"
                      name="ibRadio"
                      [checked]="ibProgram.numberOfPeople === programService.ibSelected"
                      [value]="ibProgram.numberOfPeople"
                    />
                    <label 
                    class="form-check-label" 
                    [for]="'income'+ibProgram.numberOfPeople"></label>
                  </div>
                  <div class="media-body" >
                    <h6 class="mt-0 mega-title-badge">
                      {{'For people ' + ibProgram.numberOfPeople }}
                    </h6>
                    <div class="text-end">
                      <ng-container *ngFor="let keys of programService.objectKeys(ibProgram.incomeValues)">
                        <span class="badge badge-primary digits">
                         {{programService.setIBString(keys) | uppercase }}
                         {{ibProgram.incomeValues[keys]}}
                       </span>
                   </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <div class="col-sm-6" *ngIf="ibIndex === 5">
            <div class="card min-h-120 cursor-pointer"
              (click)="programService.ibOnChange('moreThanFour', ibOtherIncomeInputRef)"
              [ngClass]="{
                'b-light': programService.ibSelected !== 'moreThanFour',
                'b-success': !programService.ibInvalidCard && programService.ibSelected === 'moreThanFour',
                'b-warning': programService.ibInvalidCard && programService.ibSelected === 'moreThanFour'
              }"
            >
              <div class="media p-20">
                <div class="form-check radio radio-primary me-3">
                  <input
                    class="form-check-input"
                    id="moreThanFour"
                    type="radio"
                    name="ibRadio"
                    [checked]="'moreThanFour' === programService.ibSelected"
                    [value]="'moreThanFour'"
                  />
                  <label 
                  class="form-check-label" 
                  for="moreThanFour"></label>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mega-title-badge">
                    {{'People more then four' }}
                  </h6>
                  
                  <div class="custom-form-group">
                    <label for="customIBinput" class="m-b-0">
                      People more than four 
                    </label>
                    <input type="text" #ibOtherIncomeInputRef id="customIBinput" class="inline-input" (blur)="programService.moreIBpersonValidation()" />
                  </div>

                  <div class="text-end" *ngIf="programService.ibmoreThanFourBadges">
                    <ng-container *ngFor="let keys of programService.objectKeys(programService.ibmoreThanFourBadges.incomeValues)">
                        <span class="badge badge-primary digits">
                          {{programService.setIBString(keys) | uppercase }}
                          {{programService.ibmoreThanFourBadges.incomeValues[keys]}}
                        </span>
                    </ng-container>
                  </div>
                  <div class="text-warning" *ngIf="programService.ibProgramErrorMessage">{{programService.ibProgramErrorMessage}}</div>
                  <div class="form-group mt-1" *ngIf="!programService.ibmoreThanFourBadges">
                    <button class="btn btn-light" disabled>Check income criteria</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #ibProgramRegularOtherRadio></ng-template>
        </ng-container>
        <div class="font-danger" *ngIf="programService.ibProgramErrorMessage">
          {{programService.ibProgramErrorMessage}}
        </div>
      </div>
     </div>
     <div class="form-group mb-3 text-end">
      <button type="button" [disabled]="programService.apiProcess" class="btn btn-light" (click)="programService.resetIBForm()">Reset</button>
      <button type="button" [disabled]="programService.apiProcess"  class="btn btn-primary m-l-10" (click)="programService.saveIBForm('save and close')">Save and Close</button>
   </div>      
  </div>
</ng-template>
</div>