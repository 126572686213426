import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { ProgramServices } from 'src/app/shared/services/program.services';
import { environment } from 'src/environments/environment';

interface IError {
  id: string,
  label: string
}

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})

export class ProgramComponent implements OnInit, AfterViewInit {
  public programs;
  public loader: boolean = true;
  public apiProcess: boolean = false;
  public errorMessageEndpoint: string = "";
  public helpline = environment.HELPLINE_NUMBER;
  public resolutionUrl = ""
  public toggleForApprovalCard:boolean = false;
  private timeOutId: number = -1;

  @Output() slideNext = new EventEmitter<string>();
  @Output() prev = new EventEmitter<string>();

  public errorMessage: IError[]; 
  private subscription: Subscription;
  private subscriptionGet: Subscription;

  @LocalStorage()
  public prefilledInfo;

  @LocalStorage()
  public callbackData;

  @LocalStorage()
  private programInfo;

  @LocalStorage()
  private programChecks;

  constructor( 
    public programService: ProgramServices,
    public commonService: CommonService,
    private cookieService: CookieService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private router: Router,
     ){
      this.commonService.setStepInfo('registration/program');
     }

  ngOnInit(){
    const snapshot: ActivatedRouteSnapshot = this.route.snapshot;
    const queryParams = snapshot.queryParams;

    if(this.callbackData){
      if(!this.resolutionUrl){
        this.resolutionUrl = this.callbackData["resolutionUrl"]
      }
      this.toggleForApprovalCard = true;
      if(queryParams["completion"] === 'true'){
        this.commonService.showCallbackUrlButton = true
      }
    }
    if(this.programChecks){
      this.programService.programForm.checkboxes = this.programChecks; 
    }
  }

  ngAfterViewInit(): void {
    const userID = +this.cookieService.get('x-userId');
    const payload = {
      companyId: environment.COMPANY_ID,
      userID
    }

    this.subscriptionGet = this.programService.getProgram(payload).subscribe();

  }
  stepPrevious(){
    this.router.navigate(['registration/address'])
  }

  removeValidCase(id){
    const data = this.errorMessage.filter( item => item.id !== id);
    this.errorMessage = data;
  }

  saveProgramDataAndValidate(){
    this.errorMessage = [];

    if(this.programService.programForm.checkboxes.length > 0){
      this.errorMessageEndpoint = "";
     const checked = this.programService.programForm.checkboxes.map( (sname:string): boolean => {
        this.removeValidCase('other');
        if(sname === 'FPH'){
          if(!this.programService.fphInvalidCard) {
            this.removeValidCase('fph');
          }
          else {
            const errorAvail = this.errorMessage.find( v => v.id === 'fph')
            if(!errorAvail){
              this.errorMessage.push({
                id: "fph",
                label: "Error FPH: Please add the selected radio required attributes"
              });
            }
            return false
          }
        }
       else if(sname === 'IB' ){
          if(!this.programService.ibInvalidCard) {
            this.removeValidCase('ib');
          }
          else {
            const errorAvail = this.errorMessage.find( v => v.id === 'fph')
            if(!errorAvail){
              this.errorMessage.push({
                id: "ib",
                label: "Error IB: Please add the selected radio required attributes"
              })
            }
            return false
          }

        }
      else if(sname === 'NSL'){
          if(!this.programService.nslInvalidCard) {
            this.removeValidCase('nsl');
          }
          else {
            const errorAvail = this.errorMessage.find( v => v.id === 'nsl')
            if(!errorAvail){
              this.errorMessage.push({
                id: "nsl",
                label: "Error NSL: Please add the selected radio required attributes"
              })
            }
            return false
          }
        }
        return true;
      });
      const exists = checked.includes(false);
      if(!exists){
        this.saveProgramData();
      }
    }
    else {
      const errorAvail = this.errorMessage.find( v => v.id === 'other')
          if(!errorAvail){
            this.errorMessage.push({
              id: 'other',
              label: "Please select at least on option."
            });
        }
    }
  }

  saveProgramData(){
    this.timeOutId = window.setTimeout( () => {
      this.apiProcess = true;
    }, 1200);

    this.errorMessageEndpoint = "";
    const otherPayload = this.programService.saveProgramDataAndValidate();

    const userID = +this.cookieService.get('x-userId');

    const payload = {
        companyId: environment.COMPANY_ID,
        userID,
        returnUrl: 'https://enroll.aqsatel.com/#/registration/program?completion=true',
        subProgram: otherPayload
    } 
    this.callApi(payload);
  }

  callApi(payload){
    this.programService.saveProgram(payload).subscribe(
      (response) => {
        window.clearTimeout(this.timeOutId);
        this.apiProcess = false;
        this.localStorage.store("programInfo", payload);
        this.localStorage.store("programChecks", this.programService.programForm.checkboxes);

        this.commonService.expiredTokenError = "";
        this.toggleForApprovalCard = false;
        this.errorMessage = [];
        if(response.status === 200){
          const resultData = response.body.data;
          
          this.errorMessageEndpoint = '';
          if(response.body.msgCode === 'API000'){
            this.commonService.clearLocalStorage();
            this.commonService.lockPreviousStep = true;
            this.programService.saveProgramSuccessMessage = resultData;

            if(this.prefilledInfo){
              this.prefilledInfo.resumeInfo.currentStep = resultData.currentStep;
              this.localStorage.store('prefilledInfo', this.prefilledInfo);
            }
            const nextRoute = this.commonService.setRouter(resultData.currentStep);
            this.router.navigate([nextRoute]);
          }
          else if(response.body.error){
            const {error} = response.body;
            if('resolution' in error && error.resolution){
              this.toggleForApprovalCard = true;
              this.commonService.showCallbackUrlButton = false;
              this.resolutionUrl = error.resolution;
              this.localStorage.store("callbackData", { callback: true, toggleForApprovalCard: true, resolutionUrl: this.resolutionUrl});
            }
            else if('resolution' in error && (error.resolution === null)){
              this.errorMessageEndpoint = `Please retry after 5 min or you may reach customer support on ${this.helpline}`;
            }
            else if('header' in error && (error.header.resolutionId === '')){
              this.errorMessageEndpoint = `Please retry after 5 min or you may reach customer support on ${this.helpline}`;
            }
          }
          else if(response.body.msgCode === 'API004'){
            this.errorMessageEndpoint = `Please retry after 5 min or you may reach customer support on ${this.helpline}`;
          }
          else {
              this.errorMessageEndpoint = response.body.message;
            } 
          }
        },
        (err) => {
          window.clearTimeout(this.timeOutId);
          this.apiProcess = false;
          this.commonService.expiredTokenError = "";
          if(!err.error || !err.error.message){
            err = {
              error: {
                message : "Unknown error"
              }
            }
          }
          this.errorMessageEndpoint = err.error.message;
        }
    );
  }
  continueAction(){
    this.localStorage.clear('callbackData');
    this.timeOutId = window.setTimeout( () => {
      this.apiProcess = true;
    }, 1200);
    this.errorMessageEndpoint = "";
    const payload = this.programInfo;
    this.callApi(payload);
  }

  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe();
    }
    if(this.subscriptionGet){
      this.subscriptionGet.unsubscribe(); 
    }
  }
}
