import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PersonalComponent } from './personal/personal.component';
import { SecurityComponent } from './security/security.component';
import { BeneficiaryComponent } from './beneficiary/beneficiary.component';
import { ApplicantAddressComponent } from './applicant-address/applicant-address.component';
import { QuestionComponent } from './question/question.component';
import { ProgramComponent } from './program/program.component'; 
import { PlansComponent } from './plans/plans.component';
import { DevicesComponent } from './devices/devices.component';
import { DeviceCartComponent } from './device-cart/device-cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PreviewComponent } from './preview/preview.component';
import { EsignComponent } from './esign/esign.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'personal',
        component: PersonalComponent,
      },
      {
        path: 'security',
        component: SecurityComponent,
      },
      {
        path: 'beneficiary',
        component: BeneficiaryComponent,
      },
      {
        path: 'address',
        component: ApplicantAddressComponent,
      },
      {
        path: 'question',
        component: QuestionComponent,
      },
      {
        path: 'program',
        component: ProgramComponent,
      },
      {
        path: 'plan',
        component: PlansComponent,
      },
      {
        path: 'device',
        component: DevicesComponent,
      },
      {
        path: 'cart',
        component: DeviceCartComponent,
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
      },
      {
        path: 'complete',
        component: PreviewComponent,
      },
      {
        path: 'esign',
        component: EsignComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class Step1RoutingModule { }
