<div class="row">
   <div class="p-2">
      <div class="login-main program-form" *ngIf="planService.loadPlans; else planLoader" >
         <ng-container *ngIf="planService.dataPlanDevice.allPlans; else errorMessage">
            <h4 class="font-primary mb-3">Select Your Plan</h4>
            <p class="f-16">Please make a selection of your plan</p>
            <div class="row">
               <ng-container *ngFor="let plan of planService.dataPlanDevice.allPlans; index as planIndex">
                  <div class="col col-sm-6" *ngIf="!plan.isHidden">
                        <app-sub-plan [plan]="plan"></app-sub-plan>
                  </div>
               </ng-container>
               <div *ngIf="planService.dataPlanDevice.errorMessagePlan" class="text-danger">{{planService.dataPlanDevice.errorMessagePlan}}</div>
               <div class="form-group mb-3 text-end">
                  <button class="btn btn-light btn-lg mx-3" (click)="previous()">Prev</button>
                  <button class="btn btn-primary btn-lg" (click)="planService.saveSelectedPlan()">Next</button>
               </div>
            </div>
         </ng-container>
         <ng-template #errorMessage>  
            <div class="text-center">
               <h5 class="mb-3 p-b-20">No plans found ! Please connect on helpline number {{helpline}}</h5>
               <button class="btn btn-primary btn-lg" (click)="retryAgain()">Retry</button>
            </div>
         </ng-template>
            
      </div>
      <ng-template #planLoader>
           <div class="login-main program-form">
             <div class="loader-box">
                 <div class="loader-9"></div>
             </div>
           </div>
      </ng-template>
   </div>
</div>
