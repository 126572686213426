<ng-container *ngIf="device.comboType.toLowerCase() === 'tablet' && responseModalData.eligibleForDevice === 'N'; else otherDevices">
  <div class="device-not-eligible-icon">
    <app-feather-icons [icon]="'slash'"></app-feather-icons>
  </div>
  <div 
  class="card card-disable border-2 b-light"
  title="You are not eligible for tablet"
  >
 <img *ngIf="device.imagePath" class="card-img-top border-top-left-radius" src="{{device.imagePath}}" alt="{{device.name}}">
 <!-- <img  class="card-img-top border-top-left-radius" src="./../../../../../../assets/images/banner/3.jpg" alt="{{device.name}}"> -->
  <div 
  class="card-body">
    <div class="p-absolute badge-position-right">
      <span class="badge badge-warning tag-pills-sm-mb">
        ${{device.comboPrice}}
      </span>
    </div>
    <h5 class="card-title">{{device.comboTitle}}</h5>
    <h6 class="card-title mt-3">{{device.comboDesc}}</h6>
    <ul *ngIf="device.products.length > 0" class="list-group list-group-flush">
    <ng-container *ngFor="let product of device.products">
        <li class="list-group-item">
          <i class="fa fa-check-square-o font-success"></i>  
          {{product.productName}}
        </li>
    </ng-container>
  </ul>
  </div>
</div>
</ng-container>
<ng-template #otherDevices>
<div 
  class="card cursor-pointer border-2"
  [ngClass]="{
    'b-success': deviceService.checkSelectedDevices(device.comboId),
    'b-light': !deviceService.checkSelectedDevices(device.comboId),
    }"

  (click)="makeSelectionDevice(device)"
>
 <img *ngIf="device.imagePath" class="card-img-top border-top-left-radius" src="{{device.imagePath}}" alt="{{device.name}}">
 <!-- <img  class="card-img-top border-top-left-radius" src="./../../../../../../assets/images/banner/3.jpg" alt="{{device.name}}"> -->
  <div 
  class="card-body">
    <div class="p-absolute badge-position-right">
      <span class="badge badge-warning tag-pills-sm-mb">
        ${{device.comboPrice}}
      </span>
    </div>
    <h5 class="card-title">{{device.comboTitle}}</h5>
    <h6 class="card-title mt-3">{{device.comboDesc}}</h6>
    <ul *ngIf="device.products.length > 0" class="list-group list-group-flush">
    <ng-container *ngFor="let product of device.products">
        <li class="list-group-item">
          <i class="fa fa-check-square-o font-success"></i>  
          {{product.productName}}
        </li>
    </ng-container>
  </ul>
  </div>
</div>
</ng-template>