<div class="login-main register-form">
      <h4 class="font-primary">{{questionName}}</h4>
      <p>{{questionDesc}}</p>
    <div class="theme-form">
        <div class="row">
            <div class="col col-sm-12">
                <div class="form-group" *ngIf="questionName">
                    <ng-container *ngFor="let option of answers; index as qIndex">
                        <div class="radio radio-primary" [id]="'quest'+qIndex">
                            <input class="form-check-input"
                                [name]="'question'"
                                (change)="renderAdditionalForm($event)"
                                [value]="option.answerValue"
                                [(ngModel)]="option.isSelected"
                                type="radio" 
                                [id]="option.answerLabel">
                            <label class="form-check-label" [for]="option.answerLabel">
                                {{option.answerLabel}}
                            </label>
                        </div>
                    </ng-container>
                    <div *ngIf="!commonService.expiredTokenError && apiProcess" class="api-process-loader">
                    <div  class="loader-box">
                        <div class="loader-9"></div>
                    </div>
                    </div>
                    <div *ngIf="!commonService.expiredTokenError && errorMessage" class="text-danger">{{errorMessage}}</div>

                    <div class="text-danger f-14" *ngIf="commonService.expiredTokenError">
                    {{commonService.expiredTokenError}}
                    </div>
                    <div class="form-group mt-3 text-end">
                    <button class="btn btn-light btn-lg mx-3" [disabled]=" !commonService.expiredTokenError && apiProcess" (click)="previous()">Prev</button>
                    <button class="btn btn-primary btn-lg" [disabled]="!commonService.expiredTokenError && apiProcess" (click)="submit()">Next</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <ng-template #loaderContent>
    <div  class="loader-box" >
        <div class="loader-9"></div>
    </div>
</ng-template> -->
