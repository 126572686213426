import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgxPrintModule } from 'ngx-print';
import {RecaptchaModule, RecaptchaFormsModule} from 'ng-recaptcha'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from  './auth/login/login.component';
import { SharedModule } from './shared/shared.module';
import { NetworkComponent } from './auth/network/network.component';
import { StepperComponent } from './auth/registration/stepper/stepper.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ErrorModule } from './pages/error/error.module';
import { InvoiceComponent } from './auth/invoice/invoice.component';
import { RegisterComponent } from './auth/registration/register/register.component';
import { Step1Module } from './auth/registration/step1/step1.module';
import { HttpHeaderInterceptorService } from './shared/services/http-header-interceptor.service';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NetworkComponent,
    StepperComponent,
    InvoiceComponent,
    RegisterComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgxWebstorageModule.forRoot(
      { prefix: 'tibss', separator: '.', caseSensitive:true }
    ),
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    Step1Module,
    ErrorModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgbAlertModule,
    NgxPrintModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [
    Location, {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptorService, multi: true}
  ],
  bootstrap: [AppComponent]
  
})
export class AppModule { }
