import {Injectable} from "@angular/core";
import { HttpClient,
  //  HttpHeaders,
   HttpResponse } from "@angular/common/http";
import { LocalStorage } from 'ngx-webstorage';
import { IQuestionsReq } from "../model/questions.modal";
import { environment } from "src/environments/environment";
import { Observable, 
  // catchError, tap, throwError 
} from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
// import { Router } from "@angular/router";
// import { RefreshServices } from "./refresh.services";
import { CommonService } from "./common.service";

@Injectable({
    providedIn: 'root'
})

export class QuestionServices {
  protected baseApi: string;
  protected token: string; 

    
    @LocalStorage()
    protected uuid;

    @LocalStorage()
    protected xExpire;

    constructor(
        private http:HttpClient,
        private cookieService: CookieService,
        // private router: Router,
        // private refreshServices: RefreshServices,
        private commonService: CommonService
        ) {
        this.baseApi = environment.BASE_API;
        this.token =  this.token = this.cookieService.get('x-token');

    }

  put(req: IQuestionsReq):Observable<HttpResponse<any>> {

    this.commonService.checkExpiredToken();

    // const headers = new HttpHeaders({
    //   'authorization': `Bearer ${this.token}`
    // })
    
    return this.http.put(`${this.baseApi}/${environment.AUTH.QUESTIONS}/${this.uuid}`, req, {
      // headers,
      observe: 'response'});
  }
}
